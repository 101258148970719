import * as React from 'react'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

import { Spinner } from '../icons/outline'

import { buttonVariants } from './buttonVariants'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  leftIcon?: React.ReactNode
  loaderPosition?: 'left' | 'right'
  loading?: boolean
  rightIcon?: React.ReactNode
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      className,
      leftIcon,
      loaderPosition = 'right',
      loading,
      rightIcon,
      size,
      variant,
      width,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(
          buttonVariants({ className, size, variant, width }),
          className,
        )}
        ref={ref}
        {...props}
      >
        {loaderPosition === 'left' && loading ? (
          <Spinner className="size-5 shrink-0 animate-spin" />
        ) : (
          leftIcon
        )}
        <Slottable>{props.children}</Slottable>
        {loaderPosition === 'right' && loading ? (
          <Spinner className="size-5 shrink-0 animate-spin" />
        ) : (
          rightIcon
        )}
      </Comp>
    )
  },
)

Button.displayName = 'Button'
