import { FormattedMessage } from 'react-intl'

import {
  bgColorClassNameByCardState,
  Card,
  dotColorClassNameByCardState,
  textColorClassNameByCardState,
} from '@/features/Cards'
import { cn } from '@/lib/utils'
import { Typography } from '@/shared/ui'

type Props = {
  card: Card
}

export const CardStateCell = ({ card }: Props) => {
  return (
    <div className="flex items-center">
      <div
        className={cn(
          'flex h-[22px] items-center gap-1.5 rounded-xl px-2',
          `${bgColorClassNameByCardState[card.state]}`,
        )}
      >
        <span
          className={cn(
            'size-1.5 rounded-full',
            dotColorClassNameByCardState[card.state],
          )}
        />

        <Typography
          className={cn(textColorClassNameByCardState[card.state])}
          bold
          variant="body-small"
        >
          <FormattedMessage
            id="card.state.label"
            defaultMessage="{state, select, ACTIVE {Active} CREATED {Inactive} BLOCKED {Frozen} TERMINATED {Terminated} other {}}"
            values={{ state: card.state }}
          />
        </Typography>
      </div>
    </div>
  )
}
