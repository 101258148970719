import { ReactNode, useState } from 'react'
import { useIntl } from 'react-intl'

import { useCopyToClipboard } from '@/hooks/useCopyToClipboard'
import { maskValue } from '@/lib/typography'
import { cn } from '@/lib/utils'

import { Copy, Eye, EyeOff } from '../icons/outline'

import { Button, Card, Typography } from '.'
import { Skeleton } from './Skeleton'

type Props = {
  align?: 'start' | 'end' | 'center'
  children: ReactNode
  className?: string
}

const Details = ({ align, children, className }: Props) => {
  return (
    <div
      className={cn(
        'flex w-full items-center justify-between',
        align === 'start' && 'items-start',
        align === 'end' && 'items-end',
        align === 'center' && 'items-center',
        className,
      )}
    >
      {children}
    </div>
  )
}

const Label = ({ children }: Props) => {
  return <Typography className="text-neutral-gray-600">{children}</Typography>
}

type ValueProps = {
  children: ReactNode
  masked?: boolean
  copyable?: boolean
  className?: string
}

const Value = ({ children, className, masked, copyable }: ValueProps) => {
  const intl = useIntl()
  const [hide, setHide] = useState(masked)
  const copyTextToClipboard = useCopyToClipboard()

  if (typeof children === 'string') {
    return (
      <div className="flex items-center gap-1">
        <Typography text="right" className={cn('', className)}>
          {hide ? maskValue(children.toString()) : children}
        </Typography>
        {masked ? (
          <Button
            variant="ghost"
            size="inline"
            className="size-6 shrink-0"
            onClick={() => setHide((h) => !h)}
            aria-label={
              hide
                ? intl.formatMessage({
                    defaultMessage: 'Show value',
                    id: 'action.showValue',
                  })
                : intl.formatMessage({
                    defaultMessage: 'Hide value',
                    id: 'action.hideValue',
                  })
            }
            type="button"
          >
            {hide ? <Eye className="size-4" /> : <EyeOff className="size-4" />}
          </Button>
        ) : null}
        {copyable ? (
          <Button
            className="size-4 shrink-0"
            onClick={() => copyTextToClipboard(children)}
            variant="ghost"
            size="inline"
            type="button"
            aria-label={intl.formatMessage({
              defaultMessage: 'Copy value',
              id: 'action.copyValue',
            })}
          >
            <Copy className="size-4" />
          </Button>
        ) : null}
      </div>
    )
  }

  return <Typography>{children}</Typography>
}

const DetailsSkeleton = () => {
  return <Skeleton className="h-[21px] w-36" />
}

const DetailsLoader = ({ rows = 3 }: { rows?: number }) => {
  return (
    <Card className="flex flex-col gap-4" size="medium">
      {Array.from({ length: rows }).map((_, index) => (
        <div key={index} className="flex w-full justify-between">
          <Skeleton className="h-[21px] w-32" />
          <Skeleton className="h-[21px] w-32" />
        </div>
      ))}
    </Card>
  )
}

Details.Label = Label
Details.Value = Value
Details.Skeleton = DetailsSkeleton
Details.Loader = DetailsLoader

export { Details }
