import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { deleteTeamMember } from '@/features/Team'
import { TeamMember } from '@/features/Team/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { hasOTPRequiredError } from '@/lib/error'
import { queryClient } from '@/lib/queryClient'
import { getFullName } from '@/lib/typography'
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Typography,
} from '@/shared/ui'

import { OTPDialog } from '../OTPDialog'

type Props = {
  isOpen: boolean
  member?: TeamMember
  onDelete: () => void
  onOpenChange: (isOpen: boolean) => void
}

export const DeleteTeamMemberDialog = ({
  isOpen,
  member,
  onDelete,
  onOpenChange,
}: Props) => {
  const [isOTPDialogOpen, setShowOTPDialog] = useState(false)
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isError, isPending } = useMutation({
    mutationFn: deleteTeamMember,
  })

  const onDeleteTeamMember = async (otp?: string) => {
    if (!member?.id) {
      toast.error(
        intl.formatMessage({
          id: 'team.member.details.error.missingId',
          defaultMessage: 'Missing team member ID',
        }),
      )
      return
    }

    try {
      await mutateAsync({
        id: member.id,
        otp,
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMembers],
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMember, member?.id],
      })

      onOpenChange(false)

      toast.success(
        intl.formatMessage({
          id: 'team.member.details.terminated',
          defaultMessage: 'Team member terminated',
        }),
      )

      onDelete()
    } catch (error) {
      if (error instanceof Error) {
        if (hasOTPRequiredError(error)) {
          onOpenChange(false)
          setShowOTPDialog(true)
          return
        }

        notifyError(error)
      }
    }
  }

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              <FormattedMessage
                defaultMessage="Delete {name}?"
                id="teamMember.delete.dialog.title"
                values={{
                  name: member?.firstName,
                }}
              />
            </DialogTitle>

            <div className="flex flex-col items-center">
              <Typography text="center">
                <FormattedMessage
                  defaultMessage="Are you sure you want to delete {fullName}?"
                  id="teamMember.delete.dialog.deleteCard"
                  values={{
                    fullName: getFullName(member),
                  }}
                />
              </Typography>

              <Typography>
                <FormattedMessage
                  defaultMessage="The user will be terminated permanently"
                  id="teamMember.delete.dialog.userTerminated"
                />
              </Typography>
            </div>
          </DialogHeader>

          <div className="p-4" />

          <div className="grid grid-cols-2 gap-3">
            <Button
              disabled={isPending}
              width="full"
              onClick={() => onOpenChange(false)}
              variant="tertiary"
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              disabled={isPending}
              loading={isPending}
              width="full"
              onClick={() => onDeleteTeamMember()}
            >
              <FormattedMessage id="action.delete" defaultMessage="Delete" />
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <OTPDialog
        isOpen={isOTPDialogOpen}
        onOpenChange={setShowOTPDialog}
        isPending={isPending}
        isError={isError}
        title={intl.formatMessage({
          id: 'teamMember.delete.dialog.otp.title',
          defaultMessage: 'Enter verification code to confirm deletion',
        })}
        description={intl.formatMessage(
          {
            id: 'teamMember.delete.dialog.otp.description',
            defaultMessage:
              'We have sent you an email with a verification code. Enter it to permanently terminate {fullName}',
          },
          { fullName: getFullName(member) },
        )}
        onContinue={(otp) => {
          onDeleteTeamMember(otp)
        }}
      />
    </>
  )
}
