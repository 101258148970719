import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { getCodeByCountry } from '@/lib/country'
import {
  CountryCodeField,
  OptionalTag,
  PaymentMethodSelect,
  Widget,
} from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  PhoneNumberInput,
  StickyContainer,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { IndividualUSRecipient } from '../types'

import { CountryCombobox } from './CountryCombobox'
import { StateSelectOrInput } from './StateSelectOrInput'
import { individualValidation, paymentValidation } from './validations'

const DEFAULT_VALUES: CreateUSIndividualSchema = {
  firstName: '',
  lastName: '',
  secondLastName: '',
  email: '',
  localPhoneNumber: '',
  paymentMethod: '',
  accountNumber: '',
  routingNumber: '',
  addressStreet: '',
  addressCity: '',
  addressCountry: '',
  addressState: '',
  addressZipCode: '',
}

const createUSIndividualSchema = individualValidation.and(paymentValidation)

const US_INDIVIDUAL_FORM_ID = 'us-individual-form'

export type CreateUSIndividualSchema = z.infer<typeof createUSIndividualSchema>

type Props = {
  country: CountryCode
  onContinue: (values: CreateUSIndividualSchema) => void
  recipient?: IndividualUSRecipient
}

export const USIndividualForm = ({ country, onContinue, recipient }: Props) => {
  const intl = useIntl()
  const form = useForm<CreateUSIndividualSchema>({
    mode: 'onChange',
    resolver: zodResolver(createUSIndividualSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
      internationalPhonePrefix: getCodeByCountry(country),
    },
  })

  const onSubmit: SubmitHandler<CreateUSIndividualSchema> = (values) => {
    const { internationalPhonePrefix, localPhoneNumber, ...rest } = values

    const parsedPhoneNumber =
      localPhoneNumber?.replace(`${internationalPhonePrefix}`, '') ?? ''

    onContinue({
      ...rest,
      localPhoneNumber: parsedPhoneNumber,
      internationalPhonePrefix,
    })
  }

  useEffect(() => {
    if (recipient) {
      form.setValue('firstName', recipient.firstName)
      form.setValue('lastName', recipient.lastName)
      form.setValue('secondLastName', recipient.secondLastName)
      form.setValue('email', recipient.email)

      if (recipient.localPhoneNumber) {
        form.setValue(
          'internationalPhonePrefix',
          recipient.internationalPhonePrefix,
        )

        form.setValue(
          'localPhoneNumber',
          recipient.internationalPhonePrefix + recipient.localPhoneNumber,
        )
      }

      form.setValue('paymentMethod', recipient.localInformation.paymentMethod)
      form.setValue('accountNumber', recipient.localInformation.accountNumber)
      form.setValue('routingNumber', recipient.localInformation.routingNumber)
      form.setValue('addressStreet', recipient.addressStreet)
      form.setValue('addressCity', recipient.addressCity)
      form.setValue('addressState', recipient.addressState)
      form.setValue('addressZipCode', recipient.addressZipCode)
      form.setValue('addressCountry', recipient.addressCountry)

      form.trigger()
    }
  }, [form, recipient])

  return (
    <>
      <Form {...form}>
        <form
          id={US_INDIVIDUAL_FORM_ID}
          className="w-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Widget
            title={
              <FormattedMessage
                id="recipient.contactDetails"
                defaultMessage="Contact details"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="given-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'First name',
                        id: 'label.firstName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="First name"
                      id="label.firstName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="family-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Last name',
                        id: 'label.lastName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Last name"
                      id="label.lastName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="secondLastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="additional-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Second last name',
                        id: 'label.secondLastName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Second last name"
                      id="label.secondLastName"
                    />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="email"
                      placeholder={intl.formatMessage({
                        id: 'label.contactEmail',
                        defaultMessage: 'Contact email',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="label.contactEmail"
                      defaultMessage="Contact email"
                    />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            <div className="flex items-stretch gap-3">
              <FormField
                control={form.control}
                name="internationalPhonePrefix"
                render={({ field }) => {
                  return (
                    <CountryCodeField
                      value={field.value}
                      onSelect={(value) =>
                        form.setValue('internationalPhonePrefix', value)
                      }
                    />
                  )
                }}
              />

              <FormField
                control={form.control}
                name="localPhoneNumber"
                render={({ field }) => (
                  <FormItem className="relative flex-1">
                    <FormControl>
                      <PhoneNumberInput
                        phonePrefix={
                          form.watch('internationalPhonePrefix') ?? ''
                        }
                        placeholder={intl.formatMessage({
                          id: 'label.contactPhoneNumber',
                          defaultMessage: 'Contact phone number',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        id="label.contactPhoneNumber"
                        defaultMessage="Contact phone number"
                      />
                    </AnimatedFormLabel>

                    {field.value === '' && <OptionalTag />}
                  </FormItem>
                )}
              />
            </div>
          </Widget>

          <div className="p-4" />

          <Widget
            title={
              <FormattedMessage
                id="recipient.paymentDetails"
                defaultMessage="Payment details"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="paymentMethod"
              render={({ field }) => (
                <PaymentMethodSelect
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <FormField
              control={form.control}
              name="accountNumber"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.accountNumber',
                        defaultMessage: 'Account number',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="label.accountNumber"
                      defaultMessage="Account number"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="routingNumber"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.routingNumber',
                        defaultMessage: 'Routing number',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="label.routingNumber"
                      defaultMessage="Routing number"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="addressStreet"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="street-address"
                      placeholder={intl.formatMessage({
                        id: 'label.address',
                        defaultMessage: 'Address',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="label.address"
                      defaultMessage="Address"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="addressCity"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="address-level2"
                      placeholder={intl.formatMessage({
                        id: 'label.city',
                        defaultMessage: 'City',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage id="label.city" defaultMessage="City" />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <div className="grid grid-cols-2 gap-3">
              <FormField
                control={form.control}
                name="addressState"
                render={({ field }) => (
                  <FormItem>
                    <StateSelectOrInput
                      value={field.value}
                      onChange={field.onChange}
                      variant={
                        form.watch('addressCountry') === CountryCode.US
                          ? 'select'
                          : 'input'
                      }
                    />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="addressZipCode"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        autoComplete="postal-code"
                        placeholder={intl.formatMessage({
                          id: 'label.postCode',
                          defaultMessage: 'Post code',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        id="label.postCode"
                        defaultMessage="Post code"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="addressCountry"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CountryCombobox
                      placeholder={intl.formatMessage({
                        id: 'label.country',
                        defaultMessage: 'Country',
                      })}
                      onSelect={(value) => {
                        field.onChange(value.valueAsCode)

                        if (form.getValues('addressState') !== '') {
                          form.setValue('addressState', '')
                        }
                      }}
                      value={field.value}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </Widget>
        </form>
      </Form>

      <StickyContainer>
        <Button
          width="full"
          form={US_INDIVIDUAL_FORM_ID}
          onClick={form.handleSubmit(onSubmit)}
          disabled={!form.formState.isValid}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Save & Continue"
            id="action.saveAndContinue"
          />
        </Button>
      </StickyContainer>
    </>
  )
}
