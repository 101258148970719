import { useMemo } from 'react'

import { Card } from '@/features/Cards'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { useBusinessUser } from '@/hooks/useBusinessUser'
import { getFullName } from '@/lib/typography'
import { cn } from '@/lib/utils'
import { Skeleton, Typography } from '@/shared/ui'
import { Role } from '@/types/roles'

type Props = {
  card: Card
}

export const CardholderCell = ({ card }: Props) => {
  const { role, isPending } = useBusinessRole()
  const businessUser = useBusinessUser()

  const cardUserName = card.printedUserName
    .toLowerCase()
    .replace(/\b\w/g, (l) => l.toUpperCase())

  const fullName = useMemo(() => {
    switch (role) {
      case Role.ADMIN:
      case Role.READ_ONLY:
        return cardUserName

      case Role.PAYMENT_OPS:
      case Role.CARD_USER:
        return getFullName(businessUser)

      default:
        return ''
    }
  }, [cardUserName, businessUser, role])

  return (
    <div className="flex items-center">
      {isPending ? (
        <Skeleton className="h-[21px] w-36" />
      ) : (
        <div className="flex shrink-0 items-center gap-2">
          <Typography className={cn('line-clamp-1')}>{fullName}</Typography>
        </div>
      )}
    </div>
  )
}
