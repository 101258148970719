import { Currency } from '@/constants/currency'
import { Permission } from '@/types/permissions'
import { Role } from '@/types/roles'

export enum TeamState {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  INVITED = 'INVITED',
  TERMINATED = 'TERMINATED',
}

export type TeamMember = {
  id: string
  businessId: string
  identityId: string
  email: string
  firstName: string
  secondLastName?: string
  lastName: string
  userTitle?: string
  state: TeamState
  role: Role
  permissions: Permission[]

  limitPeriodType?: LimitPeriodType
  periodTransferLimitAmount?: string
  singleTransferLimitAmount?: string
  moneyMovementPermission?: MovementPermission
}

export enum MovementPermission {
  REQUIRE_APPROVAL_ABOVE_THE_LIMIT = 'REQUIRE_APPROVAL_ABOVE_THE_LIMIT',
  ALWAYS_REQUIRE_APPROVAL = 'ALWAYS_REQUIRE_APPROVAL',
  NO_APPROVAL_REQUIRED = 'NO_APPROVAL_REQUIRED',
}

export enum LimitPeriodType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export type InvitedTeamMember = {
  businessId: string
  id: string
  identityId: string
  permissions: Permission[]
  role: Role
  state: TeamState
}

export type TeamMemberTransferPermission = {
  businessId: string
  businessIdentityId: string
  businessUserId: string
  type: MovementPermission
  limitCurrency: Currency
  limitPeriodType: LimitPeriodType
  periodTransferLimitAmount: number
  singleTransferLimitAmount: number
  periodStartDate: string
  periodResetDate: string
}

export type TeamMemberPaymentLimit = {
  businessUserId: string
  type: MovementPermission
  periodTransferLimit?: {
    limitType: LimitPeriodType
    limitAmount: number
    usedAmount: number
    limitCurrency: Currency
    periodStartDate: string
    periodResetDate: string
  } | null
  singleTransferLimit?: { limitAmount: number; limitCurrency: Currency } | null
}
