import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import { GoBackButton } from '@/shared/components'
import { SlideInScreen, Typography } from '@/shared/ui'
import { Country, CountryCode } from '@/types/country'

import {
  BusinessMXRecipient,
  BusinessUSRecipient,
  CreatedRecipient,
  EntityType,
  IndividualMXRecipient,
  IndividualUSRecipient,
} from '../../types'
import { MXBusinessForm } from '../MXBusinessForm'
import { MXIndividualForm } from '../MXIndividualForm'
import { USBusinessForm } from '../USBusinessForm'
import { USIndividualForm } from '../USIndividualForm'

type Props = {
  country: Country['code']
  entityType: EntityType
  recipient?: CreatedRecipient
  onBack: () => void
  onContinue: (recipient: CreatedRecipient) => void
}

export const RecipientDetailsStep = ({
  country,
  entityType,
  recipient,
  onBack,
  onContinue,
}: Props) => {
  const formByCountryAndEntity = useMemo(() => {
    switch (country) {
      case CountryCode.MX: {
        switch (entityType) {
          case EntityType.INDIVIDUAL: {
            return (
              <MXIndividualForm
                recipient={recipient as IndividualMXRecipient}
                country={CountryCode.MX}
                onContinue={(values) => {
                  const { clabe, ...rest } = values

                  onContinue({
                    country: CountryCode.MX,
                    entityType: EntityType.INDIVIDUAL,
                    localInformation: { clabe },
                    ...rest,
                  })
                }}
              />
            )
          }
          case EntityType.BUSINESS:
            return (
              <MXBusinessForm
                recipient={recipient as BusinessMXRecipient}
                country={CountryCode.MX}
                onContinue={(values) => {
                  const { clabe, ...rest } = values

                  onContinue({
                    country: CountryCode.MX,
                    entityType: EntityType.BUSINESS,
                    localInformation: { clabe },
                    ...rest,
                  })
                }}
              />
            )

          default:
            return null
        }
      }

      case CountryCode.US: {
        switch (entityType) {
          case EntityType.INDIVIDUAL:
            return (
              <USIndividualForm
                recipient={recipient as IndividualUSRecipient}
                country={CountryCode.US}
                onContinue={(values) => {
                  const {
                    accountNumber,
                    routingNumber,
                    paymentMethod,
                    ...rest
                  } = values

                  onContinue({
                    country: CountryCode.US,
                    entityType: EntityType.INDIVIDUAL,
                    localInformation: {
                      accountNumber,
                      routingNumber,
                      paymentMethod,
                    },
                    ...rest,
                  })
                }}
              />
            )
          case EntityType.BUSINESS:
            return (
              <USBusinessForm
                recipient={recipient as BusinessUSRecipient}
                country={CountryCode.US}
                onContinue={(values) => {
                  const {
                    accountNumber,
                    routingNumber,
                    paymentMethod,
                    ...rest
                  } = values

                  onContinue({
                    country: CountryCode.US,
                    entityType: EntityType.BUSINESS,
                    localInformation: {
                      accountNumber,
                      routingNumber,
                      paymentMethod,
                    },
                    ...rest,
                  })
                }}
              />
            )
          default:
            return null
        }
      }

      default:
        return null
    }
  }, [country, entityType, onContinue, recipient])

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Account details"
            id="label.accountDetails"
          />
        </Typography>
        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Enter payment details for your recipient"
            id="recipient.add.accountDetails.subtitle"
          />
        </Typography>

        <div className="p-3" />

        {formByCountryAndEntity}
      </SlideInScreen>
    </>
  )
}
