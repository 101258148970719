import { FormattedMessage } from 'react-intl'

import { Details } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { CreatedRecipient } from '../types'

type Props = {
  recipient: CreatedRecipient
}

export const RecipientPaymentDetails = ({ recipient }: Props) => {
  switch (recipient.country) {
    case CountryCode.MX:
      return (
        <Details>
          <Details.Label>
            <FormattedMessage defaultMessage="CLABE" id="label.clabe" />
          </Details.Label>

          <Details.Value>{recipient.localInformation.clabe}</Details.Value>
        </Details>
      )

    case CountryCode.US:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>
            <Details.Value>
              <FormattedMessage
                id="paymentMethod.label"
                defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                values={{ method: recipient.localInformation.paymentMethod }}
              />
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Routing number"
                id="label.routingNumber"
              />
            </Details.Label>

            <Details.Value>
              {recipient.localInformation.routingNumber}
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Account number"
                id="label.accountNumber"
              />
            </Details.Label>

            <Details.Value>
              {recipient.localInformation.accountNumber}
            </Details.Value>
          </Details>
        </>
      )

    default:
      return null
  }
}
