import { Recipient } from '../types'

export function getRecipientName(recipient?: Recipient) {
  if (!recipient) {
    return undefined
  }

  if (recipient.firstName && recipient.lastName) {
    return `${recipient.firstName} ${recipient.lastName}`
  }

  if (recipient.email) {
    return recipient.email
  }

  return undefined
}

export function getRecipientTitle(recipient?: Recipient) {
  if (!recipient) {
    return undefined
  }

  switch (recipient.entityType) {
    case 'INDIVIDUAL':
      return getRecipientName(recipient)

    case 'BUSINESS':
      return recipient.legalName
        ? recipient.legalName
        : getRecipientName(recipient)

    default:
      return undefined
  }
}
