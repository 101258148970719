import { useMutation, useQuery } from '@tanstack/react-query'
import { Map, Marker } from '@vis.gl/react-google-maps'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Currency } from '@/constants/currency'
import { CARDS_ROUTE } from '@/constants/paths'
import { PHYSICAL_CARD_FEE } from '@/constants/payments'
import { queryKeys } from '@/constants/queryKeys'
import { getAccount } from '@/features/Accounts'
import { useCoordinatesFromAddress } from '@/hooks/useCoordinatesFromAddress'
import { useErrorToast } from '@/hooks/useErrorToast'
import { formatAmount } from '@/lib/money'
import { queryClient } from '@/lib/queryClient'
import { AccountIcon, GoBackButton } from '@/shared/components'
import {
  Button,
  Card,
  Details,
  Skeleton,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { createPhysicalCard } from '../../api/createPhysicalCard'

import { PhysicalCardAddressSchema } from './CardAddressStep'
import { PhysicalCardInfoSchema } from './CreatePhysicalCardStep'

type Props = {
  physicalCardDetails?: PhysicalCardInfoSchema
  physicalCardAddress?: PhysicalCardAddressSchema
  onBack: () => void
}

export const CardReviewStep = ({
  physicalCardDetails,
  physicalCardAddress,
  onBack,
}: Props) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const notifyError = useErrorToast()

  const accountQuery = useQuery({
    queryKey: [queryKeys.getAccount, physicalCardDetails?.walletId],
    queryFn: getAccount,
    select: (data) =>
      data.data.wallets.find((w) => w.id === physicalCardDetails?.walletId),
  })

  const fullAddress = [
    physicalCardAddress?.addressStreet,
    physicalCardAddress?.addressStreetNumber,
    physicalCardAddress?.addressApartment,
    physicalCardAddress?.addressDistrict,
    physicalCardAddress?.addressCity,
    physicalCardAddress?.addressState,
    physicalCardAddress?.addressPostCode,
    physicalCardAddress?.addressCountry,
  ].join(', ')

  const coordinates = useCoordinatesFromAddress(fullAddress)

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createPhysicalCard,
  })

  const onCreatePhysicalCard = async () => {
    if (!physicalCardAddress || !physicalCardDetails) {
      return
    }

    if (!physicalCardDetails.identityId) {
      toast.error(
        intl.formatMessage({
          id: 'cards.create.noIdentityId',
          defaultMessage: 'No identity ID found',
        }),
      )
    }

    try {
      const {
        cardholder: _,
        limitAmount,
        limitType,
        pin: { code: pin },
        ...rest
      } = physicalCardDetails
      const parserPhoneNumber = physicalCardAddress.localPhoneNumber.replace(
        physicalCardAddress.internationalPhonePrefix,
        '',
      )

      const response = await mutateAsync({
        ...rest,
        pin,
        requestId: crypto.randomUUID(),
        cardLimit: { limitAmount, limitType },
        localPhoneNumber: parserPhoneNumber,
        internationalPhonePrefix: physicalCardAddress.internationalPhonePrefix,
        deliveryAddress: {
          street: physicalCardAddress.addressStreet,
          streetNumber: physicalCardAddress.addressStreetNumber,
          apartment: physicalCardAddress.addressApartment,
          references: physicalCardAddress.addressReferences,
          neighborhood: physicalCardAddress.addressDistrict,
          city: physicalCardAddress.addressCity,
          state: physicalCardAddress.addressState,
          country: CountryCode.MX,
          postcode: physicalCardAddress.addressPostCode,
        },
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getAllCards],
      })

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Physical card created!',
          id: 'cards.create.physicalCardCreated',
        }),
      )

      navigate(`${CARDS_ROUTE}?id=${response.data.id}`)
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="card.create.reviewStep.title"
            defaultMessage="Review your card and send for delivery"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="card.create.reviewStep.subtitle"
            defaultMessage="Make sure the card details and the delivery address are correct"
          />
        </Typography>

        <div className="p-6" />

        <Card size="medium" className="flex w-full max-w-3xl flex-col gap-6">
          {coordinates ? (
            <Map
              className="h-40 w-full [&>div]:rounded-xl"
              defaultZoom={16}
              defaultCenter={coordinates}
              gestureHandling="greedy"
              disableDefaultUI
            >
              <Marker position={coordinates} />
            </Map>
          ) : (
            <Skeleton className="h-40 w-full" />
          )}

          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Delivery address"
                id="label.deliveryAddress"
              />
            </Details.Label>

            <Details.Value className="max-w-72 whitespace-break-spaces text-right">
              {fullAddress}
            </Details.Value>
          </Details>

          {physicalCardAddress?.localPhoneNumber ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Contact phone number"
                  id="label.contactPhoneNumber"
                />
              </Details.Label>

              <Details.Value>
                {formatPhoneNumberIntl(physicalCardAddress.localPhoneNumber)}
              </Details.Value>
            </Details>
          ) : null}

          {physicalCardAddress?.addressReferences ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Address references"
                  id="label.addressReferences"
                />
              </Details.Label>

              <Details.Value>
                {physicalCardAddress?.addressReferences}
              </Details.Value>
            </Details>
          ) : null}
        </Card>

        <div className="p-4" />

        <Card size="medium" className="flex max-w-3xl flex-col gap-6">
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Cardholder"
                id="label.cardholder"
              />
            </Details.Label>

            <Details.Value>{physicalCardDetails?.cardholder}</Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Card nickname"
                id="label.cardNickname"
              />
            </Details.Label>

            <Details.Value>{physicalCardDetails?.nickname}</Details.Value>
          </Details>

          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Source account"
                id="label.sourceAccount"
              />
            </Details.Label>

            {accountQuery.isPending ? (
              <Skeleton className="h-[21px] w-32" />
            ) : (
              <div className="flex items-center gap-1">
                <AccountIcon
                  variant="middle"
                  id={physicalCardDetails?.walletId ?? ''}
                />
                <Details.Value>{accountQuery.data?.label}</Details.Value>
              </div>
            )}
          </Details>

          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Physical card fee"
                id="label.physicalCardFee"
              />
            </Details.Label>

            <div className="flex gap-1 text-right">
              <Typography className="line-through">
                {formatAmount({
                  amount: PHYSICAL_CARD_FEE,
                  currency: Currency.USDC,
                })}
              </Typography>
              <Typography bold className="uppercase text-primary">
                <FormattedMessage defaultMessage="Free" id="label.free" />
              </Typography>
            </div>
          </Details>
        </Card>

        <StickyContainer>
          <Button
            loading={isPending}
            disabled={isPending}
            width="full"
            onClick={onCreatePhysicalCard}
          >
            <FormattedMessage
              defaultMessage="Create & Send"
              id="action.createAndSend"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
