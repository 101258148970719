import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { AnimatePresence, motion } from 'framer-motion'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { getIdentity } from '@/api'
import { isSmallScreen } from '@/constants/breakpoints'
import { DASHBOARD_ROUTE, NavigationLink, navMessages } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { cn } from '@/lib/utils'
import { LogoIcon, ProfileDropdown } from '@/shared/components'
import { Typography } from '@/shared/ui'
import { Identity } from '@/types/business'

import { useNavigationLinks } from '../hooks/useNavigationLinks'

const SlideInSpan = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <motion.span
      initial={{ opacity: 0, x: -30 }}
      animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
      exit={{ opacity: 0, x: -30 }}
      className={className}
    >
      {children}
    </motion.span>
  )
}

type NavigationLinksProps = {
  expanded?: boolean
  links: NavigationLink[]
}

const NavigationLinks = ({ expanded, links }: NavigationLinksProps) => {
  const intl = useIntl()

  return (
    <ul className="flex w-full flex-col items-start gap-4 text-neutral-gray-100">
      {links.map(({ key, path, icon: Icon, enabled, showDot }) => {
        if (!enabled) {
          return null
        }

        return (
          <li className="w-full" key={key}>
            <NavLink
              end={path === DASHBOARD_ROUTE}
              to={path}
              className={cn(
                'relative z-10 flex w-full items-center justify-start gap-3 whitespace-nowrap rounded-xl py-2 outline-none transition-colors aria-[current=page]:font-bold md:h-10 md:p-2 md:hover:bg-neutral-gray-900 md:focus:bg-neutral-gray-900 aria-[current=page]:md:bg-neutral-gray-900',
              )}
            >
              <span className="relative size-6 shrink-0">
                <Icon
                  className={cn('size-6 shrink-0 justify-self-center', {
                    'ml-0.5 mt-0.5 size-5': key === 'cards',
                  })}
                  aria-label={intl.formatMessage(navMessages[key])}
                />
                {showDot ? (
                  <div className="absolute right-0 top-0 size-2.5 rounded-full bg-primary-error" />
                ) : null}
              </span>

              <AnimatePresence mode="wait">
                {expanded && (
                  <SlideInSpan>
                    <Typography>
                      {intl.formatMessage(navMessages[key])}
                    </Typography>
                  </SlideInSpan>
                )}
              </AnimatePresence>
            </NavLink>
          </li>
        )
      })}
    </ul>
  )
}

export const Sidebar = () => {
  const isMobile = useMediaQuery(isSmallScreen)

  const links = useNavigationLinks()

  const { boot } = useIntercom()

  const [openPopover, setOpenPopover] = useState(false)
  const [internalSidebarOpen, setInternalSidebarOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const [userQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getIdentity],
        queryFn: getIdentity,
        select: (data: AxiosResponse<Identity>) => data.data,
      },
    ],
  })

  const onMouseOver = () => {
    if (isMobile || internalSidebarOpen) return

    setExpanded(true)
  }

  const onMouseOut = () => {
    if (isMobile || internalSidebarOpen) return

    setExpanded(false)
    setOpenPopover(false)
  }

  useEffect(() => {
    if (userQuery.data) {
      boot({
        email: userQuery.data?.email,
        name: `${userQuery.data?.firstName} ${userQuery.data?.lastName}`,
      })

      Sentry.setUser({
        email: userQuery.data?.email,
        name: `${userQuery.data?.firstName} ${userQuery.data?.lastName}`,
        id: userQuery.data?.id,
      })
    }
  }, [boot, userQuery.data])

  return (
    <nav
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseOut}
      className={cn(
        'fixed z-50 min-h-screen w-12 bg-neutral-gray-1000 px-3 py-6 transition-all duration-300 md:w-16',
        expanded && 'md:w-60',
      )}
    >
      <div className="hide-scrollbar flex h-screen flex-col overflow-y-auto overflow-x-hidden">
        <ProfileDropdown
          open={openPopover}
          onOpenChange={setOpenPopover}
          expanded={expanded}
          closeSidebarCallback={() => setExpanded(false)}
          onInternalSidebarOpen={setInternalSidebarOpen}
        />

        <div className="p-6" />

        <NavigationLinks expanded={expanded} links={links} />

        <div className="mb-16 mt-auto flex w-full md:pl-2.5">
          <LogoIcon aria-label="DolarApp Business logo" className="size-5" />
        </div>
      </div>
    </nav>
  )
}
