import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { TeamMember, TeamState } from '@/features/Team/types'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { getFullName, intersperse } from '@/lib/typography'
import { CircleCancel, Pencil } from '@/shared/icons/outline'
import { Button, Skeleton, SlideInLeft, Typography } from '@/shared/ui'

import { BlockTeamMemberDialog } from './BlockTeamMemberDialog'
import { PermissionsList } from './PermissionsList'
import { SidebarTeamStateCell } from './SidebarTeamStateCell'
import { TeamCardsTable } from './TeamCardsTable'
import { ScreenType } from './TeamDetailsSidebar'
import { TeamMemberInviteSection } from './TeamMemberInviteSection'
import { TeamMemberUnblockSection } from './TeamMemberUnblockSection'
import { TeamMoneyLimit } from './TeamMoneyLimit'

type Props = {
  member?: TeamMember
  setScreen: (screen: ScreenType) => void
}

export const BaseTeamDetails = ({ member, setScreen }: Props) => {
  const intl = useIntl()

  const { isAdmin } = useBusinessRole()

  const [showBlockDialog, setShowBlockDialog] = useState(false)

  return (
    <SlideInLeft className="flex flex-col gap-8">
      {member ? (
        <div className="flex justify-between">
          <div className="flex flex-col">
            <Typography variant="h3">{getFullName(member)}</Typography>

            <Typography className="text-neutral-gray-600">
              {intersperse([member.userTitle, member.email], ' • ')}
            </Typography>

            <div className="p-1" />

            <div className="flex gap-2">
              <div className="flex h-[22px] w-fit items-center gap-1.5 rounded-xl bg-neutral-gray-200 px-2">
                <Typography
                  className="text-neutral-gray-800"
                  bold
                  variant="body-small"
                >
                  <FormattedMessage
                    id="team.role.label"
                    defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
                    values={{ role: member.role }}
                  />
                </Typography>
              </div>
              <SidebarTeamStateCell member={member} />
            </div>
          </div>

          {isAdmin && member?.state !== TeamState.TERMINATED && (
            <div className="flex gap-2">
              <Button
                leftIcon={<CircleCancel className="size-4" />}
                onClick={() => setShowBlockDialog(true)}
                variant="tertiary"
                size="icon"
                aria-label={intl.formatMessage({
                  id: 'team.details.blockTeamMember',
                  defaultMessage: 'Block team member',
                })}
              />
              <Button
                leftIcon={<Pencil className="size-4" />}
                onClick={() => setScreen('edit')}
                variant="tertiary"
                size="icon"
                aria-label={intl.formatMessage({
                  id: 'team.details.edit',
                  defaultMessage: 'Edit team member',
                })}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-between">
          <div className="flex flex-col gap-1">
            <Skeleton className="h-[33px] w-36" />
            <Skeleton className="h-[21px] w-24" />

            <div className="flex gap-2">
              <Skeleton className="h-[22px] w-12" />
              <Skeleton className="h-[22px] w-12" />
            </div>
          </div>

          <div className="flex gap-3">
            <Skeleton className="size-8" />
            <Skeleton className="size-8" />
          </div>
        </div>
      )}

      <TeamMemberInviteSection member={member} />

      <TeamMemberUnblockSection member={member} />

      <PermissionsList member={member} />

      <TeamMoneyLimit member={member} />

      <TeamCardsTable member={member} />

      <BlockTeamMemberDialog
        member={member}
        isOpen={showBlockDialog}
        onOpenChange={setShowBlockDialog}
        onBlock={() => {
          setScreen('base')
        }}
      />
    </SlideInLeft>
  )
}
