import { FormattedMessage } from 'react-intl'

import { Details, Skeleton } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { Recipient } from '../types'

type Props = {
  recipient?: Recipient
}

export const PaymentDetails = ({ recipient }: Props) => {
  switch (recipient?.country) {
    case CountryCode.MX:
      return (
        <Details>
          <Details.Label>
            <FormattedMessage id="label.clabe" defaultMessage="CLABE" />
          </Details.Label>
          {recipient ? (
            <Details.Value masked>
              {recipient.localInformation.clabe}
            </Details.Value>
          ) : (
            <Details.Skeleton />
          )}
        </Details>
      )

    case CountryCode.US:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>
            {recipient ? (
              <Details.Value>
                <FormattedMessage
                  id="paymentMethod.label"
                  defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                  values={{ method: recipient.localInformation.paymentMethod }}
                />
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.routingNumber"
                defaultMessage="Routing number"
              />
            </Details.Label>
            {recipient ? (
              <Details.Value>
                {recipient.localInformation.routingNumber}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.accountNumber"
                defaultMessage="Account number"
              />
            </Details.Label>
            {recipient ? (
              <Details.Value masked>
                {recipient.localInformation.accountNumber}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
        </>
      )

    default:
      return (
        <div className="flex justify-between">
          <Skeleton className="h-[21px] w-24" />
          <Skeleton className="h-[21px] w-32" />
        </div>
      )
  }
}
