import { EntityType, Recipient } from '@/features/Recipients/types'
import {
  BulkTransactionStatus,
  TransactionStatus,
  TransactionType,
} from '@/features/Transactions/types'
import { Building, User } from '@/shared/icons/solid'
import { Icon } from '@/types/global'

export const bgColorClassNameByStatus: Record<TransactionStatus, string> = {
  [TransactionStatus.FAILED]: 'bg-primary-error/15',
  [TransactionStatus.REVERTED]: 'bg-primary-error/15',
  [TransactionStatus.PENDING]: 'bg-neutral-gray-100',
  [TransactionStatus.BLOCKED]: 'bg-neutral-gray-100',
  [TransactionStatus.COMPLETED]: 'bg-primary-light',
}

export const dotColorClassNameByStatus: Record<TransactionStatus, string> = {
  [TransactionStatus.FAILED]: 'bg-primary-error',
  [TransactionStatus.REVERTED]: 'bg-primary-error',
  [TransactionStatus.PENDING]: 'bg-neutral-gray-800',
  [TransactionStatus.BLOCKED]: 'bg-neutral-gray-800',
  [TransactionStatus.COMPLETED]: 'bg-primary-dark',
}

export const textColorClassNameByStatus: Record<TransactionStatus, string> = {
  [TransactionStatus.FAILED]: 'text-primary-error',
  [TransactionStatus.REVERTED]: 'text-primary-error',
  [TransactionStatus.PENDING]: 'text-neutral-gray-800',
  [TransactionStatus.BLOCKED]: 'text-neutral-gray-800',
  [TransactionStatus.COMPLETED]: 'text-primary-dark',
}

export const bgColorClassNameByBulkStatus: Record<
  BulkTransactionStatus,
  string
> = {
  [BulkTransactionStatus.INCOMPLETE]: 'bg-primary-warning/15',
  [BulkTransactionStatus.PENDING]: 'bg-neutral-gray-100',
  [BulkTransactionStatus.COMPLETED]: 'bg-primary-light',
}

export const dotColorClassNameByBulkStatus: Record<
  BulkTransactionStatus,
  string
> = {
  [BulkTransactionStatus.INCOMPLETE]: 'bg-primary-warning',
  [BulkTransactionStatus.PENDING]: 'bg-neutral-gray-800',
  [BulkTransactionStatus.COMPLETED]: 'bg-primary-dark',
}

export const textColorClassNameByBulkStatus: Record<
  BulkTransactionStatus,
  string
> = {
  [BulkTransactionStatus.INCOMPLETE]: 'text-primary-warning',
  [BulkTransactionStatus.PENDING]: 'text-neutral-gray-800',
  [BulkTransactionStatus.COMPLETED]: 'text-primary-dark',
}

export const iconByRecipientType: Record<Recipient['entityType'], Icon> = {
  [EntityType.BUSINESS]: Building,
  [EntityType.INDIVIDUAL]: User,
}

export const internalTransactionTypes: TransactionType[] = [
  TransactionType.INTERNAL_SWAP_DEPOSIT,
  TransactionType.INTERNAL_SWAP_WITHDRAW,
]

export const depositTransactionTypes: TransactionType[] = [
  TransactionType.DEPOSIT,
  TransactionType.INTERNAL_SWAP_DEPOSIT,
]

export const failedTransactionStatuses: TransactionStatus[] = [
  TransactionStatus.FAILED,
  TransactionStatus.REVERTED,
]
