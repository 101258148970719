import { useCallback, useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { TEAM_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  CardType,
  createPhysicalCard,
  createVirtualCard,
} from '@/features/Cards'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useKeyPress } from '@/hooks/useKeyPress'
import { hasOTPRequiredError } from '@/lib/error'
import { queryClient } from '@/lib/queryClient'
import { removeEmptyFormFields } from '@/lib/utils'
import { GoBackButton, OTPDialog } from '@/shared/components'
import { Button, SlideInScreen, StickyContainer, Typography } from '@/shared/ui'
import { CountryCode } from '@/types/country'
import { Role } from '@/types/roles'

import { createTransferPermissions, inviteBusinessUser } from '../../api'
import { MoneyMovementReviewSection } from '../MoneyMovementReviewSection'
import { TeamCardReviewSection } from '../TeamCardReviewSection'
import { TeamDetailsReviewSection } from '../TeamDetailsReviewSection'

import { MoneyMovementSchema } from './MoneyMovementStep'
import { TeamCardAddressSchema } from './TeamCardAddressStep'
import { TeamMemberSchema } from './TeamMemberDetailsStep'
import { TeamPhysicalCardDetailsSchema } from './TeamPhysicalCardDetailsStep'
import { TeamVirtualCardDetailsSchema } from './TeamVirtualCardDetailsStep'

type Props = {
  cardType?: CardType
  moneyMovement?: MoneyMovementSchema
  onBack: () => void
  role?: Role
  teamCardAddressDetails?: TeamCardAddressSchema
  teamVirtualCardDetails?: TeamVirtualCardDetailsSchema
  teamPhysicalCardDetails?: TeamPhysicalCardDetailsSchema
  teamMemberDetails?: TeamMemberSchema
}

export const TeamMemberReviewStep = ({
  cardType,
  moneyMovement,
  onBack,
  role,
  teamCardAddressDetails,
  teamVirtualCardDetails,
  teamPhysicalCardDetails,
  teamMemberDetails,
}: Props) => {
  const intl = useIntl()
  const [isOTPOpen, setIsOTPOpen] = useState(false)
  const navigate = useNavigate()
  const notifyError = useErrorToast()

  const {
    mutateAsync: inviteBusinessUserMutateAsync,
    isPending: inviteBusinessUserIsPending,
    isError: inviteBusinessUserIsError,
  } = useMutation({
    mutationFn: inviteBusinessUser,
  })

  const {
    mutateAsync: createVirtualCardMutateAsync,
    isPending: createVirtualCardIsPending,
  } = useMutation({
    mutationFn: createVirtualCard,
  })

  const {
    mutateAsync: createTransferPermissionsMutateAsync,
    isPending: createTransferPermissionsIsPending,
  } = useMutation({
    mutationFn: createTransferPermissions,
  })

  const {
    mutateAsync: createPhysicalCardMutateAsync,
    isPending: createPhysicalCardIsPending,
  } = useMutation({
    mutationFn: createPhysicalCard,
  })

  const handleInviteUser = useCallback(
    async (otp?: string) => {
      if (!teamMemberDetails || !role) {
        toast.error(
          intl.formatMessage({
            id: 'team.card.create.user.error',
            defaultMessage:
              'Missing team member details. Please go back and fill them',
          }),
        )
        return
      }

      removeEmptyFormFields(teamMemberDetails)

      try {
        const response = await inviteBusinessUserMutateAsync({
          role,
          firstName: teamMemberDetails.firstName,
          lastName: teamMemberDetails.lastName,
          secondLastName: teamMemberDetails.secondLastName,
          userTitle: teamMemberDetails.title,
          email: teamMemberDetails.email,
          otp,
        })

        if (moneyMovement) {
          await createTransferPermissionsMutateAsync({
            id: response.data.id,
            type: moneyMovement.movementPermission,
            limitPeriodType: moneyMovement.limitPeriodType,
            periodTransferLimitAmount: moneyMovement.periodTransferLimitAmount,
            singleTransferLimitAmount: moneyMovement.singleTransferLimitAmount,
          })
        }

        switch (cardType) {
          case CardType.VIRTUAL:
            if (!teamVirtualCardDetails) {
              toast.error(
                intl.formatMessage({
                  id: 'team.card.create.virtual.error',
                  defaultMessage:
                    'Missing virtual card details. Please go back and fill them',
                }),
              )
              return
            }

            await createVirtualCardMutateAsync({
              identityId: response.data.identityId,
              requestId: crypto.randomUUID(),
              walletId: teamVirtualCardDetails.walletId,
              nickname: teamVirtualCardDetails.nickname,
              cardLimit: {
                limitAmount: teamVirtualCardDetails.limitAmount,
                limitType: teamVirtualCardDetails.limitType,
              },
            })

            break

          case CardType.PHYSICAL: {
            if (!teamPhysicalCardDetails) {
              toast.error(
                intl.formatMessage({
                  id: 'team.card.create.details.physical.error',
                  defaultMessage:
                    'Missing physical card details. Please go back and fill them',
                }),
              )
              return
            }

            if (!teamCardAddressDetails) {
              toast.error(
                intl.formatMessage({
                  id: 'team.card.create.address.physical.error',
                  defaultMessage:
                    'Missing physical card address details. Please go back and fill them',
                }),
              )
              return
            }

            const parsedPhoneNumber =
              teamCardAddressDetails.localPhoneNumber.replace(
                teamCardAddressDetails.internationalPhonePrefix,
                '',
              )

            await createPhysicalCardMutateAsync({
              identityId: response.data.identityId,
              requestId: crypto.randomUUID(),
              walletId: teamPhysicalCardDetails.walletId,
              nickname: teamPhysicalCardDetails.nickname,
              localPhoneNumber: parsedPhoneNumber,
              internationalPhonePrefix:
                teamCardAddressDetails.internationalPhonePrefix,
              pin: teamPhysicalCardDetails.pin.code,
              cardLimit: {
                limitAmount: teamPhysicalCardDetails.limitAmount,
                limitType: teamPhysicalCardDetails.limitType,
              },
              deliveryAddress: {
                street: teamCardAddressDetails.addressStreet,
                streetNumber: teamCardAddressDetails.addressStreetNumber,
                apartment: teamCardAddressDetails.addressApartment,
                references: teamCardAddressDetails.addressReferences,
                neighborhood: teamCardAddressDetails.addressDistrict,
                city: teamCardAddressDetails.addressCity,
                state: teamCardAddressDetails.addressState,
                country: CountryCode.MX,
                postcode: teamCardAddressDetails.addressPostCode,
              },
            })
            break
          }
          default:
            break
        }

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getTeamMembers],
        })

        toast.success(
          intl.formatMessage({
            id: 'team.member.invite.success',
            defaultMessage: 'Invitation sent successfully',
          }),
        )

        navigate(TEAM_ROUTE)
      } catch (error) {
        if (error instanceof Error) {
          if (hasOTPRequiredError(error)) {
            setIsOTPOpen(true)
            return
          }

          notifyError(error)
        }
      }
    },
    [
      cardType,
      createPhysicalCardMutateAsync,
      createTransferPermissionsMutateAsync,
      createVirtualCardMutateAsync,
      intl,
      inviteBusinessUserMutateAsync,
      moneyMovement,
      navigate,
      notifyError,
      role,
      teamCardAddressDetails,
      teamMemberDetails,
      teamPhysicalCardDetails,
      teamVirtualCardDetails,
    ],
  )

  const isPending =
    inviteBusinessUserIsPending ||
    createVirtualCardIsPending ||
    createPhysicalCardIsPending ||
    createTransferPermissionsIsPending

  useKeyPress('Enter', handleInviteUser)

  const subtitleByRole = useMemo(() => {
    switch (role) {
      case Role.ADMIN:
        return (
          <FormattedMessage
            id="teamMember.create.reviewStep.admin.subtitle"
            defaultMessage="Review the work email carefully before sending. The Admin role has no restrictions over the account"
          />
        )

      case Role.PAYMENT_OPS:
        return (
          <FormattedMessage
            id="teamMember.create.reviewStep.paymentOps.subtitle"
            defaultMessage="Review the work email carefully before sending. The Payment Ops role has money movement capabilities"
          />
        )

      case Role.CARD_USER:
        return (
          <FormattedMessage
            id="teamMember.create.reviewStep.cardUser.subtitle"
            defaultMessage="Review the work email carefully before sending. This role will be able to spend with a card"
          />
        )

      case Role.READ_ONLY:
        return (
          <FormattedMessage
            id="teamMember.create.reviewStep.readOnly.subtitle"
            defaultMessage="Review the work email carefully before sending. This role is able to view and download all of the account's information"
          />
        )

      default:
        return
    }
  }, [role])

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="teamMember.create.reviewStep.title"
            defaultMessage="Review and invite {name} to join the team!"
            values={{
              name: teamMemberDetails?.firstName,
            }}
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">{subtitleByRole}</Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-6">
          <TeamDetailsReviewSection
            teamMemberDetails={teamMemberDetails}
            role={role}
          />

          <MoneyMovementReviewSection moneyMovement={moneyMovement} />

          <TeamCardReviewSection
            cardType={cardType}
            teamCardAddressDetails={teamCardAddressDetails}
            teamVirtualCardDetails={teamVirtualCardDetails}
            teamPhysicalCardDetails={teamPhysicalCardDetails}
          />
        </div>

        <StickyContainer>
          <Button
            disabled={isPending}
            loading={isPending}
            width="full"
            onClick={() => handleInviteUser()}
          >
            <FormattedMessage
              defaultMessage="Invite {name} as {role}!"
              id="teamMember.action.invite"
              values={{
                name: teamMemberDetails?.firstName,
                role: (
                  <FormattedMessage
                    id="team.role.label"
                    defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
                    values={{ role }}
                  />
                ),
              }}
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>

      <OTPDialog
        title={intl.formatMessage(
          {
            id: 'teamMember.invite.otp.title',
            defaultMessage: 'Add {name} as {role}',
          },
          {
            name: teamMemberDetails?.firstName,
            role: (
              <FormattedMessage
                id="team.role.label"
                defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
                values={{ role }}
              />
            ),
          },
        )}
        description={intl.formatMessage(
          {
            id: 'teamMember.invite.otp.description',
            defaultMessage:
              'We have sent you an email with a verification code. Enter it to add {name} as {role}',
          },
          {
            name: teamMemberDetails?.firstName,
            role: (
              <FormattedMessage
                id="teamMember.details.role"
                defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
                values={{ role }}
              />
            ),
          },
        )}
        action={<FormattedMessage id="action.invite" defaultMessage="Invite" />}
        isOpen={isOTPOpen}
        onOpenChange={setIsOTPOpen}
        isPending={inviteBusinessUserIsPending}
        isError={inviteBusinessUserIsError}
        onContinue={handleInviteUser}
      />
    </>
  )
}
