import { IntlShape } from 'react-intl'

import { Address } from '@/features/Accounts/types'
import { Recipient } from '@/features/Recipients/types'
import { BusinessIdentity } from '@/types/business'
import { AddressComponent } from '@/types/global'

export function getFullAddress(address: Address, intl: IntlShape) {
  if (!address) return null

  if (!address.street || !address.city || !address.state || !address.postcode) {
    return null
  }

  return `${address.street}, ${address.city},\n ${address.state}, ${address.postcode}, ${intl?.formatMessage(
    {
      id: 'country.name',
      defaultMessage:
        '{country, select, MEX {Mexico} USA {United States} ARG {Argentina} BRA {Brazil} COL {Colombia} CHL {Chile} URY {Uruguay} ESP {Spain} PRT {Portugal} other {}}',
    },
    {
      country: address.countryCode,
    },
  )}`
}

export function getRecipientAddress(recipient?: Recipient, intl?: IntlShape) {
  if (!recipient) return ''

  const { addressCity, addressState, addressStreet, addressZipCode, country } =
    recipient

  if (
    !addressCity ||
    !addressState ||
    !addressStreet ||
    !addressZipCode ||
    !country
  ) {
    return ''
  }

  return `${addressStreet},\n ${addressCity}, ${addressState}, ${addressZipCode}, ${intl?.formatMessage(
    {
      id: 'country.name',
      defaultMessage:
        '{country, select, MEX {Mexico} USA {United States} ARG {Argentina} BRA {Brazil} COL {Colombia} CHL {Chile} URY {Uruguay} ESP {Spain} PRT {Portugal} other {}}',
    },
    {
      country: country,
    },
  )}`
}

export function getBusinessAddress(
  business?: BusinessIdentity,
  intl?: IntlShape,
) {
  if (!business) return null

  const {
    addressCity,
    addressMunicipality,
    addressNeighborhood,
    addressPostalCode,
    addressState,
    addressStreet,
    addressStreetNumber,
    countryOfIncorporation,
  } = business

  const addressCountry = intl?.formatMessage(
    {
      id: 'country.name',
      defaultMessage:
        '{country, select, MEX {Mexico} USA {United States} ARG {Argentina} BRA {Brazil} COL {Colombia} CHL {Chile} URY {Uruguay} ESP {Spain} PRT {Portugal} other {}}',
    },
    { country: countryOfIncorporation },
  )

  const addressValues = [
    addressStreet,
    addressStreetNumber,
    addressNeighborhood,
    addressMunicipality,
    addressCity,
    addressState,
    addressPostalCode,
    addressCountry,
  ]

  const filteredValues = addressValues.filter((value) => !!value)

  const joinedAddress = filteredValues.reduce((acc, value, index) => {
    if (index === 1) {
      return `${acc}  ${value}`
    }
    if (index === 3) {
      return `${acc},\n ${value}`
    }

    return `${acc}, ${value}`
  })

  return joinedAddress
}

export interface GooglePlacesParsedAddress {
  street: string
  streetNumber: string
  neighborhood: string
  city: string
  state: string
  postcode: string
  country: string
}

export function mapGooglePlacesAddress(
  address: AddressComponent[],
): GooglePlacesParsedAddress {
  const FIELD_STREET = 'route'
  const FIELD_NUMBER = 'street_number'
  const FIELD_NEIGHBORHOOD = 'neighborhood'
  const FIELD_SUBLOCALITY_1 = 'sublocality_level_1'
  const FIELD_LOCALITY = 'locality'
  const FIELD_ADMINISTRATIVE_AREA_1 = 'administrative_area_level_1'
  const FIELD_POSTAL_CODE = 'postal_code'
  const FIELD_COUNTRY = 'country'

  const street =
    address.find((component) => component.types.includes(FIELD_STREET))
      ?.long_name || ''

  const streetNumber =
    address.find((component) => component.types.includes(FIELD_NUMBER))
      ?.long_name || ''

  const neighborhood =
    address.find(
      (component) =>
        component.types.includes(FIELD_NEIGHBORHOOD) ||
        component.types.includes(FIELD_SUBLOCALITY_1),
    )?.long_name || ''

  const city =
    address.find((component) => component.types.includes(FIELD_LOCALITY))
      ?.long_name || ''

  const state =
    address.find((component) =>
      component.types.includes(FIELD_ADMINISTRATIVE_AREA_1),
    )?.short_name || ''

  const country =
    address.find((component) => component.types.includes(FIELD_COUNTRY))
      ?.long_name || ''

  const postalCode =
    address.find((component) => component.types.includes(FIELD_POSTAL_CODE))
      ?.long_name || ''

  return {
    street,
    streetNumber,
    neighborhood,
    city,
    state,
    postcode: postalCode,
    country,
  }
}
