import { defineMessage, FormattedMessage } from 'react-intl'

import { teamPermissionsMessages } from '@/constants/messages'
import { Feature, useFeatures } from '@/hooks/useFeatures'
import { cn } from '@/lib/utils'
import { Check, X } from '@/shared/icons/outline'
import { Button, Card, SlideInScreen, Typography } from '@/shared/ui'
import { Permission } from '@/types/permissions'
import { Role } from '@/types/roles'

const HEADERS = [
  {
    label: defineMessage({
      id: 'label.permissions',
      defaultMessage: 'Permissions',
    }),
  },
  {
    label: defineMessage({
      id: 'role.admin',
      defaultMessage: 'Admin',
    }),
  },
  {
    label: defineMessage({
      id: 'role.paymentOps',
      defaultMessage: 'Payment Ops',
    }),
  },
  {
    label: defineMessage({
      id: 'role.cardUser',
      defaultMessage: 'Card User',
    }),
  },
  {
    label: defineMessage({
      id: 'role.readOnly',
      defaultMessage: 'Read Only',
    }),
  },
]

const PERMISSIONS = [
  {
    name: Permission.TEAM_MANAGEMENT,
    roles: [Role.ADMIN],
  },
  {
    name: Permission.REQUEST_APPROVALS,
    roles: [Role.ADMIN],
  },
  {
    name: Permission.CARDS_MANAGEMENT,
    roles: [Role.ADMIN],
  },
  {
    name: Permission.TRANSFERS,
    roles: [Role.ADMIN, Role.PAYMENT_OPS],
  },
  {
    name: Permission.CARDS_ACCESS,
    roles: [Role.ADMIN, Role.PAYMENT_OPS, Role.CARD_USER],
  },
  {
    name: Permission.READ_ACCESS,
    roles: [Role.ADMIN, Role.PAYMENT_OPS, Role.READ_ONLY],
  },
]

type Props = {
  onContinue: (role: Role) => void
}

export const TeamMemberTypeStep = ({ onContinue }: Props) => {
  const [hasTasksFeature] = useFeatures([Feature.TASKS])

  return (
    <SlideInScreen className="h-screen max-w-4xl">
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Add a new team member"
          id="teamMember.select.title"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          defaultMessage="Choose the role you want according to the permissions that this member should have"
          id="teamMember.select.description"
        />
      </Typography>

      <div className="p-6" />

      <Card size="none">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-neutral-gray-200">
            <thead>
              <tr>
                {HEADERS.map((header, index) => {
                  if (
                    !hasTasksFeature &&
                    header.label.id === 'role.paymentOps'
                  ) {
                    return null
                  }

                  return (
                    <th
                      key={header.label.id}
                      className={cn(
                        'py-3 text-neutral-gray-800',

                        index === 0 && 'pl-6',
                      )}
                    >
                      <Typography
                        text={index === 0 ? 'left' : 'center'}
                        variant="body-small"
                        bold
                      >
                        <FormattedMessage {...header.label} />
                      </Typography>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className="divide-y divide-neutral-gray-200 bg-white">
              {PERMISSIONS.map((permission) => (
                <tr key={permission.name}>
                  <td className="whitespace-no-wrap px-6 py-3">
                    <div className="flex flex-col">
                      <Typography>
                        <FormattedMessage
                          {...teamPermissionsMessages[permission.name].title}
                        />
                      </Typography>
                      <Typography className="text-neutral-gray-600">
                        <FormattedMessage
                          {...teamPermissionsMessages[permission.name]
                            .description}
                        />
                      </Typography>
                    </div>
                  </td>
                  {Object.values(Role)
                    .filter((role) => {
                      if (hasTasksFeature) {
                        return true
                      }

                      return role !== Role.PAYMENT_OPS
                    })
                    .map((role) => (
                      <td key={role} className="whitespace-no-wrap text-center">
                        <div className="flex items-center justify-center">
                          <div
                            className={cn(
                              'flex size-5 items-center justify-center rounded-full text-white',
                              {
                                'bg-primary': permission.roles.includes(role),
                                'bg-neutral-gray-300':
                                  !permission.roles.includes(role),
                              },
                            )}
                          >
                            {permission.roles.includes(role) ? (
                              <Check className="size-4" />
                            ) : (
                              <X className="size-5" />
                            )}
                          </div>
                        </div>
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="whitespace-no-wrap px-6 py-3" />
                {Object.values(Role)
                  .filter((role) => {
                    if (hasTasksFeature) {
                      return true
                    }

                    return role !== Role.PAYMENT_OPS
                  })

                  .map((role) => (
                    <td key={role} className="px-6 py-3">
                      <div className="flex justify-center">
                        <Button size="xs" onClick={() => onContinue(role)}>
                          <FormattedMessage
                            id="action.select"
                            defaultMessage="Select"
                          />
                        </Button>
                      </div>
                    </td>
                  ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </Card>

      <div className="p-8 md:p-0" />
    </SlideInScreen>
  )
}
