import { FormattedMessage } from 'react-intl'

import {
  bgColorClassNameByBulkStatus,
  bgColorClassNameByStatus,
  dotColorClassNameByBulkStatus,
  dotColorClassNameByStatus,
  textColorClassNameByBulkStatus,
  textColorClassNameByStatus,
} from '@/features/Transactions'
import { DisplayableType, Transaction } from '@/features/Transactions/types'
import { cn } from '@/lib/utils'

import { Typography } from '../../ui'

type Props = {
  transaction: Transaction
}

export const StateCell = ({ transaction }: Props) => {
  switch (transaction.displayableType) {
    case DisplayableType.SINGLE:
      return (
        <div className="flex items-center">
          <div
            className={cn(
              'flex h-[22px] items-center gap-1.5 rounded-xl px-2',
              `${bgColorClassNameByStatus[transaction.transactionStatus]}`,
            )}
          >
            <span
              className={cn(
                'size-1.5 rounded-full',
                dotColorClassNameByStatus[transaction.transactionStatus],
              )}
            />

            <Typography
              className={cn(
                textColorClassNameByStatus[transaction.transactionStatus],
              )}
              bold
              variant="body-small"
            >
              <FormattedMessage
                id="transaction.status"
                defaultMessage="{status, select, FAILED {Failed} BLOCKED {Blocked} REVERTED {Reverted} PENDING {Pending} COMPLETED {Completed} other {}}"
                values={{ status: transaction.transactionStatus }}
              />
            </Typography>
          </div>
        </div>
      )

    case DisplayableType.BULK:
      return (
        <div className="flex items-center">
          <div
            className={cn(
              'flex h-[22px] items-center gap-1.5 rounded-xl px-2',
              `${bgColorClassNameByBulkStatus[transaction.bulkTransactionStatus]}`,
            )}
          >
            <span
              className={cn(
                'size-1.5 rounded-full',
                dotColorClassNameByBulkStatus[
                  transaction.bulkTransactionStatus
                ],
              )}
            />

            <Typography
              className={cn(
                textColorClassNameByBulkStatus[
                  transaction.bulkTransactionStatus
                ],
              )}
              variant="body-small"
              bold
            >
              <FormattedMessage
                id="transaction.bulk.status"
                defaultMessage="{status, select, INCOMPLETE {Incomplete} PENDING {Pending} COMPLETED {Completed} other {}}"
                values={{ status: transaction.bulkTransactionStatus }}
              />
            </Typography>
          </div>
        </div>
      )

    default:
      return null
  }
}
