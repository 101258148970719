import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { getCodeByCountry } from '@/lib/country'
import { CountryCodeField, OptionalTag, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  PhoneNumberInput,
  StickyContainer,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { IndividualMXRecipient } from '../types'

import { clabeValidation, individualValidation } from './validations'

const DEFAULT_VALUES: CreateIndividualSchema = {
  firstName: '',
  lastName: '',
  secondLastName: '',
  email: '',
  localPhoneNumber: '',
  clabe: '',
}

const createIndividualSchema = individualValidation.and(clabeValidation)

const MX_INDIVIDUAL_FORM_ID = 'mx-individual-form'

export type CreateIndividualSchema = z.infer<typeof createIndividualSchema>

type Props = {
  country: CountryCode
  onContinue: (values: CreateIndividualSchema) => void
  recipient?: IndividualMXRecipient
}

export const MXIndividualForm = ({ country, onContinue, recipient }: Props) => {
  const intl = useIntl()
  const form = useForm<CreateIndividualSchema>({
    mode: 'onChange',
    resolver: zodResolver(createIndividualSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
      internationalPhonePrefix: getCodeByCountry(country),
    },
  })

  const onSubmit: SubmitHandler<CreateIndividualSchema> = (values) => {
    const { internationalPhonePrefix, localPhoneNumber, ...rest } = values

    const parsedPhoneNumber =
      localPhoneNumber?.replace(`${internationalPhonePrefix}`, '') ?? ''

    const phoneFields =
      parsedPhoneNumber !== ''
        ? {
            localPhoneNumber: parsedPhoneNumber,
            internationalPhonePrefix,
          }
        : undefined

    onContinue({
      ...rest,
      ...phoneFields,
    })
  }

  useEffect(() => {
    if (recipient) {
      form.setValue('firstName', recipient.firstName)
      form.setValue('lastName', recipient.lastName)
      form.setValue('secondLastName', recipient.secondLastName)
      form.setValue('email', recipient.email)

      if (recipient.localPhoneNumber) {
        form.setValue(
          'internationalPhonePrefix',
          recipient.internationalPhonePrefix,
        )

        form.setValue(
          'localPhoneNumber',
          recipient.internationalPhonePrefix + recipient.localPhoneNumber,
        )
      }

      form.setValue('clabe', recipient.localInformation.clabe)

      form.trigger()
    }
  }, [form, recipient])

  return (
    <>
      <Form {...form}>
        <form
          id={MX_INDIVIDUAL_FORM_ID}
          className="w-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Widget
            title={
              <FormattedMessage
                id="recipient.contactDetails"
                defaultMessage="Contact details"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="given-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'First name',
                        id: 'label.firstName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="First name"
                      id="label.firstName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="family-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Last name',
                        id: 'label.lastName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Last name"
                      id="label.lastName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="secondLastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="additional-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Second last name',
                        id: 'label.secondLastName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Second last name"
                      id="label.secondLastName"
                    />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="email"
                      placeholder={intl.formatMessage({
                        id: 'label.contactEmail',
                        defaultMessage: 'Contact email',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="label.contactEmail"
                      defaultMessage="Contact email"
                    />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            <div className="flex items-stretch gap-3">
              <FormField
                control={form.control}
                name="internationalPhonePrefix"
                render={({ field }) => {
                  return (
                    <CountryCodeField
                      value={field.value}
                      onSelect={(value) =>
                        form.setValue('internationalPhonePrefix', value)
                      }
                    />
                  )
                }}
              />

              <FormField
                control={form.control}
                name="localPhoneNumber"
                render={({ field }) => (
                  <FormItem className="relative flex-1">
                    <FormControl>
                      <PhoneNumberInput
                        phonePrefix={
                          form.watch('internationalPhonePrefix') ?? ''
                        }
                        placeholder={intl.formatMessage({
                          id: 'label.contactPhoneNumber',
                          defaultMessage: 'Contact phone number',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        id="label.contactPhoneNumber"
                        defaultMessage="Contact phone number"
                      />
                    </AnimatedFormLabel>

                    {field.value === '' && <OptionalTag />}
                  </FormItem>
                )}
              />
            </div>
          </Widget>

          <div className="p-4" />

          <Widget
            title={
              <FormattedMessage
                id="recipient.paymentDetails"
                defaultMessage="Payment details"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="clabe"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.clabe',
                        defaultMessage: 'CLABE',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage id="label.clabe" defaultMessage="CLABE" />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
          </Widget>
        </form>
      </Form>

      <StickyContainer>
        <Button
          width="full"
          form={MX_INDIVIDUAL_FORM_ID}
          disabled={!form.formState.isValid}
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Save & Continue"
            id="action.saveAndContinue"
          />
        </Button>
      </StickyContainer>
    </>
  )
}
