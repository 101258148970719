import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'

import { currencyToCountryFlag } from '@/constants/countries'
import { getAccountPDF } from '@/features/Accounts/api'
import { AccountType, BankAccount } from '@/features/Accounts/types'
import { getFlagUrl } from '@/lib/images'
import { downloadFile } from '@/lib/utils'
import { Button, MotionDiv, Typography } from '@/shared/ui'

import { Download, Spinner } from '../../icons/outline'

import { CountryFields } from './CountryFields'

type Props = {
  details: BankAccount
  walletId?: string
}

export const BankDetails = ({ details, walletId }: Props) => {
  const intl = useIntl()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: getAccountPDF,
  })

  const getFileName = () => {
    switch (details.type) {
      case AccountType.LOCAL_MX:
        return intl.formatMessage({
          id: 'account.details.MX.pdf',
          defaultMessage: 'account-details-MX.pdf',
        })

      case AccountType.ACH:
        return intl.formatMessage({
          id: 'account.details.US.ACH.pdf',
          defaultMessage: 'account-details-US-ACH.pdf',
        })

      default:
        return ''
    }
  }

  const downloadPDF = () => {
    if (!walletId) return

    const fileName = getFileName()

    mutateAsync({ type: details.type, id: walletId }).then((data) =>
      downloadFile(data.data, fileName),
    )
  }

  if (details.type === AccountType.WIRE) {
    return null
  }

  return (
    <MotionDiv>
      <div className="flex w-full justify-between px-2">
        <div className="flex items-center gap-2">
          <img
            src={getFlagUrl(currencyToCountryFlag[details.currency].flagCode)}
            className="size-3"
            alt={intl.formatMessage(
              {
                id: `country.flag.alt`,
                defaultMessage:
                  '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
              },
              { country: details.currency },
            )}
          />

          <Typography bold>
            <FormattedMessage
              id="account.details.labelByCurrency"
              defaultMessage="{currency, select, MXN {MX account details} USD {US account details} other {}}"
              values={{ currency: details.currency }}
            />
          </Typography>
        </div>

        <Button
          disabled={isPending}
          aria-label={
            isPending
              ? intl.formatMessage({
                  id: 'action.downloading',
                  defaultMessage: 'Downloading...',
                })
              : intl.formatMessage({
                  id: 'action.downloadPDF',
                  defaultMessage: 'Download PDF',
                })
          }
          className="size-8 p-2"
          size="inline"
          variant="tertiary"
          onClick={downloadPDF}
        >
          {isPending ? (
            <Spinner className="size-4 animate-spin" />
          ) : (
            <Download className="size-4" />
          )}
        </Button>
      </div>

      <div className="p-1" />

      <CountryFields details={details} />

      <div className="p-3" />
    </MotionDiv>
  )
}
