export const DISABLED_BUSINESS_IDS = [
  'c34ca83d-f2ef-4c8b-a6dc-3cd1645d03ad',
  '6d37796f-9638-4c48-8bbc-ce41d4f2ec60',
  '485feb15-2f32-434a-af5a-edad0281acef',
  'df11691d-9fc3-4f49-9c22-1a9d60e9eac6',
  '20dc53b4-0975-4b5f-8107-49297fc254be',
  '3655990b-5228-456c-9769-57bdb4143aa8',
  'ef95beec-f907-43f7-bac8-7ab535898fb7',
  '1fc8a715-f34f-4339-b076-db5d48280922',
  'eb9a347c-6ea6-49b4-946c-a4978fae9f6d',
  '025444c1-49db-4871-99da-459a72f09154',
]
