import { useIntl } from 'react-intl'

import { Spinner, X } from '../icons/outline'
import { Document } from '../icons/solid'
import { Button, Typography } from '../ui'

type Props = {
  isPending: boolean
  file: string
  onClick: (file: string) => void
}

export const UploadedFile = ({ onClick, isPending, file }: Props) => {
  const intl = useIntl()
  return (
    <div className="flex w-full items-center justify-between rounded-lg bg-neutral-gray-100 p-2">
      <div className="flex items-center gap-2">
        <Document className="h-4 w-4" />
        <Typography>{file}</Typography>
      </div>
      <Button
        disabled={isPending}
        type="button"
        size="inline"
        aria-label={intl.formatMessage({
          defaultMessage: 'Delete file',
          id: 'action.deleteFile',
        })}
        onClick={(e) => {
          e.stopPropagation()

          onClick(file)
        }}
        variant="ghost"
      >
        {isPending ? (
          <Spinner className="size-4 shrink-0 animate-spin" />
        ) : (
          <X className="size-4" />
        )}
      </Button>
    </div>
  )
}
