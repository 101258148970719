import { CountryCode, CountryData } from '@/types/country'

export enum OnboardingStateEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  VERIFICATION = 'VERIFICATION',
  COMPLETED = 'COMPLETED',
}

export enum OnboardingStepName {
  COMPANY_FORMATION = 'COMPANY_FORMATION',
  BUSINESS_ADDRESS = 'BUSINESS_ADDRESS',
  LEGAL_REPRESENTATIVE = 'LEGAL_REPRESENTATIVE',
  COMPANY_ACTIVITY = 'COMPANY_ACTIVITY',
  COMPANY_OWNERSHIP = 'COMPANY_OWNERSHIP',
  IDENTITY_VALIDATION = 'IDENTITY_VALIDATION',
  REVIEW_AND_SUBMIT = 'REVIEW_AND_SUBMIT',
}

export type OnboardingStep = {
  id: string
  name: OnboardingStepName
  state: OnboardingStateEnum
}

export type OnboardingField = {
  name: string
  required: boolean
  allowedValues?: string[]
}

export type OnboardingDocumentField = {
  type: string
  required: boolean
}

export type OnboardingStepConfig = {
  name: OnboardingStepName
  fields: OnboardingField[]
  documents: OnboardingDocumentField[]
}

export type OnboardingState = {
  id: string
  state: OnboardingStateEnum
  steps: OnboardingStep[]
}

export type OnboardingConfig = {
  country: CountryCode
  steps: OnboardingStepConfig[]
}

export type OnboardingCountry = CountryData & {
  availableForBusinessMembers: boolean
}

export type OnboardingDocument = {
  documentType: string
  fileName: string
}

export type CompanyFormationStep = {
  stepName: OnboardingStepName.COMPANY_FORMATION
  stepDetails: {
    dateOfIncorporation?: string
    countryOfIncorporation?: string
    taxId?: string
    taxIdType?: string
  } & StepDocuments
}

export type BusinessAddressStep = {
  stepName: OnboardingStepName.BUSINESS_ADDRESS
  stepDetails: {
    addressStreet?: string
    addressStreetNumber?: string
    addressDistrict?: string
    addressCity?: string
    addressState?: string
    addressPostCode?: string
    addressCountry?: string
  } & StepDocuments
}

type LegalRepresentativeUser = {
  firstName?: string
  lastName?: string
  secondLastName?: string
  email?: string
  internationalPhonePrefix?: string
  localPhoneNumber?: string
  taxId?: string
  taxResidenceCountry?: CountryCode
}

export type LegalRepresentativeStep = {
  stepName: OnboardingStepName.LEGAL_REPRESENTATIVE
  stepDetails: {
    legalRepresentative?: LegalRepresentativeUser
  } & StepDocuments
}

export type CompanyActivityStep = {
  stepName: OnboardingStepName.COMPANY_ACTIVITY
  stepDetails: {
    activityType?: string
    website?: string
    expectedVolume?: string
    sourcesOfFunds?: string[]
    description?: string
    operatesInHighRiskActivities?: boolean
    operatesInProhibitedActivities?: boolean
    operatesInProhibitedCountries?: boolean
  } & StepDocuments
}

export type IdentityValidationStep = {
  stepName: OnboardingStepName.IDENTITY_VALIDATION
  stepDetails: {
    members?: OnboardingMember[]
  } & StepDocuments
}

export type IdentityValidationSubmitStep = {
  stepName: OnboardingStepName.IDENTITY_VALIDATION
  stepDetails: Record<string, never>
}

export enum OnboardingMemberRole {
  LEGAL_REPRESENTATIVE = 'LEGAL_REPRESENTATIVE',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
}

export enum OnboardingMemberState {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}

export type OnboardingMember = {
  email: string
  firstName?: string
  lastName?: string
  secondLastName?: string
  roles: OnboardingMemberRole[]
  onboardingMemberValidationId?: string
  validationLink?: string
  state: OnboardingMemberState
}

export type CompanyOwner = {
  id: string
  firstName?: string
  lastName?: string
  secondLastName?: string
  email?: string
  internationalPhonePrefix?: string
  localPhoneNumber?: string
  taxId?: string
  taxResidenceCountry?: CountryCode
  addressStreet?: string
  addressStreetNumber?: string
  addressDistrict?: string
  addressCity?: string
  addressState?: string
  addressPostCode?: string
  addressCountry?: string
}

export type CompanyOwnershipStep = {
  stepName: OnboardingStepName.COMPANY_OWNERSHIP
  stepDetails: {
    beneficialOwners?: CompanyOwner[]
  } & StepDocuments
}

export type CompanyOwnershipSubmitStep = {
  stepName: OnboardingStepName.COMPANY_OWNERSHIP
  stepDetails: {
    beneficialOwnersIds?: string[]
  } & StepDocuments
}

export type ReviewAndSubmitStep = {
  stepName: OnboardingStepName.REVIEW_AND_SUBMIT
  stepDetails: {
    reviewElements?: Exclude<OnboardingStepDetails, ReviewAndSubmitStep>[]
  } & StepDocuments
}

export type ReviewAndSubmitRequestStep = {
  stepName: OnboardingStepName.REVIEW_AND_SUBMIT
  stepDetails: Record<string, never>
}

type StepDocuments = {
  documents: OnboardingDocument[]
}

export type OnboardingStepDetails =
  | CompanyFormationStep
  | BusinessAddressStep
  | LegalRepresentativeStep
  | CompanyActivityStep
  | CompanyOwnershipStep
  | IdentityValidationStep
  | ReviewAndSubmitStep

export type OnboardingSubmitStepDetails =
  | CompanyFormationStep
  | BusinessAddressStep
  | LegalRepresentativeStep
  | CompanyActivityStep
  | CompanyOwnershipSubmitStep
  | IdentityValidationSubmitStep
  | ReviewAndSubmitRequestStep

export enum LegalRepresentativeType {
  USER = 'USER',
  OTHER = 'OTHER',
}

export enum OwnerType {
  NEW_OWNER = 'NEW_OWNER',
  USER_OWNER = 'USER_OWNER',
  USER_LEGAL_REPRESENTATIVE_AND_OWNER = 'USER_LEGAL_REPRESENTATIVE_AND_OWNER',
  OTHER_LEGAL_REPRESENTATIVE_AND_OWNER = 'OTHER_LEGAL_REPRESENTATIVE_AND_OWNER',
}

export type IdentityValidation = {
  id: string
  state: OnboardingMemberState
  validationLink: string
}
