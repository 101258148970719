export const onboardingAddressFieldsOrder = [
  'ADDRESS_STREET',
  'ADDRESS_STREET_NUMBER',
  'ADDRESS_DISTRICT',
  'ADDRESS_CITY',
  'ADDRESS_STATE',
  'ADDRESS_POST_CODE',
  'ADDRESS_COUNTRY',
] as const

export const bgColorClassNameByVerificationStatus: Record<string, string> = {
  NOT_STARTED: 'bg-neutral-gray-100',
  IN_PROGRESS: 'bg-primary-info/10',
  COMPLETED: 'bg-primary-light',
  REJECTED: 'bg-primary-error/15',
}

export const textColorClassNameByVerificationStatus: Record<string, string> = {
  NOT_STARTED: 'text-neutral-gray-800',
  IN_PROGRESS: 'text-primary-info',
  COMPLETED: 'text-primary-dark',
  REJECTED: 'text-primary-error',
}

export const dotColorClassNameByVerificationStatus: Record<string, string> = {
  NOT_STARTED: 'bg-neutral-gray-800',
  IN_PROGRESS: 'bg-primary-info',
  COMPLETED: 'bg-primary-dark',
  REJECTED: 'bg-primary-error',
}
