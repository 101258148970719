import { FormattedMessage } from 'react-intl'

import {
  SingleTransaction,
  TransactionType,
} from '@/features/Transactions/types'
import { getTransactionPaymentMethod } from '@/features/Transactions/utils'
import { formatLastFour } from '@/lib/card'
import { parseAdditionalDetails } from '@/lib/utils'

import { Typography } from '../../ui'

type Props = {
  transaction: SingleTransaction
}

export const SingleMethodCell = ({ transaction }: Props) => {
  const paymentMethod = getTransactionPaymentMethod(transaction)

  switch (transaction.transactionType) {
    case TransactionType.REWARD:
    case TransactionType.WITHDRAW_REFUND:
      return (
        <div className="flex items-center">
          <Typography>
            <FormattedMessage id="label.refund" defaultMessage="Refund" />
          </Typography>
        </div>
      )

    case TransactionType.INTERNAL_SWAP_DEPOSIT:
    case TransactionType.INTERNAL_SWAP_WITHDRAW:
      return (
        <div className="flex items-center">
          <Typography>
            <FormattedMessage id="label.transfer" defaultMessage="Move" />
          </Typography>
        </div>
      )

    case TransactionType.CASHBACK:
      return (
        <div className="flex items-center">
          <Typography>
            <FormattedMessage id="label.reward" defaultMessage="Reward" />
          </Typography>
        </div>
      )

    case TransactionType.FEE:
      return (
        <div className="flex items-center">
          <Typography>
            <FormattedMessage id="label.feeDebit" defaultMessage="Fee Debit" />
          </Typography>
        </div>
      )

    case TransactionType.DEPOSIT:
      return (
        <div className="flex flex-col justify-center gap-1">
          <Typography>
            <FormattedMessage id="label.deposit" defaultMessage="Deposit" />
          </Typography>
          <Typography className="text-neutral-gray-600">
            {paymentMethod}
          </Typography>
        </div>
      )

    case TransactionType.WITHDRAW:
      return (
        <div className="flex flex-col justify-center">
          <Typography>
            <FormattedMessage
              id="label.withdrawal"
              defaultMessage="Withdrawal"
            />
          </Typography>
          <Typography className="text-neutral-gray-600">
            {paymentMethod}
          </Typography>
        </div>
      )

    case TransactionType.CARD_PAYMENT_REFUND:
    case TransactionType.CARD_PAYMENT_CHARGEBACK:
    case TransactionType.CARD_PAYMENT: {
      const parsedAdditionalDetails = parseAdditionalDetails<{
        cardType?: string
        cardLastFourDigits?: string
      }>(transaction.additionalDetails)

      return (
        <div className="flex flex-col justify-center">
          <Typography>
            {(() => {
              switch (transaction.transactionType) {
                case TransactionType.CARD_PAYMENT_CHARGEBACK:
                  return (
                    <FormattedMessage
                      id="label.cardChargeback"
                      defaultMessage="Card chargeback"
                    />
                  )
                case TransactionType.CARD_PAYMENT:
                  return (
                    <FormattedMessage id="label.card" defaultMessage="Card" />
                  )

                case TransactionType.CARD_PAYMENT_REFUND:
                  return (
                    <FormattedMessage
                      id="label.cardRefund"
                      defaultMessage="Card refund"
                    />
                  )
                default:
                  return (
                    <FormattedMessage id="label.card" defaultMessage="Card" />
                  )
              }
            })()}
          </Typography>
          <div className="flex gap-1">
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="cards.type.selection"
                defaultMessage="{type, select, VIRTUAL {Virtual} PHYSICAL {Physical} other {}}"
                values={{ type: parsedAdditionalDetails?.cardType }}
              />
            </Typography>
            <Typography className="text-neutral-gray-600">
              {formatLastFour(parsedAdditionalDetails?.cardLastFourDigits)}
            </Typography>
          </div>
        </div>
      )
    }

    default:
      return null
  }
}
