import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { z } from 'zod'

import { queryKeys } from '@/constants/queryKeys'
import { getAccount } from '@/features/Accounts/api'
import { useBusinessUser } from '@/hooks/useBusinessUser'
import { getFullName } from '@/lib/typography'
import { GoBackButton, VirtualCardFields } from '@/shared/components'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { PaymentLimitType } from '@/types/limits'

import { TeamMemberSchema } from './TeamMemberDetailsStep'

const CARD_INFO_FORM_ID = 'card-info-form-id'

const teamCardDetailsSchema = z.object({
  nickname: z.string().min(1, {
    message: 'validation.nickname.required',
  }),
  requestId: z.string(),
  identityId: z.string(),
  walletId: z.string().min(1),
  limitAmount: z.string().refine((v) => Big(v === '' ? 0 : v).gt(0), {
    message: 'validation.balance.minAmount',
  }),
  limitType: z.nativeEnum(PaymentLimitType),
})

export type TeamVirtualCardDetailsSchema = z.infer<typeof teamCardDetailsSchema>

const DEFAULT_VALUES: TeamVirtualCardDetailsSchema = {
  nickname: '',
  walletId: '',
  requestId: '',
  identityId: '',
  limitAmount: '',
  limitType: '' as PaymentLimitType,
}

type Props = {
  onBack: () => void
  onContinue: (data: TeamVirtualCardDetailsSchema) => void
  teamCardDetails?: TeamVirtualCardDetailsSchema
  teamMemberDetails?: TeamMemberSchema
}

export const TeamVirtualCardDetailsStep = ({
  onBack,
  onContinue,
  teamCardDetails,
  teamMemberDetails,
}: Props) => {
  const form = useForm<TeamVirtualCardDetailsSchema>({
    mode: 'onChange',
    resolver: zodResolver(teamCardDetailsSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const accountQuery = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) => data?.data,
  })

  const businessUser = useBusinessUser()

  useEffect(() => {
    if (teamCardDetails) {
      form.setValue('nickname', teamCardDetails.nickname)
      form.setValue('walletId', teamCardDetails.walletId)
      form.setValue('requestId', teamCardDetails.requestId)
      form.setValue('limitAmount', teamCardDetails.limitAmount)
      form.setValue('limitType', teamCardDetails.limitType)

      form.trigger()
      return
    }
  }, [businessUser?.identityId, form, teamCardDetails, teamMemberDetails])

  const onSubmit: SubmitHandler<TeamVirtualCardDetailsSchema> = async (
    data,
  ) => {
    onContinue(data)
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="cards.create.infoStep.virtual.title"
            defaultMessage="Create a virtual card"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="cards.create.infoStep.virtual.description"
            defaultMessage="Customize the card to fit your needs. You can have as many as you want"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={CARD_INFO_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <VirtualCardFields
              form={form}
              fullName={getFullName(teamMemberDetails)}
              wallets={accountQuery.data?.wallets}
            />
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={CARD_INFO_FORM_ID}
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
