import { FormattedMessage } from 'react-intl'

import { cn } from '@/lib/utils'
import { Typography } from '@/shared/ui'

import {
  bgColorClassNameByVerificationStatus,
  dotColorClassNameByVerificationStatus,
  textColorClassNameByVerificationStatus,
} from '../constants'

export const VerificationBadge = ({ status }: { status: string }) => {
  return (
    <div
      className={cn(
        'flex h-[22px] items-center gap-1.5 rounded-xl px-2',
        `${bgColorClassNameByVerificationStatus[status]}`,
      )}
    >
      <span
        className={cn(
          'size-1.5 rounded-full',
          dotColorClassNameByVerificationStatus[status],
        )}
      />

      <Typography
        className={cn(textColorClassNameByVerificationStatus[status])}
        bold
        variant="body-small"
      >
        <FormattedMessage
          id="verification.status.label"
          defaultMessage="{status, select, NOT_STARTED {Not started} IN_PROGRESS {In progress} COMPLETED {Completed} REJECTED {Rejected} other {}}"
          values={{ status: status }}
        />
      </Typography>
    </div>
  )
}
