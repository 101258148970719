import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { generatePath, Link, useParams } from 'react-router-dom'

import { ACCOUNT_TRANSACTIONS_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  getTransactionByType,
  getTransactions,
} from '@/features/Transactions/api'
import { Transaction } from '@/features/Transactions/types'
import { useTransactionUtils } from '@/hooks/useTransactionUtils'
import {
  ACCOUNTS_FILTER_NAME,
  EmptyTransactionsCard,
  TransactionDetailsSidebar,
  TransactionsTable,
} from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card } from '@/shared/ui'

import { useAccountsTransactionColumns } from './useAccountTransactionsColumns'

export const AccountTransactionsList = () => {
  const columns = useAccountsTransactionColumns()
  const {
    closeSidebar,
    openSidebar,
    isSidebarOpen,
    transactionIdFromQuery,
    transactionTypeFromQuery,
  } = useTransactionUtils()

  const { id: walletId } = useParams<{ id: string }>()

  const [allAccountTransactions, singleTransaction] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getTransactions, walletId],
        queryFn: () => getTransactions({ [ACCOUNTS_FILTER_NAME]: walletId }),
        select: (data: AxiosResponse<Transaction[]>) => data?.data,
        staleTime: 0,
      },
      {
        queryKey: [
          queryKeys.getTransaction,
          transactionIdFromQuery,
          transactionTypeFromQuery,
        ],
        queryFn: () =>
          getTransactionByType({
            type: transactionTypeFromQuery,
            id: transactionIdFromQuery,
          }),
        select: (data: AxiosResponse<Transaction>) => data?.data,
        enabled: !!transactionIdFromQuery,
      },
    ],
  })

  return (
    <>
      {allAccountTransactions.data?.length === 0 ? (
        <EmptyTransactionsCard />
      ) : (
        <>
          <TransactionsTable
            isLoading={allAccountTransactions.isPending}
            onRowClick={openSidebar}
            columns={columns}
            loaderOptions={{ rows: 5 }}
            data={allAccountTransactions.data?.slice(0, 4) ?? []}
          />

          <Card className="relative -top-5" size="large">
            <Button
              rightIcon={<ChevronRight />}
              size="md"
              variant="secondary"
              asChild
            >
              <Link
                to={generatePath(ACCOUNT_TRANSACTIONS_ROUTE, {
                  id: walletId ?? '',
                })}
              >
                <FormattedMessage id="action.seeAll" defaultMessage="See all" />
              </Link>
            </Button>
          </Card>
        </>
      )}

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={isSidebarOpen}
        onOpenChange={closeSidebar}
      />
    </>
  )
}
