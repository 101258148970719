import { useCallback, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { z } from 'zod'

import { googlePlacesFields } from '@/constants/google'
import { GooglePlacesParsedAddress } from '@/lib/address'
import {
  AddressAutocompleteField,
  CardAddressFields,
  GoBackButton,
} from '@/shared/components'
import {
  Button,
  Form,
  FormField,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { ISO2CountryCode } from '@/types/country'

const formFields = [
  'addressStreet',
  'addressStreetNumber',
  'addressApartment',
  'addressReferences',
  'addressDistrict',
  'addressCity',
  'addressState',
  'addressPostCode',
  'addressCountry',
] as const

const googleAddressSchema = z.object({
  googlePlacesAddress: z.string(),
})

const cardAddressSchema = z.object({
  addressStreet: z.string(),
  addressStreetNumber: z.string(),
  addressApartment: z.string().optional(),
  addressReferences: z.string().optional(),
  addressDistrict: z.string(),
  addressCity: z.string(),
  addressState: z.string(),
  addressPostCode: z.string(),
  addressCountry: z.string(),
  internationalPhonePrefix: z.string().min(1, {
    message: 'validation.phoneCode.required',
  }),
  localPhoneNumber: z
    .string()
    .min(1, {
      message: 'validation.phoneNumber.required',
    })
    .refine(isValidPhoneNumber, {
      message: 'validation.phoneNumber.invalid',
    }),
})

export type PhysicalCardAddressSchema = z.infer<typeof cardAddressSchema>
export type GoogleAddressSchema = z.infer<typeof googleAddressSchema>

const DEFAULT_VALUES: PhysicalCardAddressSchema = {
  addressStreet: '',
  addressStreetNumber: '',
  addressApartment: '',
  addressReferences: '',
  addressDistrict: '',
  addressCity: '',
  addressState: '',
  addressPostCode: '',
  addressCountry: 'Mexico',
  localPhoneNumber: '',
  internationalPhonePrefix: '+52',
}

const CARD_ADDRESS_FORM_ID = 'card-address-form-id'

type Props = {
  physicalCardAddress?: PhysicalCardAddressSchema
  onBack: () => void
  onContinue: (cardAddress: PhysicalCardAddressSchema) => void
}

export const CardAddressStep = ({
  onBack,
  onContinue,
  physicalCardAddress,
}: Props) => {
  const [inputView, setInputView] = useState<'auto' | 'manual'>('auto')
  const intl = useIntl()

  const form = useForm<PhysicalCardAddressSchema>({
    mode: 'onChange',
    resolver: zodResolver(cardAddressSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const googleForm = useForm<GoogleAddressSchema>({
    mode: 'onChange',
    resolver: zodResolver(googleAddressSchema),
    defaultValues: { googlePlacesAddress: '' },
  })

  useEffect(() => {
    if (inputView === 'manual') {
      return
    }

    if (physicalCardAddress) {
      const {
        addressStreet,
        addressStreetNumber,
        addressApartment,
        addressReferences,
        addressDistrict,
        addressCity,
        addressState,
        addressPostCode,
        addressCountry,
        localPhoneNumber,
        internationalPhonePrefix,
      } = physicalCardAddress

      const ownerFields = [
        addressStreet,
        addressStreetNumber,
        addressApartment,
        addressReferences,
        addressDistrict,
        addressCity,
        addressState,
        addressPostCode,
        addressCountry,
        localPhoneNumber,
        internationalPhonePrefix,
      ]

      formFields.forEach((field, index) => {
        if (!ownerFields[index]) {
          return
        }

        form.setValue(field, ownerFields[index] ?? '')
      })

      if (formFields.some((field) => !!field)) {
        setInputView('manual')
      }
    }
    // we don't want to re-run this effect when inputView changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [physicalCardAddress, form])

  const onSubmit: SubmitHandler<PhysicalCardAddressSchema> = useCallback(
    (data) => {
      onContinue(data)
    },
    [onContinue],
  )

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="card.create.addressStep.title"
            defaultMessage="Where should we send the card?"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="card.create.addressStep.subtitle"
            defaultMessage="Search and find the address where you would like to have the card delivered"
          />
        </Typography>

        <div className="p-6" />

        {inputView === 'auto' ? (
          <Form key={inputView} {...googleForm}>
            <form id={CARD_ADDRESS_FORM_ID} className="w-full">
              <FormField
                control={googleForm.control}
                name="googlePlacesAddress"
                render={({ field }) => (
                  <AddressAutocompleteField
                    restrictedCountries={[ISO2CountryCode.MX]}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={field.name}
                    disabled={field.disabled}
                    placeholder={intl.formatMessage({
                      id: 'label.searchAddress',
                      defaultMessage: 'Search address',
                    })}
                    onManualClick={() => setInputView('manual')}
                    onGoogleAddressSelected={(address) => {
                      formFields
                        .filter(
                          (field) =>
                            field !== 'addressApartment' &&
                            field !== 'addressReferences',
                        )
                        .forEach((field, index) => {
                          form.setValue(
                            field,
                            address[
                              googlePlacesFields[
                                index
                              ] as keyof GooglePlacesParsedAddress
                            ],
                          )
                        })

                      setInputView('manual')
                    }}
                  />
                )}
              />
            </form>
          </Form>
        ) : (
          <Form key={inputView} {...form}>
            <form id={CARD_ADDRESS_FORM_ID} className="w-full">
              <CardAddressFields form={form} />
            </form>
          </Form>
        )}

        <StickyContainer>
          <Button
            width="full"
            disabled={
              !form.formState.isValid ||
              Object.values(form.getValues()).every((value) => !value)
            }
            form={CARD_ADDRESS_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>

          {inputView === 'manual' && (
            <>
              <div className="p-1" />
              <Button
                type="button"
                onClick={() => {
                  setInputView('auto')
                  googleForm.setValue('googlePlacesAddress', '')
                }}
                width="full"
                className="text-center font-semibold text-primary"
                variant="ghost"
              >
                <FormattedMessage
                  defaultMessage="Search for my address"
                  id="onboarding.businessAddress.searchForAddress"
                />
              </Button>
            </>
          )}
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
