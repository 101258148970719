import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { z } from 'zod'

import { queryKeys } from '@/constants/queryKeys'
import { getAccount } from '@/features/Accounts/api'
import { getFullName } from '@/lib/typography'
import { GoBackButton, PhysicalCardFields } from '@/shared/components'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { PaymentLimitType } from '@/types/limits'

import { TeamMemberSchema } from './TeamMemberDetailsStep'

const CARD_INFO_FORM_ID = 'card-info-form-id'

const teamPhysicalCardDetailsSchema = z.object({
  nickname: z.string().min(1, { message: 'validation.nickname.required' }),
  requestId: z.string(),
  identityId: z.string(),
  walletId: z.string().min(1),
  pin: z
    .object({
      code: z
        .string()
        .min(1, { message: 'validation.pin.required' })
        .length(4, { message: 'validation.pin.required' }),
      confirm: z.string().min(1, { message: 'validation.pin.required' }),
    })
    .refine(
      (data) => {
        return data.code === data.confirm
      },
      {
        message: 'validation.pin.match',
        path: ['confirm'],
      },
    ),
  limitAmount: z.string().refine((v) => Big(v === '' ? 0 : v).gt(0), {
    message: 'validation.balance.minAmount',
  }),
  limitType: z.nativeEnum(PaymentLimitType),
})

export type TeamPhysicalCardDetailsSchema = z.infer<
  typeof teamPhysicalCardDetailsSchema
>

const DEFAULT_VALUES: TeamPhysicalCardDetailsSchema = {
  nickname: '',
  walletId: '',
  requestId: '',
  identityId: '',
  limitAmount: '',
  limitType: '' as PaymentLimitType,
  pin: { code: '', confirm: '' },
}

type Props = {
  onBack: () => void
  onContinue: (data: TeamPhysicalCardDetailsSchema) => void
  teamCardDetails?: TeamPhysicalCardDetailsSchema
  teamMemberDetails?: TeamMemberSchema
}

export const TeamPhysicalCardDetailsStep = ({
  onBack,
  onContinue,
  teamCardDetails,
  teamMemberDetails,
}: Props) => {
  const form = useForm<TeamPhysicalCardDetailsSchema>({
    mode: 'onChange',
    resolver: zodResolver(teamPhysicalCardDetailsSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const accountQuery = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) => data?.data,
  })

  useEffect(() => {
    if (teamCardDetails) {
      form.setValue('nickname', teamCardDetails.nickname)
      form.setValue('walletId', teamCardDetails.walletId)
      form.setValue('requestId', teamCardDetails.requestId)
      form.setValue('limitAmount', teamCardDetails.limitAmount)
      form.setValue('limitType', teamCardDetails.limitType)
      form.setValue('pin.code', teamCardDetails.pin.code)
      form.setValue('pin.confirm', teamCardDetails.pin.confirm)

      form.trigger()
      return
    }
  }, [form, teamCardDetails, teamMemberDetails])

  const onSubmit: SubmitHandler<TeamPhysicalCardDetailsSchema> = async (
    data,
  ) => {
    onContinue(data)
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="cards.create.infoStep.physical.title"
            defaultMessage="Create a physical card"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="cards.create.infoStep.physical.description"
            defaultMessage="Customize the card to fit your needs"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={CARD_INFO_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <PhysicalCardFields
              form={form}
              fullName={getFullName(teamMemberDetails)}
              wallets={accountQuery.data?.wallets}
            />
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={CARD_INFO_FORM_ID}
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
