import { BusFront, CreditCard, Receipt, Tv } from 'lucide-react'

import { CardCategory, CardState } from '@/features/Cards/types'
import {
  Bag,
  Cart,
  ChartTrendUp,
  LightbulbOn,
  MapMark,
  MedicalKit,
  Soda,
} from '@/shared/icons/outline'
import { Icon } from '@/types/global'

export const bgColorClassNameByCardState: Record<CardState, string> = {
  [CardState.ACTIVE]: 'bg-primary-light',
  [CardState.CREATED]: 'bg-neutral-gray-100',
  [CardState.BLOCKED]: 'bg-neutral-gray-100',
  [CardState.TERMINATED]: 'bg-primary-error/15',
}

export const textColorClassNameByCardState: Record<CardState, string> = {
  [CardState.ACTIVE]: 'text-primary-dark',
  [CardState.CREATED]: 'text-neutral-gray-800',
  [CardState.BLOCKED]: 'text-neutral-gray-800',
  [CardState.TERMINATED]: 'text-primary-error',
}

export const dotColorClassNameByCardState: Record<CardState, string> = {
  [CardState.ACTIVE]: 'bg-primary-dark',
  [CardState.CREATED]: 'bg-neutral-gray-800',
  [CardState.BLOCKED]: 'bg-neutral-gray-800',
  [CardState.TERMINATED]: 'bg-primary-error',
}

export const iconByCardCategory: Record<CardCategory, Icon> = {
  [CardCategory.GROCERIES]: Cart,
  [CardCategory.SHOPPING]: Bag,
  [CardCategory.RESTAURANTS]: Soda,
  [CardCategory.TRANSPORT]: BusFront,
  [CardCategory.TRAVEL]: MapMark,
  [CardCategory.ENTERTAINMENT]: Tv,
  [CardCategory.UTILITIES]: LightbulbOn,
  [CardCategory.HEALTH]: MedicalKit,
  [CardCategory.SERVICES]: Receipt,
  [CardCategory.FINANCE_AND_INVESTMENTS]: ChartTrendUp,
  [CardCategory.OTHER]: CreditCard,
}

export const sizeByCardCategory: Record<CardCategory, string> = {
  [CardCategory.GROCERIES]: 'size-4',
  [CardCategory.SHOPPING]: 'size-4',
  [CardCategory.RESTAURANTS]: 'size-4',
  [CardCategory.TRANSPORT]: 'size-4',
  [CardCategory.TRAVEL]: 'size-4',
  [CardCategory.ENTERTAINMENT]: 'size-3.5',
  [CardCategory.UTILITIES]: 'size-4',
  [CardCategory.HEALTH]: 'size-4',
  [CardCategory.SERVICES]: 'size-3.5',
  [CardCategory.FINANCE_AND_INVESTMENTS]: 'size-4.5',
  [CardCategory.OTHER]: 'size-4',
}
