import { forwardRef, Ref, type SVGProps } from 'react'

const SvgSpinner = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M5 12h3M12 5v3M19 12h-3M12 19v-3M7.05 16.95l2.121-2.121M7.05 7.05l2.121 2.121M16.95 7.05l-2.121 2.121M16.95 16.95l-2.121-2.121"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgSpinner)
export default ForwardRef
