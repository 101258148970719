import { useCallback, useEffect, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValidPhoneNumber } from 'react-phone-number-input/input'
import { generatePath, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { getIdentity } from '@/api'
import { ONBOARDING_STEP } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { removeEmptyFormFields } from '@/lib/utils'
import {
  CountryCodeField,
  GoBackButton,
  OptionalTag,
  Widget,
} from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  PhoneNumberInput,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { Identity } from '@/types/business'
import { CountryCode } from '@/types/country'

import { getOnboardingStepDetails, submitOnboardingStep } from '../../api'
import {
  LegalRepresentativeStep as LegalRepresentativeStepType,
  LegalRepresentativeType,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepDetails,
  OnboardingStepName,
} from '../../types'
import { CountryTaxResidenceCombobox } from '../CountryTaxResidenceCombobox'
import { OnboardingDocumentField } from '../OnboardingDocumentField'

const legalStepSchema = z.object({
  FIRST_NAME: z.string(),
  LAST_NAME: z.string(),
  SECOND_LAST_NAME: z.string().optional(),
  EMAIL: z.string().email({
    message: 'validation.email.invalid',
  }),
  PHONE_PREFIX: z.string(),
  PHONE_NUMBER: z.string().refine(isValidPhoneNumber, {
    message: 'validation.phoneNumber.invalid',
  }),
  TAX_ID: z.string(),
  TAX_RESIDENCE_COUNTRY: z.string(),

  POWER_OF_ATTORNEY: z.array(z.any()),
})

type LegalStepSchema = z.infer<typeof legalStepSchema>

const DEFAULT_VALUES: LegalStepSchema = {
  FIRST_NAME: '',
  LAST_NAME: '',
  SECOND_LAST_NAME: '',
  EMAIL: '',
  PHONE_PREFIX: '+52',
  PHONE_NUMBER: '',
  TAX_ID: '',
  TAX_RESIDENCE_COUNTRY: '',

  POWER_OF_ATTORNEY: [new File([], '')],
}

type Props = {
  onBack: () => void
  config?: OnboardingStepConfig
  steps: OnboardingStep[]
  representativeType: LegalRepresentativeType
}

const LEGAL_REPRESENTATIVE_FORM_ID = 'legal-representative-form-id'

export const LegalRepresentativeForm = ({
  onBack,
  config,
  steps,
  representativeType,
}: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()
  const navigate = useNavigate()

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: submitOnboardingStep,
  })

  const form = useForm<LegalStepSchema>({
    mode: 'onChange',
    resolver: zodResolver(legalStepSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const isUserRepresentative =
    representativeType === LegalRepresentativeType.USER

  const [stepDetailsQuery, userQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
        queryFn: () => getOnboardingStepDetails(config?.name ?? ''),
        select: (data: AxiosResponse<OnboardingStepDetails>) => data.data,
        enabled: !!config?.name,
      },
      {
        queryKey: [queryKeys.getIdentity],
        staleTime: 0,
        queryFn: getIdentity,
        select: (data: AxiosResponse<Identity>) => data?.data,
        enabled: representativeType === LegalRepresentativeType.USER,
      },
    ],
  })

  const onSubmit: SubmitHandler<LegalStepSchema> = useCallback(
    async (data) => {
      const documents = stepDetailsQuery.data?.stepDetails?.documents ?? []

      removeEmptyFormFields(data)

      const {
        FIRST_NAME,
        LAST_NAME,
        SECOND_LAST_NAME,
        EMAIL,
        PHONE_PREFIX,
        PHONE_NUMBER,
        TAX_ID,
        TAX_RESIDENCE_COUNTRY,
      } = data

      const phoneNumber = PHONE_NUMBER.replace(`${PHONE_PREFIX}`, '') ?? ''

      try {
        await mutateAsync({
          stepName: OnboardingStepName.LEGAL_REPRESENTATIVE,
          stepDetails: {
            legalRepresentative: {
              firstName: FIRST_NAME,
              lastName: LAST_NAME,
              secondLastName: SECOND_LAST_NAME,
              email: EMAIL,
              internationalPhonePrefix: PHONE_PREFIX,
              localPhoneNumber: phoneNumber,
              taxId: TAX_ID,
              taxResidenceCountry: TAX_RESIDENCE_COUNTRY as CountryCode,
            },
            documents,
          },
        })

        const currentStepIndex = steps.findIndex(
          (step) => step.name === config?.name,
        )

        const nextStep = steps[currentStepIndex + 1]

        await queryClient.refetchQueries({
          queryKey: [queryKeys.getOnboardingState],
        })

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
        })

        navigate(generatePath(ONBOARDING_STEP, { step: nextStep.name }))
      } catch (error) {
        if (error instanceof Error) {
          notifyError(error)
        }
      }
    },
    [
      config?.name,
      navigate,
      notifyError,
      stepDetailsQuery.data?.stepDetails?.documents,
      steps,
      mutateAsync,
    ],
  )

  const fields = form.watch()

  useEffect(() => {
    const existingData = [
      fields.EMAIL,
      fields.FIRST_NAME,
      fields.LAST_NAME,
      fields.PHONE_NUMBER,
      fields.SECOND_LAST_NAME,
      fields.TAX_ID,
      fields.TAX_RESIDENCE_COUNTRY,
    ]
    const hasExistingData = existingData.some(Boolean)

    const hasUserFieldsPrefilled = existingData.some((field) => {
      const { firstName, lastName, email, phoneNumber } = userQuery.data ?? {}

      const userQueryData = [
        firstName,
        lastName,
        email,
        phoneNumber?.internationalPhonePrefix,
        phoneNumber?.localPhoneNumber,
      ]

      return userQueryData.includes(field)
    })

    if ((hasExistingData && !isUserRepresentative) || hasUserFieldsPrefilled) {
      form.trigger()
      return
    }

    if (stepDetailsQuery.data) {
      const details = stepDetailsQuery.data
        .stepDetails as LegalRepresentativeStepType['stepDetails']

      let formValuesToFill: (string | undefined)[]

      const {
        firstName,
        lastName,
        secondLastName,
        email,
        internationalPhonePrefix,
        localPhoneNumber,
        taxId,
        taxResidenceCountry,
      } = details?.legalRepresentative ?? {}

      if (userQuery.data && isUserRepresentative) {
        const {
          firstName: userFirstName,
          lastName: userLastName,
          email: userEmail,
          phoneNumber: {
            internationalPhonePrefix: userInternationalPhonePrefix,
            localPhoneNumber: userLocalPhoneNumber,
          },
        } = userQuery.data

        formValuesToFill = [
          userFirstName,
          userLastName,
          '',
          userEmail,
          userInternationalPhonePrefix,
          userLocalPhoneNumber,
          taxId,
          taxResidenceCountry,
        ]
      } else {
        formValuesToFill = [
          firstName,
          lastName,
          secondLastName,
          email,
          internationalPhonePrefix,
          localPhoneNumber,
          taxId,
          taxResidenceCountry,
        ]
      }

      const formFields = [
        'FIRST_NAME',
        'LAST_NAME',
        'SECOND_LAST_NAME',
        'EMAIL',
        'PHONE_PREFIX',
        'PHONE_NUMBER',
        'TAX_ID',
        'TAX_RESIDENCE_COUNTRY',
      ] as const

      formFields.forEach((field, index) => {
        if (!formValuesToFill[index]) {
          return
        }

        if (field === 'PHONE_NUMBER') {
          const prefix = formValuesToFill[index - 1]
          const number = formValuesToFill[index]

          form.setValue(field, `${prefix}${number}`)
          return
        }

        form.setValue(field, formValuesToFill[index] ?? '')

        form.trigger(field)
      })
    }
  }, [
    fields.EMAIL,
    fields.FIRST_NAME,
    fields.LAST_NAME,
    fields.PHONE_NUMBER,
    fields.SECOND_LAST_NAME,
    fields.TAX_ID,
    fields.TAX_RESIDENCE_COUNTRY,
    form,
    isUserRepresentative,
    stepDetailsQuery.data,
    userQuery.data,
  ])

  const getFilesByField = useCallback(
    (fieldName: string) => {
      return (stepDetailsQuery.data?.stepDetails?.documents ?? []).filter(
        (doc) => doc.documentType === fieldName,
      )
    },
    [stepDetailsQuery.data?.stepDetails?.documents],
  )

  const hasAllRequiredFields = useMemo(() => {
    if (!config?.fields) {
      return false
    }

    const requiredFields = config.fields.filter((field) => field.required)
    const requiredDocuments = config.documents.filter((doc) => doc.required)

    return (
      requiredFields.every(
        (field) => fields[field.name as keyof typeof fields],
      ) && requiredDocuments.every((doc) => getFilesByField(doc.type).length)
    )
  }, [config?.documents, config?.fields, fields, getFilesByField])

  return (
    <>
      <GoBackButton className="hidden md:left-80 md:flex" onClick={onBack} />

      <SlideInScreen>
        <Typography variant="h3" text="center">
          <FormattedMessage
            defaultMessage="Legal representative"
            id="onboarding.legalRepresentative.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          {isUserRepresentative ? (
            <FormattedMessage
              defaultMessage="We have used your pre-filled data to save you time. A couple more questions and we are good to go"
              id="onboarding.legalRepresentative.user.description"
            />
          ) : (
            <FormattedMessage
              defaultMessage="We need to validate the legal representative's information. If that is you, indicate it to do it faster"
              id="onboarding.legalRepresentative.description"
            />
          )}
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={LEGAL_REPRESENTATIVE_FORM_ID}
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Representative details"
                  id="onboarding.companyFormation.representativeDetails"
                />
              }
              variant="form"
            >
              <FormField
                control={form.control}
                name="FIRST_NAME"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        disabled={isUserRepresentative}
                        autoComplete="given-name"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'First name',
                          id: 'label.firstName',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="First name"
                        id="label.firstName"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="LAST_NAME"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        disabled={isUserRepresentative}
                        autoComplete="family-name"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Last name',
                          id: 'label.lastName',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Last name"
                        id="label.lastName"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="SECOND_LAST_NAME"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        disabled={isUserRepresentative}
                        autoComplete="additional-name"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Second last name',
                          id: 'label.secondLastName',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Second last name"
                        id="label.secondLastName"
                      />
                    </AnimatedFormLabel>

                    {field.value === '' && <OptionalTag />}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="EMAIL"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        disabled={isUserRepresentative}
                        autoComplete="email"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Work email',
                          id: 'label.workEmail',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Work email"
                        id="label.workEmail"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <div className="flex items-stretch gap-3">
                <FormField
                  control={form.control}
                  name="PHONE_PREFIX"
                  render={({ field }) => {
                    return (
                      <CountryCodeField
                        disabled={isUserRepresentative}
                        value={field.value}
                        onSelect={(value) =>
                          form.setValue('PHONE_PREFIX', value)
                        }
                      />
                    )
                  }}
                />

                <FormField
                  control={form.control}
                  name="PHONE_NUMBER"
                  render={({ field }) => (
                    <FormItem className="relative flex-1">
                      <FormControl>
                        <PhoneNumberInput
                          disabled={isUserRepresentative}
                          phonePrefix={form.watch('PHONE_PREFIX')}
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Phone number',
                            id: 'label.phoneNumber',
                          })}
                          {...field}
                        />
                      </FormControl>
                      <AnimatedFormLabel>
                        <FormattedMessage
                          defaultMessage="Phone number"
                          id="label.phoneNumber"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="TAX_RESIDENCE_COUNTRY"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <CountryTaxResidenceCombobox
                        placeholder={intl.formatMessage({
                          id: 'label.countryOfTaxResidence',
                          defaultMessage: 'Country of tax residence',
                        })}
                        onSelect={(value) => {
                          field.onChange(value.valueAsCode)
                        }}
                        value={field.value}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="TAX_ID"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Tax identification number',
                          id: 'label.taxIdentificationNumber',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Tax identification number"
                        id="label.taxIdentificationNumber"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />
            </Widget>

            <div className="p-4" />

            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Supporting documentation"
                  id="onboarding.companyFormation.supportingDocuments"
                />
              }
              variant="form"
            >
              <FormField
                control={form.control}
                name="POWER_OF_ATTORNEY"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <OnboardingDocumentField
                        isLoadingFiles={stepDetailsQuery.isPending}
                        title={intl.formatMessage({
                          id: 'onboarding.legalRepresentative.powerOfAttorney',
                          defaultMessage: 'Power of attorney',
                        })}
                        description={intl.formatMessage({
                          id: 'onboarding.legalRepresentative.powerOfAttorney.description',
                          defaultMessage:
                            'Submit a document that entitles the legal representative. For example: Power of attorney, bylaws or board resolution. If these are contained in a document uploaded previously, you may upload it again',
                        })}
                        step={config?.name ?? ''}
                        files={getFilesByField(field.name)}
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        onDrop={(files) => {
                          form.setValue(field.name, files)
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </Widget>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            loading={isPending || isSuccess}
            disabled={
              !hasAllRequiredFields ||
              isPending ||
              isSuccess ||
              !form.formState.isValid
            }
            form={LEGAL_REPRESENTATIVE_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
