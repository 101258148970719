import { Fragment, useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import { getSingleRecipient } from '@/features/Recipients/api'
import { Recipient } from '@/features/Recipients/types'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { formatAmount, formatCurrency, formatRate } from '@/lib/money'
import { getFullName, intersperse } from '@/lib/typography'
import { AccountIcon, Widget } from '@/shared/components'
import { Details, MotionDiv, Typography } from '@/shared/ui'
import { CountryCode } from '@/types/country'
import { Role } from '@/types/roles'

import { ExecuteWithdrawalTask, WithdrawalTaskDetails } from '../types'

export const TaskRecipientPaymentDetails = ({
  recipient,
}: {
  recipient?: Recipient
}) => {
  if (!recipient) {
    return Array.from({ length: 3 }).map((_, index) => (
      <Details key={index}>
        <Details.Skeleton />
        <Details.Skeleton />
      </Details>
    ))
  }

  switch (recipient.country) {
    case CountryCode.MX:
      return (
        <Details>
          <Details.Label>
            <FormattedMessage defaultMessage="CLABE" id="label.clabe" />
          </Details.Label>

          <Details.Value masked>
            {recipient.localInformation.clabe}
          </Details.Value>
        </Details>
      )

    case CountryCode.US:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>
            <Details.Value>
              <FormattedMessage
                id="paymentMethod.label"
                defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                values={{ method: recipient.localInformation.paymentMethod }}
              />
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Routing number"
                id="label.routingNumber"
              />
            </Details.Label>

            <Details.Value>
              {recipient.localInformation.routingNumber}
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Account number"
                id="label.accountNumber"
              />
            </Details.Label>
            <Details.Value masked>
              {recipient.localInformation.accountNumber}
            </Details.Value>
          </Details>
        </>
      )

    default:
      return null
  }
}

type Props = {
  task: ExecuteWithdrawalTask
  taskDetails: WithdrawalTaskDetails
}

export const WithdrawalTaskReview = ({ task, taskDetails }: Props) => {
  const { role } = useBusinessRole()

  const [recipientQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getSingleRecipient, taskDetails?.beneficiaryId],
        queryFn: () =>
          getSingleRecipient({ id: taskDetails?.beneficiaryId ?? '' }),
        select: (data: AxiosResponse<Recipient>) => data.data,
        enabled: !!taskDetails.beneficiaryId,
      },
    ],
  })

  const isUSDCurrency =
    taskDetails?.localCurrency === Currency.USD ||
    taskDetails?.localCurrency === Currency.USDC

  const description = useMemo(() => {
    if (task) {
      switch (role) {
        case Role.ADMIN:
          return (
            <FormattedMessage
              id="tasks.review.executeWithdrawal.admin.description"
              defaultMessage="{fullName} has made a request to transfer. Review and decide to approve or decline"
              values={{
                fullName: getFullName({
                  firstName: task.createdByFirstName,
                  lastName: task.createdByLastName,
                  secondLastName: task.createdBySecondLastName,
                }),
              }}
            />
          )

        case Role.PAYMENT_OPS:
          return (
            <FormattedMessage
              id="tasks.review.paymentOps.description"
              defaultMessage="Approval of this request is pending. Contact your account Admin so they can review it!"
            />
          )

        default:
          return ''
      }
    }

    return ''
  }, [role, task])

  return (
    <MotionDiv>
      <Typography text="center" variant="h3">
        <FormattedMessage
          id="tasks.review.executeWithdrawal.title"
          defaultMessage="Send {amount} to {recipient}"
          values={{
            recipient: task.taskDetails.beneficiaryName,
            amount: formatAmount({
              amount: task.taskDetails.localAmount,
              currency: task.taskDetails.localCurrency,
            }),
          }}
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">{description}</Typography>

      <div className="p-6" />

      <div className="flex flex-col gap-4">
        <Widget
          title={
            <FormattedMessage
              id="tasks.review.recipientDetails"
              defaultMessage="Recipient details"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage id="label.name" defaultMessage="Name" />
            </Details.Label>
            <Details.Value>{task.taskDetails.beneficiaryName}</Details.Value>
          </Details>

          {recipientQuery.data?.email ? (
            <Details>
              <Details.Label>
                <FormattedMessage id="label.email" defaultMessage="Email" />
              </Details.Label>
              <Details.Value>{recipientQuery.data?.email}</Details.Value>
            </Details>
          ) : null}

          <TaskRecipientPaymentDetails recipient={recipientQuery.data} />
        </Widget>

        <Widget
          title={
            <FormattedMessage
              id="recipient.paymentDetails"
              defaultMessage="Payment details"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage id="label.from" defaultMessage="From" />
            </Details.Label>

            <div className="flex items-center gap-2">
              <AccountIcon variant="middle" id={taskDetails.walletId} />
              <Typography>{taskDetails.walletLabel}</Typography>
            </div>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage id="label.youPay" defaultMessage="You pay" />
            </Details.Label>
            <Details.Value>
              {formatAmount({
                amount: taskDetails.baseAmount,
                currency: taskDetails.baseCurrency,
              })}
            </Details.Value>
          </Details>
          {!isUSDCurrency ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Exchange rate"
                  id="label.exchangeRate"
                />
              </Details.Label>

              <Details.Value>
                {intersperse(
                  [
                    <Fragment key="baseCurrency">
                      1 {formatCurrency(taskDetails.baseCurrency)}{' '}
                    </Fragment>,
                    <Fragment key="localCurrency">
                      {formatRate(taskDetails.fxRate)}{' '}
                      {formatCurrency(taskDetails.localCurrency)}
                    </Fragment>,
                  ],
                  ' = ',
                )}
              </Details.Value>
            </Details>
          ) : null}
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.recipientGets"
                defaultMessage="Recipient gets"
              />
            </Details.Label>
            <Details.Value>
              {formatAmount({
                amount: taskDetails.localAmount,
                currency: taskDetails.localCurrency,
              })}
            </Details.Value>
          </Details>

          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.memoOrSpecialReference"
                defaultMessage="Memo or special reference"
              />
            </Details.Label>
            <Details.Value>
              {taskDetails.additionalDetails?.paymentMessage ?? '-'}
            </Details.Value>
          </Details>
        </Widget>
      </div>
    </MotionDiv>
  )
}
