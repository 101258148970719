import { useIntl } from 'react-intl'

import { Typography } from '@/shared/ui'

import { useDocumentsList } from '../../hooks/useDocumentsList'
import { BusinessAddressStep, OnboardingStepName } from '../../types'
import { StepDetails } from '../StepDetails'

type Props = {
  stepDetails?: BusinessAddressStep['stepDetails']
}

export const BusinessAddressReview = ({ stepDetails }: Props) => {
  const intl = useIntl()

  const documentKeys: Record<string, string> = {
    PROOF_OF_ADDRESS: intl.formatMessage({
      id: 'onboarding.step.businessAddress.proofOfAddress',
      defaultMessage: 'Proof of address',
    }),
  }

  const documentsDetails = useDocumentsList(
    stepDetails?.documents ?? [],
    documentKeys,
  )

  if (!stepDetails) {
    return null
  }

  const { documents, ...fields } = stepDetails

  const mappedKeys: Record<keyof typeof fields, string | undefined> = {
    addressStreet: intl.formatMessage({
      id: 'onboarding.step.businessAddress.addressStreet',
      defaultMessage: 'Street',
    }),
    addressStreetNumber: intl.formatMessage({
      id: 'onboarding.step.businessAddress.addressStreetNumber',
      defaultMessage: 'Street number',
    }),
    addressDistrict: intl.formatMessage({
      id: 'onboarding.step.businessAddress.addressDistrict',
      defaultMessage: 'Neighborhood or district',
    }),
    addressCity: intl.formatMessage({
      id: 'onboarding.step.businessAddress.addressCity',
      defaultMessage: 'City',
    }),
    addressState: intl.formatMessage({
      id: 'onboarding.step.businessAddress.addressState',
      defaultMessage: 'State or province',
    }),
    addressPostCode: intl.formatMessage({
      id: 'onboarding.step.businessAddress.addressPostCode',
      defaultMessage: 'Post code',
    }),
    addressCountry: intl.formatMessage({
      id: 'onboarding.step.businessAddress.addressCountry',
      defaultMessage: 'Country',
    }),
  }

  const mappedValues: Record<keyof typeof fields, React.ReactNode> = {
    addressStreet: <Typography>{fields.addressStreet}</Typography>,
    addressStreetNumber: <Typography>{fields.addressStreetNumber}</Typography>,
    addressDistrict: <Typography>{fields.addressDistrict}</Typography>,
    addressCity: <Typography>{fields.addressCity}</Typography>,
    addressState: <Typography>{fields.addressState}</Typography>,
    addressPostCode: <Typography>{fields.addressPostCode}</Typography>,
    addressCountry: <Typography>{fields.addressCountry}</Typography>,
  }

  const details = Object.entries(fields).map(([key]) => {
    return {
      key: mappedKeys[key as keyof typeof mappedKeys],
      value: mappedValues[key as keyof typeof mappedValues],
    }
  })

  return (
    <StepDetails
      step={OnboardingStepName.BUSINESS_ADDRESS}
      title={intl.formatMessage({
        id: 'onboarding.step.businessAddress',
        defaultMessage: 'Business address',
      })}
      details={[...details, ...documentsDetails]}
    />
  )
}
