import { FormattedMessage } from 'react-intl'

import { getCardTitle } from '@/lib/card'
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Typography,
} from '@/shared/ui'

import { Card } from '../types'

type Props = {
  card: Card
  isOpen: boolean
  onDelete: () => void
  onOpenChange: (isOpen: boolean) => void
}

export const DeleteCardDialog = ({
  card,
  isOpen,
  onDelete,
  onOpenChange,
}: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage
              defaultMessage="Delete card?"
              id="card.delete.dialog.title"
            />
          </DialogTitle>

          <div className="flex flex-col items-center">
            <Typography text="center">
              <FormattedMessage
                defaultMessage="Are you sure you want to delete {cardInfo}?"
                id="card.delete.dialog.deleteCard"
                values={{
                  cardInfo: getCardTitle(card),
                }}
              />
            </Typography>

            <Typography>
              <FormattedMessage
                defaultMessage="The card will be terminated permanently"
                id="card.delete.dialog.cardTerminated"
              />
            </Typography>
          </div>
        </DialogHeader>

        <div className="p-4" />

        <div className="grid grid-cols-2 gap-3">
          <Button
            width="full"
            onClick={() => onOpenChange(false)}
            variant="tertiary"
          >
            <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button width="full" onClick={onDelete}>
            <FormattedMessage id="action.delete" defaultMessage="Delete" />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
