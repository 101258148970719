import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Navigate, useNavigate } from 'react-router-dom'

import { getBusinessIdentity } from '@/api'
import { ONBOARDING_ROUTE, SIGNIN_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { isAPIError } from '@/lib/error'
import { useAuth } from '@/providers/AuthProvider'
import { Loader } from '@/shared/components'
import { BusinessState } from '@/types/business'

type Props = {
  children: React.ReactNode
}

export const BusinessStateChecker = ({ children }: Props) => {
  const navigate = useNavigate()
  const { setAuthToken } = useAuth()
  const { isCardUser, isPending } = useBusinessRole()

  const businessQuery = useQuery({
    queryKey: [queryKeys.getBusinessIdentity],
    queryFn: getBusinessIdentity,
    select: (data) => data.data,
    retry: 1,
    staleTime: 0,
  })

  useEffect(() => {
    if (
      businessQuery.error &&
      isAPIError(businessQuery.error) &&
      businessQuery.error.response?.status === 401
    ) {
      setAuthToken(null)

      navigate(SIGNIN_ROUTE, { replace: true })
    }
  }, [businessQuery.error, isCardUser, navigate, setAuthToken])

  if (businessQuery.isPending || businessQuery.isError || isPending) {
    return <Loader />
  }

  switch (businessQuery.data?.state) {
    case BusinessState.ACTIVE:
      return <>{children}</>

    case BusinessState.ONBOARDING:
      return <Navigate to={ONBOARDING_ROUTE} />

    default:
      console.error('Unknown business state')
      return null
  }
}
