import { TeamState } from '../types'

export const bgColorClassNameByTeamState: Record<TeamState, string> = {
  [TeamState.ACTIVE]: 'bg-primary-light',
  [TeamState.INVITED]: 'bg-neutral-gray-100',
  [TeamState.BLOCKED]: 'bg-neutral-gray-100',
  [TeamState.TERMINATED]: 'bg-primary-error/15',
}

export const textColorClassNameByTeamState: Record<TeamState, string> = {
  [TeamState.ACTIVE]: 'text-primary-dark',
  [TeamState.INVITED]: 'text-neutral-gray-800',
  [TeamState.BLOCKED]: 'text-neutral-gray-800',
  [TeamState.TERMINATED]: 'text-primary-error',
}

export const dotColorClassNameByTeamState: Record<TeamState, string> = {
  [TeamState.ACTIVE]: 'bg-primary-dark',
  [TeamState.INVITED]: 'bg-neutral-gray-800',
  [TeamState.BLOCKED]: 'bg-neutral-gray-800',
  [TeamState.TERMINATED]: 'bg-primary-error',
}
