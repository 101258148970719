import * as React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { cn } from '@/lib/utils'

import { Search, X } from '../icons/outline'

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
} from '.'

const searchSchema = z.object({
  search: z.string().or(z.number()).optional(),
})

type SearchSchema = z.infer<typeof searchSchema>

type SearchInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'value' | 'onChange'
> & {
  value?: string
  onChange?: (value: string) => void
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ className, onChange, ...props }, ref) => {
    const intl = useIntl()
    const form = useForm<SearchSchema>({
      mode: 'onChange',
      resolver: zodResolver(searchSchema),
      defaultValues: {
        search: props.value ?? '',
      },
    })

    return (
      <Form {...form}>
        <form className="w-full">
          <FormField
            control={form.control}
            name="search"
            render={({ field }) => (
              <FormItem className="relative flex h-10 w-full items-center rounded-lg bg-neutral-gray-200 pl-2">
                <Search className="size- text-neutral-gray-900" />
                <FormControl>
                  <Input
                    variant="search"
                    inputSize="default"
                    className={cn('search-input-autofill p-0 pl-2', className)}
                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      onChange?.(e.target.value)
                    }}
                    placeholder={intl.formatMessage({
                      id: 'label.search',
                      defaultMessage: 'Search',
                    })}
                    ref={ref}
                    {...props}
                  />
                </FormControl>
                <FormLabel className="sr-only">
                  <FormattedMessage id="label.search" defaultMessage="Search" />
                </FormLabel>

                {props.value ? (
                  <Button
                    type="button"
                    variant="ghost"
                    size="inline"
                    className="absolute right-3 top-2.5 rounded-full bg-neutral-gray-300 p-0.5 hover:bg-neutral-gray-400"
                    aria-label={intl.formatMessage({
                      id: 'action.clearSearch',
                      defaultMessage: 'Clear search',
                    })}
                    onClick={() => {
                      field.onChange('')
                      onChange?.('')
                    }}
                  >
                    <X className="size-4" />
                  </Button>
                ) : null}
              </FormItem>
            )}
          />
        </form>
      </Form>
    )
  },
)

SearchInput.displayName = 'MoneyInput'
