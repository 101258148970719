import { CountryCode, CountryTaxId } from '@/types/country'

import {
  CompanyOwner,
  IdentityValidation,
  OnboardingConfig,
  OnboardingMemberRole,
  OnboardingMemberState,
  OnboardingState,
  OnboardingStateEnum,
  OnboardingStepDetails,
  OnboardingStepName,
} from '../../types'

export const MOCKED_OWNER: CompanyOwner = {
  id: '19e6b1d5-f619-4edf-8583-38104bc7c765',
  firstName: 'Mr',
  lastName: 'Sandman',
  taxId: 'HIG240206AAA',
  taxResidenceCountry: CountryCode.MX,
  email: 'john.doe@gmail.com',
  internationalPhonePrefix: '+52',
  localPhoneNumber: '5512341234',
  addressStreet: 'Amsterdam',
  addressStreetNumber: '316',
  addressDistrict: 'CMDX',
  addressCity: 'Mexico',
  addressState: 'Mexico',
  addressPostCode: '33333',
  addressCountry: 'Mexico',
}

export const MOCKED_RANDOM_OWNER: CompanyOwner = {
  id: '19e6b1d5-f619-4edf-8583-38104bc7c722',
  firstName: 'Random',
  lastName: 'Owner',
  taxId: 'HIG240206AAA',
  taxResidenceCountry: CountryCode.MX,
  email: 'random.owner@gmail.com',
  internationalPhonePrefix: '+52',
  localPhoneNumber: '5512341234',
  addressStreet: 'Amsterdam',
  addressStreetNumber: '316',
  addressDistrict: 'CMDX',
  addressCity: 'Mexico',
  addressState: 'Mexico',
  addressPostCode: '33333',
  addressCountry: 'Mexico',
}

export const STEPS: OnboardingState['steps'] = [
  {
    id: '0b72c153-9286-44f7-8ab3-7841d3dc0d51',
    name: OnboardingStepName.COMPANY_FORMATION,
    state: OnboardingStateEnum.NOT_STARTED,
  },
  {
    id: '0a72c153-9286-44f7-8ab3-7841d3dc0d51',
    name: OnboardingStepName.BUSINESS_ADDRESS,
    state: OnboardingStateEnum.NOT_STARTED,
  },
  {
    id: '0a12c153-9286-44f7-8ab3-7841d3dc0d51',
    name: OnboardingStepName.LEGAL_REPRESENTATIVE,
    state: OnboardingStateEnum.NOT_STARTED,
  },
  {
    id: '0a12c153-9286-44f7-8ab3-7841d3dc0d52',
    name: OnboardingStepName.COMPANY_ACTIVITY,
    state: OnboardingStateEnum.NOT_STARTED,
  },
  {
    id: '0a12c153-9286-44f7-8ab3-7841d3dc0122',
    name: OnboardingStepName.COMPANY_OWNERSHIP,
    state: OnboardingStateEnum.NOT_STARTED,
  },
  {
    id: '0a12c153-9286-44f7-8ab3-7841d3dc0199',
    name: OnboardingStepName.IDENTITY_VALIDATION,
    state: OnboardingStateEnum.NOT_STARTED,
  },
  {
    id: '0a12c153-9286-44f7-8ab3-7841d3dc0222',
    name: OnboardingStepName.REVIEW_AND_SUBMIT,
    state: OnboardingStateEnum.NOT_STARTED,
  },
]

export const MOCK_ONBOARDING_STATE: OnboardingState = {
  id: '5ae046fc-a00b-4a43-9485-3d71ab84b73b',
  state: OnboardingStateEnum.NOT_STARTED,
  steps: STEPS,
}

export const MOCK_ONBOARDING_IN_PROGRESS_STATE: OnboardingState = {
  id: '5ae046fc-a00b-4a43-9485-3d71ab84b73b',
  state: OnboardingStateEnum.IN_PROGRESS,
  steps: STEPS,
}
export const MOCK_ONBOARDING_VERIFICATION_STATE: OnboardingState = {
  id: '5ae046fc-a00b-4a43-9485-3d71ab84b73b',
  state: OnboardingStateEnum.IN_PROGRESS,
  steps: [
    {
      id: '0b72c153-9286-44f7-8ab3-7841d3dc0d51',
      name: OnboardingStepName.COMPANY_FORMATION,
      state: OnboardingStateEnum.VERIFICATION,
    },
    {
      id: '0a72c153-9286-44f7-8ab3-7841d3dc0d51',
      name: OnboardingStepName.BUSINESS_ADDRESS,
      state: OnboardingStateEnum.VERIFICATION,
    },
  ],
}

export const MOCK_ONBOARDING_CONFIG: OnboardingConfig = {
  country: CountryCode.MX,
  steps: [
    {
      name: OnboardingStepName.COMPANY_FORMATION,
      documents: [
        { type: 'FORMATION_DOCUMENTS', required: true },
        { type: 'TAX_CERTIFICATE_OF_GOOD_STANDING', required: true },
      ],
      fields: [
        { name: 'COUNTRY_OF_INCORPORATION', required: true },
        { name: 'TAX_ID', required: true },
        { name: 'TAX_ID_TYPE', required: true },
        { name: 'DATE_OF_INCORPORATION', required: true },
      ],
    },
    {
      name: OnboardingStepName.BUSINESS_ADDRESS,
      fields: [
        { name: 'ADDRESS_STREET', required: true },
        { name: 'ADDRESS_STREET_NUMBER', required: true },
        { name: 'ADDRESS_DISTRICT', required: true },
        { name: 'ADDRESS_CITY', required: true },
        { name: 'ADDRESS_STATE', required: true },
        { name: 'ADDRESS_POST_CODE', required: true },
        { name: 'ADDRESS_COUNTRY', required: true },
      ],
      documents: [{ type: 'PROOF_OF_ADDRESS', required: true }],
    },
    {
      name: OnboardingStepName.LEGAL_REPRESENTATIVE,
      fields: [
        { name: 'FIRST_NAME', required: true },
        { name: 'LAST_NAME', required: true },
        { name: 'SECOND_LAST_NAME', required: false },
        { name: 'EMAIL', required: true },
        { name: 'PHONE_PREFIX', required: true },
        { name: 'PHONE_NUMBER', required: true },
        { name: 'TAX_ID', required: true },
        { name: 'TAX_RESIDENCE_COUNTRY', required: true },
      ],
      documents: [
        { type: 'OFFICIAL_GOVERNMENT_ID', required: true },
        { type: 'POWER_OF_ATTORNEY', required: true },
      ],
    },
    {
      name: OnboardingStepName.COMPANY_ACTIVITY,
      fields: [
        {
          name: 'ACTIVITY_TYPE',
          required: true,
          allowedValues: [
            'PRIMARY_SECTOR_AGRICULTURE',
            'PRIMARY_SECTOR_LIVESTOCK_FARMING',
            'PRIMARY_SECTOR_EXPLOITATION',
            'PRIMARY_SECTOR_FORESTRY',
            'PRIMARY_SECTOR_FISHING',
            'MINING_MINING_OF_OTHER_RESOURCES',
            'MINING_PETROLEUM_AND_GAS',
            'MINING_GOLD',
            'MINING_SILVER',
            'MINING_PRECIOUS_STONES',
            'ELECTRICITY_WATER_GAS',
          ],
        },
        { name: 'COMPANY_WEBSITE', required: true },
        {
          name: 'EXPECTED_MONTHLY_VOLUME',
          required: true,
          allowedValues: [
            'LESS_THAN_10K',
            'BETWEEN_10K_50K',
            'BETWEEN_50K_100K',
            'BETWEEN_100K_250K',
            'BETWEEN_250K_500K',
            'MORE_THAN_500K',
          ],
        },
        {
          name: 'OPERATES_IN_PROHIBITED_ACTIVITIES',
          required: true,
          allowedValues: [
            'ADULT_ENTERTAINMENT',
            'BETTING_HOUSES_CASINOS_INTERNET_GAMBLING',
            'CHARITIES_NON_PROFITS',
            'GAMING',
            'GOVERNMENTAL_ENTITIES',
            'JEWELRY_AND_PRECIOUS_STONES',
            'MARIJUANA',
            'NUCLEAR_POWER_PLANTS',
            'PRIVATE_ATMS',
            'WEAPONS',
          ],
        },
        {
          name: 'OPERATES_IN_PROHIBITED_COUNTRIES',
          required: true,
          allowedValues: ['BGR', 'BFA', 'CMR', 'COD'],
        },
        {
          name: 'OPERATES_IN_HIGH_RISK_ACTIVITIES',
          required: true,
          allowedValues: [
            'BANK_AND_NOT_BANK_FINANCIAL_INSTITUTIONS',
            'CRYPTOCURRENCIES_STABLECOINS_AND_DIGITAL_ASSETS',
            'DECENTRALIZED_AUTONOMOUS_ORGANISATIONS',
            'MONEY_SERVICE_BUSINESSES',
            'PHARMACEUTICALS',
            'PROFESSIONAL_SERVICE_PROVIDERS',
            'THIRD_PARTY_PAYMENT_PROCESSORS_AND_TRANSMITTERS',
          ],
        },
        {
          name: 'SOURCES_OF_FUNDS',
          required: true,
          allowedValues: [
            'BUSINESS_LOANS',
            'BUSINESS_REVENUE',
            'EQUITY_FUNDING',
            'GRANTS',
            'INVESTMENT_PROCEEDS',
            'LEGAL_SETTLEMENT',
            'RETIREMENT_PENSION',
            'SALE_OF_ASSETS',
            'TAX_REFUND',
            'THIRD_PARTY_FUNDS',
          ],
        },
        {
          name: 'BUSINESS_DESCRIPTION',
          required: true,
          allowedValues: [],
        },
      ],
      documents: [{ type: 'SOURCE_OF_FUNDS', required: true }],
    },
    {
      name: OnboardingStepName.COMPANY_OWNERSHIP,
      fields: [
        { name: 'FIRST_NAME', required: true, allowedValues: [] },
        { name: 'LAST_NAME', required: true, allowedValues: [] },
        { name: 'SECOND_LAST_NAME', required: false, allowedValues: [] },
        { name: 'EMAIL', required: true, allowedValues: [] },
        { name: 'PHONE_PREFIX', required: true, allowedValues: [] },
        { name: 'PHONE_NUMBER', required: true, allowedValues: [] },
        { name: 'TAX_ID', required: true, allowedValues: [] },
        { name: 'TAX_RESIDENCE_COUNTRY', required: true, allowedValues: [] },
        { name: 'ADDRESS_STREET', required: true, allowedValues: [] },
        { name: 'ADDRESS_STREET_NUMBER', required: true, allowedValues: [] },
        { name: 'ADDRESS_DISTRICT', required: true, allowedValues: [] },
        { name: 'ADDRESS_CITY', required: true, allowedValues: [] },
        { name: 'ADDRESS_STATE', required: true, allowedValues: [] },
        { name: 'ADDRESS_POST_CODE', required: true, allowedValues: [] },
        { name: 'ADDRESS_COUNTRY', required: true, allowedValues: [] },
      ],
      documents: [{ type: 'SHARE_REGISTRY', required: true }],
    },
    {
      name: OnboardingStepName.IDENTITY_VALIDATION,
      fields: [],
      documents: [],
    },
    { name: OnboardingStepName.REVIEW_AND_SUBMIT, fields: [], documents: [] },
  ],
}

export const MOCK_ONBOARDING_STATE_DATA: OnboardingStepDetails = {
  stepName: OnboardingStepName.COMPANY_FORMATION,
  stepDetails: {
    countryOfIncorporation: CountryCode.MX,
    taxId: 'BBC451231AAA',
    taxIdType: 'RFC',
    dateOfIncorporation: '2001-05-05',
    documents: [
      {
        documentType: 'FORMATION_DOCUMENTS',
        fileName: '33ABEFF7E619B060CD1E55F82B19EB94-FORMATION_DOCUMENTS.pdf',
      },
      {
        documentType: 'TAX_CERTIFICATE_OF_GOOD_STANDING',
        fileName:
          '765FF75550902B6D67AF2506F9DBEFC2-TAX_CERTIFICATE_OF_GOOD_STANDING.pdf',
      },
    ],
  },
}

export const MOCK_ONBOARDING_BUSINESS_ADDRESS: OnboardingStepDetails = {
  stepName: OnboardingStepName.BUSINESS_ADDRESS,
  stepDetails: {
    addressStreet: 'Hello Street',
    addressStreetNumber: '5A',
    addressDistrict: 'Big District',
    addressCity: 'Great City',
    addressState: 'Some State',
    addressPostCode: '11111',
    addressCountry: 'Maybe Mexico',
    documents: [
      {
        documentType: 'PROOF_OF_ADDRESS',
        fileName: '23CA9EB9BFED604F53B42A86CA04FABF-photo1699645817.jpeg',
      },
    ],
  },
}

export const MOCK_ONBOARDING_LEGAL_REPRESENTATIVE: OnboardingStepDetails = {
  stepName: OnboardingStepName.LEGAL_REPRESENTATIVE,
  stepDetails: {
    legalRepresentative: {
      firstName: '',
      lastName: '',
      secondLastName: '',
      email: '',
      internationalPhonePrefix: '+52',
      localPhoneNumber: '',
      taxId: '',
      taxResidenceCountry: undefined,
    },

    documents: [
      {
        documentType: 'OFFICIAL_GOVERNMENT_ID',
        fileName: '23CA9EB9BFED604F53B42A86CA04FABF-OFFICIAL_GOVERNMENT_ID.pdf',
      },
      {
        documentType: 'POWER_OF_ATTORNEY',
        fileName: '23CA9EB9BFED604F53B42A86CA04FABF-POWER_OF_ATTORNEY.pdf',
      },
    ],
  },
}

export const MOCK_ONBOARDING_COMPANY_ACTIVITY: OnboardingStepDetails = {
  stepName: OnboardingStepName.COMPANY_ACTIVITY,
  stepDetails: {
    activityType: '',
    website: '',
    expectedVolume: '',
    sourcesOfFunds: ['BUSINESS_LOANS'],
    description: '',
    operatesInHighRiskActivities: false,
    operatesInProhibitedActivities: false,
    operatesInProhibitedCountries: false,

    documents: [
      {
        documentType: 'SOURCE_OF_FUNDS',
        fileName: '23CA9EB9BFED604F53B42A86CA04FABF-SOURCE_OF_FUNDS.pdf',
      },
    ],
  },
}

export const MOCK_ONBOARDING_COMPANY_OWNERSHIP: OnboardingStepDetails = {
  stepName: OnboardingStepName.COMPANY_OWNERSHIP,
  stepDetails: {
    beneficialOwners: [MOCKED_OWNER, MOCKED_RANDOM_OWNER],

    documents: [
      {
        documentType: 'SHARE_REGISTRY',
        fileName: '23CA9EB9BFED604F53B42A86CA04FABF-SHARE_REGISTRY.pdf',
      },
    ],
  },
}

export const MOCK_ONBOARDING_IDENTITY_VALIDATION: OnboardingStepDetails = {
  stepName: OnboardingStepName.IDENTITY_VALIDATION,

  stepDetails: {
    members: [
      {
        email: 'john.one@gmail.com',
        firstName: 'John',
        lastName: 'One',
        secondLastName: undefined,
        roles: [OnboardingMemberRole.LEGAL_REPRESENTATIVE],
        onboardingMemberValidationId: '0a12c153-9286-44f7-8ab3-7841d3dc0199',
        state: OnboardingMemberState.COMPLETED,
      },
      {
        email: 'john.two@gmail.com',
        firstName: 'John',
        lastName: 'Two',
        secondLastName: undefined,
        roles: [OnboardingMemberRole.BENEFICIAL_OWNER],
        onboardingMemberValidationId: '0a12c153-9286-44f7-8ab3-7841d3dc0199',
        state: OnboardingMemberState.COMPLETED,
      },
    ],
    documents: [],
  },
}

export const MOCK_ONBOARDING_REVIEW_AND_SUBMIT: OnboardingStepDetails = {
  stepName: OnboardingStepName.REVIEW_AND_SUBMIT,

  stepDetails: {
    documents: [],
    reviewElements: [
      {
        stepName: OnboardingStepName.COMPANY_FORMATION,
        stepDetails: {
          countryOfIncorporation: CountryCode.MX,
          taxId: 'ROC240101AAA',
          taxIdType: CountryTaxId.MX,
          dateOfIncorporation: '2024-01-01',
          documents: [
            {
              documentType: 'FORMATION_DOCUMENTS',
              fileName: '23CA9EB9BFED604F53B42A86CA04FABF-photo1699645817.jpeg',
            },
            {
              documentType: 'FORMATION_DOCUMENTS',
              fileName:
                '765FF75550902B6D67AF2506F9DBEFC2-photo1699645817 (2).jpeg',
            },
            {
              documentType: 'TAX_CERTIFICATE_OF_GOOD_STANDING',
              fileName:
                '33ABEFF7E619B060CD1E55F82B19EB94-photo1699645817 (1).jpeg',
            },
          ],
        },
      },
      {
        stepName: OnboardingStepName.BUSINESS_ADDRESS,
        stepDetails: {
          addressStreet: 'Ámsterdam',
          addressStreetNumber: '316',
          addressDistrict: 'Hipódromo',
          addressCity: 'Ciudad de México',
          addressState: 'CDMX',
          addressPostCode: '06100',
          addressCountry: 'Mexico',
          documents: [
            {
              documentType: 'PROOF_OF_ADDRESS',
              fileName:
                '33ABEFF7E619B060CD1E55F82B19EB94-photo1699645817 (1).jpeg',
            },
          ],
        },
      },
      {
        stepName: OnboardingStepName.LEGAL_REPRESENTATIVE,
        stepDetails: {
          legalRepresentative: {
            firstName: 'Maks',
            lastName: 'Akymenko',
            secondLastName: '',
            taxId: 'BBC451231AAA',
            email: 'maks.test@gmail.com',
            internationalPhonePrefix: '+52',
            localPhoneNumber: '5512341234',
          },
          documents: [
            {
              documentType: 'OFFICIAL_GOVERNMENT_ID',
              fileName:
                '33ABEFF7E619B060CD1E55F82B19EB94-photo1699645817 (1).jpeg',
            },
            {
              documentType: 'POWER_OF_ATTORNEY',
              fileName:
                '765FF75550902B6D67AF2506F9DBEFC2-photo1699645817 (2).jpeg',
            },
          ],
        },
      },
      {
        stepName: OnboardingStepName.COMPANY_ACTIVITY,
        stepDetails: {
          activityType: 'PRIMARY_SECTOR_LIVESTOCK_FARMING',
          website: 'https://www.garpa.money/',
          expectedVolume: 'BETWEEN_10K_50K',
          sourcesOfFunds: ['BUSINESS_LOANS'],
          description:
            'long description that has length more than 50 characters',
          operatesInHighRiskActivities: false,
          operatesInProhibitedActivities: false,
          operatesInProhibitedCountries: false,
          documents: [
            {
              documentType: 'SOURCE_OF_FUNDS',
              fileName:
                '765FF75550902B6D67AF2506F9DBEFC2-photo1699645817 (2).jpeg',
            },
          ],
        },
      },
      {
        stepName: OnboardingStepName.COMPANY_OWNERSHIP,
        stepDetails: {
          beneficialOwners: [
            {
              id: '19e6b1d5-f619-4edf-8583-38104bc7c765',
              firstName: 'Maks',
              lastName: 'Akymenko',
              secondLastName: undefined,
              taxId: 'HIG240206AAA',
              email: 'john.doe@test.com',
              internationalPhonePrefix: '+52',
              localPhoneNumber: '5512341234',
              addressStreet: 'Amsterdam',
              addressStreetNumber: '316',
              addressDistrict: 'CMDX',
              addressCity: 'Mexico',
              addressState: 'Mexico',
              addressPostCode: '33333',
              addressCountry: 'Mexico',
            },
            {
              id: '6d9e231a-c294-4711-913e-57c3e581a49a',
              firstName: 'Maks',
              lastName: 'Akymenko',
              secondLastName: undefined,
              taxId: 'BBC451231AAA',
              email: 'maks.test@gmail.com',
              internationalPhonePrefix: '+52',
              localPhoneNumber: '5512341234',
              addressStreet: 'Ámsterdam',
              addressStreetNumber: '315',
              addressDistrict: 'Hipódromo',
              addressCity: 'Ciudad de México',
              addressState: 'CDMX',
              addressPostCode: '06100',
              addressCountry: 'Mexico',
            },
          ],
          documents: [
            {
              documentType: 'SHARE_REGISTRY',
              fileName:
                '33ABEFF7E619B060CD1E55F82B19EB94-photo1699645817 (1).jpeg',
            },
          ],
        },
      },
      {
        stepName: OnboardingStepName.IDENTITY_VALIDATION,
        stepDetails: {
          members: [
            {
              email: 'john.one@gmail.com',
              firstName: 'John',
              lastName: 'One',
              secondLastName: undefined,
              roles: [OnboardingMemberRole.LEGAL_REPRESENTATIVE],
              onboardingMemberValidationId:
                '0a12c153-9286-44f7-8ab3-7841d3dc0199',
              state: OnboardingMemberState.COMPLETED,
            },
            {
              email: 'john.two@gmail.com',
              firstName: 'John',
              lastName: 'Two',
              secondLastName: undefined,
              roles: [OnboardingMemberRole.BENEFICIAL_OWNER],
              onboardingMemberValidationId:
                '0a12c153-9286-44f7-8ab3-7841d3dc0129',
              state: OnboardingMemberState.COMPLETED,
            },
          ],
          documents: [],
        },
      },
    ],
  },
}

export const MOCK_ONBOARDING_VALIDATION_STATE: IdentityValidation = {
  id: '0a12c153-9286-44f7-8ab3-7841d3dc0129',
  state: OnboardingMemberState.COMPLETED,
  validationLink: 'https://www.google.com',
}
