import { CountryCode } from '@/types/country'

export function getCodeByCountry(country?: CountryCode): string {
  switch (country) {
    case CountryCode.MX:
      return '+52'

    case CountryCode.US:
      return '+1'

    default:
      return ''
  }
}
