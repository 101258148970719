import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import { countryCodeToCountryData } from '@/constants/countries'
import { SEND_ROUTE } from '@/constants/paths'
import { getRecipientAddress } from '@/lib/address'
import { getFlagUrl } from '@/lib/images'
import { Widget, WithPermissions } from '@/shared/components'
import {
  Button,
  Details,
  Sheet,
  SheetContent,
  SheetFooter,
  Skeleton,
  Typography,
} from '@/shared/ui'
import { Permission } from '@/types/permissions'

import { Recipient } from '../types'
import { getRecipientTitle } from '../utils'

import { PaymentDetails } from './PaymentDetails'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  recipient?: Recipient
}

export const RecipientDetailsSidebar = ({
  isOpen,
  onOpenChange,
  recipient,
}: Props) => {
  const location = useLocation()
  const intl = useIntl()

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        {recipient ? (
          <div className="flex w-full items-center justify-between">
            <Typography variant="h3">{getRecipientTitle(recipient)}</Typography>
          </div>
        ) : (
          <Skeleton className="h-[33px] w-36" />
        )}

        <div className="p-4" />

        <Widget
          title={
            <FormattedMessage
              id="recipient.contactDetails"
              defaultMessage="Contact details"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage
                id="recipient.recipientCountry"
                defaultMessage="Recipient country"
              />
            </Details.Label>
            {recipient?.country ? (
              <div className="flex items-center gap-3">
                <img
                  src={getFlagUrl(
                    countryCodeToCountryData[recipient.country].flagCode,
                  )}
                  className="h-3 w-3"
                  alt={intl.formatMessage(
                    {
                      id: `country.flag.alt`,
                      defaultMessage:
                        '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
                    },
                    { country: recipient.country },
                  )}
                />

                <Typography>
                  <FormattedMessage
                    id="country.name"
                    defaultMessage="{country, select, MEX {Mexico} USA {United States} ARG {Argentina} BRA {Brazil} COL {Colombia} CHL {Chile} URY {Uruguay} ESP {Spain} PRT {Portugal} other {}}"
                    values={{ country: recipient.country }}
                  />
                </Typography>
              </div>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage id="label.name" defaultMessage="Name" />
            </Details.Label>
            {recipient ? (
              <Details.Value>{getRecipientTitle(recipient)}</Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          {recipient?.email ? (
            <Details>
              <Details.Label>
                <FormattedMessage id="label.email" defaultMessage="Email" />
              </Details.Label>
              <Details.Value>{recipient.email}</Details.Value>
            </Details>
          ) : null}
          {recipient?.internationalPhonePrefix && recipient.localPhoneNumber ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.phoneNumber"
                  defaultMessage="Phone number"
                />
              </Details.Label>
              <Details.Value>
                {recipient.internationalPhonePrefix}
                {recipient.localPhoneNumber}
              </Details.Value>
            </Details>
          ) : null}
          {getRecipientAddress(recipient) ? (
            <Details>
              <Details.Label>
                <FormattedMessage id="label.address" defaultMessage="Address" />
              </Details.Label>
              <Details.Value className="whitespace-break-spaces text-right">
                {getRecipientAddress(recipient, intl)}
              </Details.Value>
            </Details>
          ) : null}
        </Widget>

        <div className="p-4" />

        <Widget
          title={
            <FormattedMessage
              id="recipient.paymentDetails"
              defaultMessage="Payment details"
            />
          }
        >
          <PaymentDetails recipient={recipient} />
          {recipient?.bankName ? (
            <Details>
              <Details.Label>
                <FormattedMessage id="label.bank" defaultMessage="Bank" />
              </Details.Label>
              <Details.Value>{recipient.bankName}</Details.Value>
            </Details>
          ) : null}
        </Widget>

        <SheetFooter>
          <WithPermissions permissions={[Permission.TRANSFERS]}>
            <Button width="full" asChild>
              <Link
                state={{ from: location }}
                to={`${SEND_ROUTE}?recipient=${recipient?.id}`}
              >
                <FormattedMessage
                  id="action.sendPayment"
                  defaultMessage="Send a payment"
                />
              </Link>
            </Button>
          </WithPermissions>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
