import { useState } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { AnimatePresence } from 'framer-motion'

import { queryKeys } from '@/constants/queryKeys'

import { getOnboardingStepDetails } from '../api'
import {
  LegalRepresentativeForm,
  SelectLegalRepresentative,
} from '../components/LegalRepresentativeSteps'
import { OnboardingLoader } from '../components/OnboardingLoader'
import {
  LegalRepresentativeType,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepDetails,
} from '../types'

type Step =
  | { type: 'legalType' }
  | { type: 'select' }
  | { type: 'submit'; representativeType: LegalRepresentativeType }

type Props = {
  config?: OnboardingStepConfig
  steps: OnboardingStep[]
}

export const LegalRepresentativeStep = ({ config, steps }: Props) => {
  const [activeStep, setActiveStep] = useState<Step>({ type: 'select' })

  const [stepDetailsQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
        queryFn: () => getOnboardingStepDetails(config?.name ?? ''),
        select: (data: AxiosResponse<OnboardingStepDetails>) => data.data,
        enabled: !!config?.name,
      },
    ],
  })

  if (stepDetailsQuery.isPending) {
    return <OnboardingLoader />
  }

  return (
    <>
      <AnimatePresence mode="wait">
        {activeStep.type === 'select' && (
          <SelectLegalRepresentative
            config={config}
            steps={steps}
            onSelect={(representativeType) =>
              setActiveStep({
                type: 'submit',
                representativeType,
              })
            }
          />
        )}

        {activeStep.type === 'submit' && (
          <LegalRepresentativeForm
            config={config}
            steps={steps}
            representativeType={activeStep.representativeType}
            onBack={() => {
              setActiveStep({
                type: 'select',
              })
            }}
          />
        )}
      </AnimatePresence>
    </>
  )
}
