import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import {
  depositTransactionTypes,
  failedTransactionStatuses,
  getCashbackTransactions,
} from '@/features/Transactions'
import {
  SingleTransaction,
  TransactionType,
} from '@/features/Transactions/types'
import { formatDate, getBrowserTimeZone } from '@/lib/date'
import { formatCurrency, formatMoney } from '@/lib/money'
import { cn, parseAdditionalDetails } from '@/lib/utils'

import { Card, Details, Skeleton, Typography } from '../../ui'
import { StateCell } from '../TransactionsTable'

import { AdditionalDetails } from './AdditionalDetails'
import { ShortCashbackTransactionsTable } from './ShortCashbackTransactionsTable'
import { TransactionInfo } from './TransactionInfo'
import { TransactionPaymentDetails } from './TransactionPaymentDetails'

type Props = {
  transaction?: SingleTransaction
}

export const SingleTransactionDetails = ({ transaction }: Props) => {
  const additionalDetails = parseAdditionalDetails<{
    claveRastreo?: string
    imad?: string
    omad?: string
    taskOwnerFullName?: string
    completedByFullName?: string
  }>(transaction?.additionalDetails)

  const cashbackTransactionQuery = useQuery({
    queryKey: [queryKeys.getCashbackTransactions, transaction?.id],
    select: (data) => data.data,
    queryFn: () =>
      getCashbackTransactions({
        id: transaction?.id ?? '',
      }),
    enabled: transaction?.transactionType === TransactionType.CASHBACK,
    retry: 1,
  })

  const isDeposit = transaction
    ? depositTransactionTypes.includes(transaction?.transactionType)
    : false

  const isFailed = transaction?.transactionStatus
    ? failedTransactionStatuses.includes(transaction?.transactionStatus)
    : false

  return (
    <>
      <Typography variant="h3" className={cn('', isFailed && 'line-through')}>
        {isDeposit ? '+' : ''}
        {formatMoney(transaction?.baseAmount ?? 0)}{' '}
        {formatCurrency(transaction?.baseCurrency)}
      </Typography>

      <div className="p-1.5" />

      {transaction?.name ? (
        <Typography>
          <FormattedMessage
            id="transaction.typeLabel"
            defaultMessage="{type, select, DEPOSIT {From:} WITHDRAW {To:} INTERNAL_SWAP_DEPOSIT {From:} INTERNAL_SWAP_WITHDRAW {To:} other {}}"
            values={{ type: transaction.transactionType }}
          />{' '}
          {transaction.name}
        </Typography>
      ) : (
        <Skeleton className="h-[21px] w-24" />
      )}

      <div className="p-3" />

      <div className="flex flex-col gap-4">
        <TransactionInfo transaction={transaction} />

        <AdditionalDetails transaction={transaction} />

        {transaction ? (
          <TransactionPaymentDetails transaction={transaction} />
        ) : (
          <>
            <Details.Loader />
            <div className="p-2" />
            <Details.Loader />
          </>
        )}

        {transaction ? (
          <Card className="flex flex-col gap-4" size="medium">
            {additionalDetails?.imad ? (
              <Details>
                <Details.Label>
                  <FormattedMessage id="label.imad" defaultMessage="IMAD" />
                </Details.Label>

                <Details.Value>{additionalDetails.imad}</Details.Value>
              </Details>
            ) : null}
            {additionalDetails?.omad ? (
              <Details>
                <Details.Label>
                  {' '}
                  <FormattedMessage id="label.omad" defaultMessage="OMAD" />
                </Details.Label>

                <Details.Value>{additionalDetails.omad}</Details.Value>
              </Details>
            ) : null}
            {additionalDetails?.claveRastreo ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.claveDeRastreo"
                    defaultMessage="Clave de rastreo"
                  />
                </Details.Label>

                <Details.Value>{additionalDetails.claveRastreo}</Details.Value>
              </Details>
            ) : null}
            <Details>
              <Details.Label>
                <FormattedMessage id="label.date" defaultMessage="Date" />
              </Details.Label>
              <Details.Value>
                {formatDate(transaction.createdOn, `d MMM. yyyy, HH:mm`)} (
                {getBrowserTimeZone()})
              </Details.Value>
            </Details>
            <Details>
              <Details.Label>
                <FormattedMessage id="label.status" defaultMessage="Status" />
              </Details.Label>
              <StateCell transaction={transaction} />
            </Details>
            {additionalDetails?.taskOwnerFullName ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.requestedBy"
                    defaultMessage="Requested by"
                  />
                </Details.Label>
                <Details.Value>
                  {additionalDetails.taskOwnerFullName}
                </Details.Value>
              </Details>
            ) : null}
            {additionalDetails?.completedByFullName ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.approvedBy"
                    defaultMessage="Approved by"
                  />
                </Details.Label>
                <Details.Value>
                  {additionalDetails.completedByFullName}
                </Details.Value>
              </Details>
            ) : null}
          </Card>
        ) : (
          <Details.Loader rows={2} />
        )}

        {transaction?.transactionType === TransactionType.CASHBACK ? (
          <ShortCashbackTransactionsTable
            isPending={cashbackTransactionQuery.isFetching}
            transactionId={transaction?.id}
            transactions={(
              cashbackTransactionQuery.data?.eligibleTransactions ?? []
            ).slice(0, 3)}
          />
        ) : null}
      </div>
    </>
  )
}
