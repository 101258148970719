import { useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { logout as logoutApi } from '@/api/logout'
import { LOGGING_OUT_ROUTE, SIGNIN_ROUTE } from '@/constants/paths'
import { queryClient } from '@/lib/queryClient'
import { useAuth } from '@/providers/AuthProvider'

import { useErrorToast } from './useErrorToast'

export function useLogout() {
  const { updateRefreshToken, setAuthToken } = useAuth()
  const notifyError = useErrorToast()
  const { mutateAsync } = useMutation({
    mutationFn: logoutApi,
  })

  const navigate = useNavigate()

  const logout = useCallback(async () => {
    navigate(LOGGING_OUT_ROUTE, { replace: true })

    try {
      await mutateAsync()
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }

    setAuthToken(null)
    updateRefreshToken(null)
    sessionStorage.clear()
    Sentry.setUser(null)

    queryClient.invalidateQueries()
    queryClient.resetQueries()

    navigate(SIGNIN_ROUTE, { replace: true })
  }, [mutateAsync, navigate, notifyError, setAuthToken, updateRefreshToken])

  return { logout }
}
