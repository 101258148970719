import { FormattedMessage } from 'react-intl'

import { cn } from '@/lib/utils'
import { Typography } from '@/shared/ui'

import {
  bgColorClassNameByTeamState,
  dotColorClassNameByTeamState,
  textColorClassNameByTeamState,
} from '../../constants'
import { TeamMember } from '../../types'

type Props = {
  member: TeamMember
}

export const TeamStateCell = ({ member }: Props) => {
  return (
    <div className="flex items-center">
      <div
        className={cn(
          'flex h-[22px] items-center gap-1.5 rounded-xl px-2',
          `${bgColorClassNameByTeamState[member.state]}`,
        )}
      >
        <span
          className={cn(
            'size-1.5 rounded-full',
            dotColorClassNameByTeamState[member.state],
          )}
        />

        <Typography
          className={cn(textColorClassNameByTeamState[member.state])}
          bold
          variant="body-small"
        >
          <FormattedMessage
            id="team.state.label"
            defaultMessage="{state, select, ACTIVE {Active} INVITED {Invited} BLOCKED {Blocked} TERMINATED {Terminated} other {}}"
            values={{ state: member.state }}
          />
        </Typography>
      </div>
    </div>
  )
}
