import { useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { generatePath } from 'react-router-dom'
import { z } from 'zod'

import { ONBOARDING_STEP } from '@/constants/paths'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import {
  CompanyOwner,
  OnboardingStepConfig,
  OnboardingStepName,
  OwnerType,
} from '../../types'
import { OwnerAddressFields } from '../OwnerAddressFields'

const createOwnerAddressSchema = z.object({
  GOOGLE_PLACES_ADDRESS: z.string(),

  ADDRESS_STREET: z.string(),
  ADDRESS_STREET_NUMBER: z.string(),
  ADDRESS_DISTRICT: z.string(),
  ADDRESS_CITY: z.string(),
  ADDRESS_STATE: z.string(),
  ADDRESS_POST_CODE: z.string(),
  ADDRESS_COUNTRY: z.string(),
})

export type CreateOwnerAddressSchema = z.infer<typeof createOwnerAddressSchema>

const DEFAULT_VALUES: CreateOwnerAddressSchema = {
  GOOGLE_PLACES_ADDRESS: '',
  ADDRESS_STREET: '',
  ADDRESS_STREET_NUMBER: '',
  ADDRESS_DISTRICT: '',
  ADDRESS_CITY: '',
  ADDRESS_STATE: '',
  ADDRESS_POST_CODE: '',
  ADDRESS_COUNTRY: '',
}

const CREATE_OWNER_ADDRESS_FORM_ID = 'create-owner-address-form-id'

type Props = {
  data: Omit<CompanyOwner, 'id'>
  config?: OnboardingStepConfig
  owner?: CompanyOwner
  ownerType?: OwnerType
  onBack: () => void
  onContinue: (data: Omit<CompanyOwner, 'id'>) => void
}

export const OwnerAddressStep = ({
  config,
  data,
  onBack,
  onContinue,
  owner,
  ownerType,
}: Props) => {
  const [inputView, setInputView] = useState<'auto' | 'manual'>('auto')

  const form = useForm<CreateOwnerAddressSchema>({
    mode: 'onChange',
    resolver: zodResolver(createOwnerAddressSchema),
    defaultValues: DEFAULT_VALUES,
  })

  useEffect(() => {
    if (inputView === 'manual') {
      return
    }

    if (owner || data.addressStreet) {
      const {
        addressStreet,
        addressStreetNumber,
        addressDistrict,
        addressCity,
        addressState,
        addressPostCode,
        addressCountry,
      } = owner ?? data

      const ownerFields = [
        addressStreet,
        addressStreetNumber,
        addressDistrict,
        addressCity,
        addressState,
        addressPostCode,
        addressCountry,
      ]

      const formFields = [
        'ADDRESS_STREET',
        'ADDRESS_STREET_NUMBER',
        'ADDRESS_DISTRICT',
        'ADDRESS_CITY',
        'ADDRESS_STATE',
        'ADDRESS_POST_CODE',
        'ADDRESS_COUNTRY',
      ] as const

      formFields.forEach((field, index) => {
        if (!ownerFields[index]) {
          return
        }

        form.setValue(field, ownerFields[index] ?? '')
        form.trigger(field)
      })

      if (formFields.some((field) => !!field)) {
        setInputView('manual')
      }
    }

    // we don't want to re-run this effect when inputView changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, owner])

  const onSubmit: SubmitHandler<CreateOwnerAddressSchema> = useCallback(
    (formData) => {
      const {
        ADDRESS_STREET,
        ADDRESS_STREET_NUMBER,
        ADDRESS_DISTRICT,
        ADDRESS_CITY,
        ADDRESS_STATE,
        ADDRESS_POST_CODE,
        ADDRESS_COUNTRY,
      } = formData

      const requestData = {
        ...data,
        addressStreet: ADDRESS_STREET,
        addressStreetNumber: ADDRESS_STREET_NUMBER,
        addressDistrict: ADDRESS_DISTRICT,
        addressCity: ADDRESS_CITY,
        addressState: ADDRESS_STATE,
        addressPostCode: ADDRESS_POST_CODE,
        addressCountry: ADDRESS_COUNTRY,
      }

      onContinue(requestData)
    },
    [data, onContinue],
  )

  const fields = form.watch()

  const hasAllRequiredFields = useMemo(() => {
    if (!config?.fields) {
      return false
    }

    const stepFields = [
      'ADDRESS_STREET',
      'ADDRESS_STREET_NUMBER',
      'ADDRESS_DISTRICT',
      'ADDRESS_CITY',
      'ADDRESS_STATE',
      'ADDRESS_POST_CODE',
      'ADDRESS_COUNTRY',
    ]

    const filteredRequiredFields = config.fields.filter(
      (field) => stepFields.includes(field.name) && field.required,
    )

    return filteredRequiredFields.every(
      (field) => fields[field.name as keyof typeof fields],
    )
  }, [fields, config?.fields])

  const shouldRedirect = useMemo(() => {
    return (
      (ownerType === OwnerType.OTHER_LEGAL_REPRESENTATIVE_AND_OWNER ||
        ownerType === OwnerType.USER_LEGAL_REPRESENTATIVE_AND_OWNER) &&
      !!owner
    )
  }, [owner, ownerType])

  return (
    <>
      <GoBackButton
        to={
          shouldRedirect
            ? generatePath(ONBOARDING_STEP, {
                step: OnboardingStepName.COMPANY_OWNERSHIP,
              })
            : undefined
        }
        onClick={shouldRedirect ? undefined : onBack}
      />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="onboarding.ownership.addressStep.title"
            defaultMessage="Provide {name}'s residential address"
            values={{
              name: data.firstName,
            }}
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="onboarding.ownership.addressStep.subtitle"
            defaultMessage="Search for the address or enter the address manually"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form id={CREATE_OWNER_ADDRESS_FORM_ID} className="w-full">
            <OwnerAddressFields
              inputView={inputView}
              onInputViewChange={setInputView}
              form={form}
            />
          </form>
        </Form>

        <StickyContainer>
          <Button
            disabled={!hasAllRequiredFields}
            width="full"
            form={CREATE_OWNER_ADDRESS_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>

          {inputView === 'manual' && (
            <>
              <div className="p-1" />
              <Button
                type="button"
                onClick={() => {
                  setInputView('auto')
                  form.setValue('GOOGLE_PLACES_ADDRESS', '')
                }}
                width="full"
                className="text-center font-semibold text-primary"
                variant="ghost"
              >
                <FormattedMessage
                  defaultMessage="Search for my address"
                  id="onboarding.businessAddress.searchForAddress"
                />
              </Button>
            </>
          )}
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
