import { FormattedMessage, useIntl } from 'react-intl'

import { formatDate } from '@/lib/date'
import { Typography } from '@/shared/ui'

import { useDocumentsList } from '../../hooks/useDocumentsList'
import { CompanyFormationStep, OnboardingStepName } from '../../types'
import { StepDetails } from '../StepDetails'

type Props = {
  stepDetails?: CompanyFormationStep['stepDetails']
}

export const CompanyFormationReview = ({ stepDetails }: Props) => {
  const intl = useIntl()

  const documentKeys: Record<string, string> = {
    FORMATION_DOCUMENTS: intl.formatMessage({
      id: 'onboarding.step.companyFormation.formationDocuments',
      defaultMessage: 'Formation documents',
    }),
    TAX_CERTIFICATE_OF_GOOD_STANDING: intl.formatMessage({
      id: 'onboarding.step.companyFormation.taxRegistryCertificate',
      defaultMessage: 'Tax registration certificate',
    }),
  }

  const documentsDetails = useDocumentsList(
    stepDetails?.documents ?? [],
    documentKeys,
  )

  if (!stepDetails) {
    return null
  }

  const { documents, ...fields } = stepDetails

  const mappedKeys: Record<keyof typeof fields, string | undefined> = {
    dateOfIncorporation: intl.formatMessage({
      id: 'onboarding.step.companyFormation.dateOfIncorporation',
      defaultMessage: 'Date of incorporation',
    }),
    countryOfIncorporation: intl.formatMessage({
      id: 'onboarding.step.companyFormation.countryOfIncorporation',
      defaultMessage: 'Country of incorporation',
    }),
    taxId: stepDetails.taxIdType,
    taxIdType: undefined,
  }

  const mappedValues: Record<keyof typeof fields, React.ReactNode> = {
    dateOfIncorporation: (
      <Typography>
        {formatDate(fields.dateOfIncorporation ?? '', 'dd/MM/yyyy')}
      </Typography>
    ),
    countryOfIncorporation: (
      <Typography>
        <FormattedMessage
          id="country.name"
          defaultMessage="{country, select, MEX {Mexico} USA {United States} ARG {Argentina} BRA {Brazil} COL {Colombia} CHL {Chile} URY {Uruguay} ESP {Spain} PRT {Portugal} other {}}"
          values={{ country: fields.countryOfIncorporation }}
        />
      </Typography>
    ),
    taxId: <Typography>{fields.taxId}</Typography>,
    taxIdType: undefined,
  }

  const details = Object.entries(fields).map(([key]) => {
    return {
      key: mappedKeys[key as keyof typeof mappedKeys],
      value: mappedValues[key as keyof typeof mappedValues],
    }
  })

  return (
    <StepDetails
      step={OnboardingStepName.COMPANY_FORMATION}
      title={intl.formatMessage({
        id: 'onboarding.step.companyFormation',
        defaultMessage: 'Company formation',
      })}
      details={[...details, ...documentsDetails]}
    />
  )
}
