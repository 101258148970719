import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { Transaction } from '@/features/Transactions/types'
import { useSearchInput } from '@/hooks/useSearchInput'
import { useTransactionUtils } from '@/hooks/useTransactionUtils'
import { downloadFile } from '@/lib/utils'
import {
  AccountsBadge,
  AccountsFilter,
  ActiveFilters,
  CurrencyBadge,
  CurrencyFilter,
  DateBadge,
  DateFilter,
  EmptyTransactionsCard,
  FiltersWidget,
  MoveMoneyWidget,
  StateBadge,
  StateFilter,
  TransactionDetailsSidebar,
  TransactionsTable,
  TypeBadge,
  TypeFilter,
  useTransactionColumns,
} from '@/shared/components'
import { DownloadFile } from '@/shared/icons/outline'
import { Button, SearchInput, Typography } from '@/shared/ui'

import { getTransactionByType, getTransactions } from './api'
import { getTransactionsCSV } from './api/getTransactionsCSV'

export const Transactions = () => {
  const {
    closeSidebar,
    openSidebar,
    params,
    isSidebarOpen,
    transactionIdFromQuery,
    transactionTypeFromQuery,
  } = useTransactionUtils()

  const intl = useIntl()

  const columns = useTransactionColumns()

  const [search, setSearch, handleSearchQuery] = useSearchInput()

  const [allTransactions, singleTransaction] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getTransactions, params],
        queryFn: () => getTransactions(params),
        select: (data: AxiosResponse<Transaction[]>) => data?.data,
        staleTime: 0,
      },
      {
        queryKey: [
          queryKeys.getTransaction,
          transactionIdFromQuery,
          transactionTypeFromQuery,
        ],
        queryFn: () =>
          getTransactionByType({
            type: transactionTypeFromQuery,
            id: transactionIdFromQuery,
          }),
        select: (data: AxiosResponse<Transaction>) => data?.data,
        enabled: !!transactionIdFromQuery,
      },
    ],
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: getTransactionsCSV,
  })

  const downloadCSV = () => {
    mutateAsync(params).then((data) =>
      downloadFile(
        data.data,
        intl.formatMessage({
          id: 'transactions.statement.csv',
          defaultMessage: 'transactions.csv',
        }),
      ),
    )
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage
              id="dashboard.transactions.title"
              defaultMessage="Transactions"
            />
          </Typography>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.transactions.subtitle"
              defaultMessage="Search, filter and export all your past transactions"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />
        <FiltersWidget>
          <TypeFilter />
          <AccountsFilter />
          <DateFilter />
          <StateFilter />
          <CurrencyFilter />
        </FiltersWidget>

        <Button
          size="md"
          onClick={downloadCSV}
          loading={isPending}
          disabled={allTransactions.data?.length === 0 || isPending}
          leftIcon={<DownloadFile className="size-5" />}
          loaderPosition="left"
          variant="tertiary"
        >
          <FormattedMessage
            id="action.exportCSV"
            defaultMessage="Export .csv"
          />
        </Button>
      </div>

      <div className="p-3" />

      <ActiveFilters>
        <TypeBadge />
        <AccountsBadge />
        <DateBadge />
        <StateBadge />
        <CurrencyBadge />
      </ActiveFilters>

      {allTransactions.data?.length === 0 &&
      Object.keys(params).length === 0 ? (
        <EmptyTransactionsCard />
      ) : (
        <TransactionsTable
          isLoading={allTransactions.isPending}
          onRowClick={openSidebar}
          columns={columns}
          data={allTransactions.data ?? []}
          loaderOptions={{ rows: 4 }}
        />
      )}

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={isSidebarOpen}
        onOpenChange={closeSidebar}
      />
    </div>
  )
}
