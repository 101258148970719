import { useEffect, useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { useErrorToast } from '@/hooks/useErrorToast'
import { hasOTPRequiredError } from '@/lib/error'
import { queryClient } from '@/lib/queryClient'
import { intersperse } from '@/lib/typography'
import { Button, Card, Typography } from '@/shared/ui'

import { approveMultipleTasks } from '../api'
import { Task, TaskType } from '../types'

import { ApproveOTPDialog } from './ApproveOTPDialog'
import { CancelTasksDialog } from './CancelTaskDialog'
import { DeclineTasksDialog } from './DeclineTasksDialog'

type Props = {
  onActionCallback: (id: string[]) => void
  tasks: Task[]
}

export const FloatingTaskButton = ({ onActionCallback, tasks }: Props) => {
  const { isAdmin, isPaymentOps } = useBusinessRole()
  const [isVisible, setIsVisible] = useState(false)
  const intl = useIntl()
  const notifyError = useErrorToast()

  const [showDeclineDialog, setShowDeclineDialog] = useState(false)
  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [showCancelDialog, setShowCancelDialog] = useState(false)

  const { mutateAsync, isError, isPending } = useMutation({
    mutationFn: approveMultipleTasks,
  })

  const subtitle = useMemo(() => {
    if (tasks.length === 0) {
      return ''
    }

    if (tasks.length === 1) {
      const [task] = tasks

      switch (task.type) {
        case TaskType.ADD_BENEFICIARY:
          return (
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="tasks.floatingButton.single.addBeneficiary.subtitle"
                defaultMessage="Add recipient"
              />
            </Typography>
          )

        case TaskType.EXECUTE_WITHDRAWAL:
          return (
            <Typography className="text-neutral-gray-600">
              <FormattedMessage id="action.send" defaultMessage="Send" />
            </Typography>
          )

        case TaskType.EXECUTE_BULK_PAYMENT:
          return (
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="label.bulkPayment"
                defaultMessage="Bulk payment"
              />
            </Typography>
          )

        default:
          return ''
      }
    }

    return intersperse(
      tasks
        .map((task) => task.type)
        .filter((type, index, self) => self.indexOf(type) === index)
        .map((task) => (
          <Typography key={task} className="inline-flex text-neutral-gray-600">
            <FormattedMessage
              id="tasks.selected.requests.type"
              defaultMessage="{type, select, EXECUTE_WITHDRAWAL {Send} EXECUTE_BULK_PAYMENT {Bulk payment} ADD_BENEFICIARY {Add recipient} other {}}"
              values={{ type: task }}
            />
          </Typography>
        )),
    )
  }, [tasks])

  useEffect(() => {
    if (tasks.length === 0) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [tasks])

  const onContinue = (idx: string[]) => {
    onActionCallback(idx)
    setIsVisible(false)
  }

  const onApproveTask = async (otp?: string) => {
    const businessTaskIds = tasks.map((task) => task.id)

    try {
      const response = await mutateAsync({
        businessTaskIds,
        otp,
      })

      toast.success(
        intl.formatMessage(
          {
            id: 'tasks.multiple.approve.dialog.success',
            defaultMessage:
              '{count, plural, =0 {} one {# task approved successfully} other {# tasks approved successfully}}',
          },
          { count: response.data.length },
        ),
      )

      queryClient.refetchQueries({
        queryKey: [queryKeys.getAdminTasks],
      })

      onActionCallback(response.data.map((response) => response.id))
    } catch (error) {
      if (error instanceof Error) {
        if (hasOTPRequiredError(error)) {
          return
        }

        notifyError(error)
      }
    }
  }

  if (!isVisible) {
    return null
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, transform: 'translateX(-50%) translateY(50px)' }}
        animate={{
          opacity: 1,
          y: 0,
          transform: 'translateX(-50%) translateY(0)',
        }}
        exit={{
          opacity: 0,
          y: 50,
          transform: 'translateX(-50%) translateY(50px)',
        }}
        className="fixed bottom-16 left-1/2 z-50"
      >
        <Card
          size="upload"
          className="flex min-h-[78px] max-w-xl flex-wrap items-center justify-center gap-6 rounded-lg px-6 py-4 shadow-floating md:flex-nowrap"
        >
          <div className="flex flex-col items-center gap-0 md:w-40 md:items-start">
            <Typography bold>
              <FormattedMessage
                id="tasks.selected.requests"
                defaultMessage="{count, plural, =0 {} one {# request selected} other {# requests selected}}"
                values={{ count: tasks.length }}
              />
            </Typography>

            <div className="text-neutral-gray-600">{subtitle}</div>
          </div>

          {isAdmin && (
            <div className="flex gap-2">
              <Button
                size="sm"
                variant="secondary"
                onClick={() => setShowDeclineDialog(true)}
              >
                <FormattedMessage
                  id="action.declineAll"
                  defaultMessage="Decline all"
                />
              </Button>
              <Button
                onClick={() => {
                  onApproveTask()

                  setShowApproveDialog(true)
                }}
                size="sm"
              >
                <FormattedMessage
                  id="action.approveAll"
                  defaultMessage="Approve all"
                />
              </Button>
            </div>
          )}

          {isPaymentOps && (
            <div className="flex gap-2">
              <Button size="sm" onClick={() => setShowCancelDialog(true)}>
                <FormattedMessage
                  id="action.cancelAll"
                  defaultMessage="Cancel all"
                />
              </Button>
            </div>
          )}
        </Card>
      </motion.div>

      <ApproveOTPDialog
        isOpen={showApproveDialog}
        onOpenChange={setShowApproveDialog}
        tasks={tasks}
        isPending={isPending}
        isError={isError}
        onContinue={onApproveTask}
      />

      <DeclineTasksDialog
        tasks={tasks}
        isOpen={showDeclineDialog}
        onOpenChange={setShowDeclineDialog}
        onContinue={(idx) => {
          onContinue(idx)

          setShowDeclineDialog(false)
        }}
      />

      <CancelTasksDialog
        tasks={tasks}
        isOpen={showCancelDialog}
        onOpenChange={setShowCancelDialog}
        onContinue={(idx) => {
          onContinue(idx)

          setShowCancelDialog(false)
        }}
      />
    </>
  )
}
