import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { CARDS_ROUTE, DASHBOARD_ROUTE } from '@/constants/paths'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { Loader } from '@/shared/components'

export const RoleChecker = () => {
  const location = useLocation()
  const { role } = useBusinessRole()
  const navigate = useNavigate()

  useEffect(() => {
    switch (role) {
      case 'ADMIN':
      case 'PAYMENT_OPS':
      case 'READ_ONLY':
        navigate(location.state?.from ?? DASHBOARD_ROUTE, { replace: true })
        break
      case 'CARD_USER':
        navigate(CARDS_ROUTE, { replace: true })
        break

      default:
        break
    }
  }, [role, navigate, location.state?.from])

  return <Loader />
}
