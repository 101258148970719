import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { z } from 'zod'

import { queryKeys } from '@/constants/queryKeys'
import { getAccount } from '@/features/Accounts/api'
import { Account } from '@/features/Accounts/types'
import { GoBackButton, PhysicalCardFields } from '@/shared/components'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { PaymentLimitType } from '@/types/limits'

const CARD_INFO_FORM_ID = 'card-info-form-id'

const physicalCardInfoSchema = z.object({
  nickname: z.string().min(1, {
    message: 'validation.nickname.required',
  }),
  requestId: z.string(),
  identityId: z.string(),
  cardholder: z.string().optional(),
  walletId: z.string().min(1),
  pin: z
    .object({
      code: z
        .string()
        .min(1, { message: 'validation.pin.required' })
        .length(4, { message: 'validation.pin.required' }),
      confirm: z.string().min(1, { message: 'validation.pin.required' }),
    })
    .refine(
      (data) => {
        return data.code === data.confirm
      },
      {
        message: 'validation.pin.match',
        path: ['confirm'],
      },
    ),
  limitAmount: z.string().refine((v) => Big(v === '' ? 0 : v).gt(0), {
    message: 'validation.balance.minAmount',
  }),
  limitType: z.nativeEnum(PaymentLimitType),
})

export type PhysicalCardInfoSchema = z.infer<typeof physicalCardInfoSchema>

const DEFAULT_VALUES: PhysicalCardInfoSchema = {
  nickname: '',
  walletId: '',
  requestId: '',
  identityId: '',
  limitAmount: '',
  limitType: '' as PaymentLimitType,
  pin: {
    code: '',
    confirm: '',
  },
}

type Props = {
  physicalCardDetails?: PhysicalCardInfoSchema
  onBack: () => void
  onContinue: (data: PhysicalCardInfoSchema) => void
}

export const CreatePhysicalCardStep = ({
  physicalCardDetails,
  onBack,
  onContinue,
}: Props) => {
  const form = useForm<PhysicalCardInfoSchema>({
    mode: 'onChange',
    resolver: zodResolver(physicalCardInfoSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const [accountQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getAccount],
        queryFn: getAccount,
        select: (data: AxiosResponse<Account>) => data?.data,
      },
    ],
  })

  useEffect(() => {
    if (physicalCardDetails) {
      form.setValue('nickname', physicalCardDetails.nickname)
      form.setValue('walletId', physicalCardDetails.walletId)
      form.setValue('limitAmount', physicalCardDetails.limitAmount)
      form.setValue('cardholder', physicalCardDetails.cardholder)
      form.setValue('identityId', physicalCardDetails.identityId)
      form.setValue('limitType', physicalCardDetails.limitType)
      form.setValue('pin.code', physicalCardDetails.pin.code)
      form.setValue('pin.confirm', physicalCardDetails.pin.confirm)

      form.trigger()
    }
  }, [form, physicalCardDetails])

  const onSubmit: SubmitHandler<PhysicalCardInfoSchema> = async (data) => {
    onContinue(data)
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="cards.create.infoStep.physical.title"
            defaultMessage="Create a physical card"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="cards.create.infoStep.physical.description"
            defaultMessage="Customize the card to fit your needs"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={CARD_INFO_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <PhysicalCardFields
              form={form}
              wallets={accountQuery.data?.wallets}
              onSelectCardholder={(cardholder) => {
                form.setValue('cardholder', cardholder)
              }}
            />
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={CARD_INFO_FORM_ID}
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
