import { Currency } from '@/constants/currency'

export type Account = {
  id: string
  wallets: Wallet[]
  totalBalance: number
  totalBalanceCurrency: Currency
}

export type Wallet = {
  id: string
  accountId: string
  currency: Currency
  balance: number
  label: string
  monthlyTotalMoneyIn: number
  monthlyTotalMoneyOut: number
}

export enum AccountType {
  WIRE = 'USA_LOCAL_WIRE',
  ACH = 'USA_LOCAL_ACH',
  LOCAL_MX = 'MEX_LOCAL',
}

export type AccountDetails = {
  bankAccounts: BankAccount[]
}

export type Address = {
  street: string
  city: string
  postcode: string
  state: string
  countryCode: string
}

type HolderDetails = {
  bankName: string
  entityName: string
  bankAddress: Address
}

type Identifier = {
  type: string
  value: string
}

type DetailsWire = {
  accountIdentifier: Identifier
  accountEntityType: string
  bankIdentifier: Identifier
  additionalInformation: {
    DEPOSIT_MESSAGE: string
  }
}

type DetailsACH = {
  accountIdentifier: Identifier
  accountEntityType: string
  bankIdentifier: Identifier
  additionalInformation: null
}

type USAccountACH = {
  type: AccountType.ACH
  currency: Currency
  state: string
  holderDetails: HolderDetails
  accountDetails: DetailsACH
}

type USAccountWire = {
  type: AccountType.WIRE
  currency: Currency
  state: string
  holderDetails: HolderDetails
  accountDetails: DetailsWire
}

type MXAccount = {
  type: AccountType.LOCAL_MX
  currency: Currency
  state: string
  holderDetails: { bankName: string; entityName: string }
  accountDetails: {
    accountIdentifier: Identifier
    accountEntityType: null
    bankIdentifier: null
  }
}

export type BankAccount = MXAccount | USAccountWire | USAccountACH

export interface AccountIdentifier {
  type: string
  value: string
}
