import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { ACCOUNTS_ROUTE, DASHBOARD_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { formatCurrency, formatMoney } from '@/lib/money'
import {
  AccountSelect,
  CurrencyFlag,
  GoBackButton,
  ProgressButton,
  Widget,
} from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FullScreen,
  MoneyInput,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { getAccount } from '../Accounts'

import { transferMoney } from './api'

const TRANSFER_FORM_ID = 'transfer-form'

const DEFAULT_VALUES: TransferSchema = {
  fromBalance: Big(0).toFixed(),
  requestId: '',
  fromWallet: '',
  toWallet: '',
  currency: Currency.USDC,
  amount: '',
}

const transferSchema = z
  .object({
    fromBalance: z.string(),
    requestId: z.string(),
    fromWallet: z.string().min(1),
    toWallet: z.string().min(1),
    amount: z.string().refine((v) => Big(v === '' ? 0 : v).gt(0), {
      message: 'validation.balance.minAmount',
    }),
    currency: z.nativeEnum(Currency),
  })
  .refine(
    (data) =>
      data.fromWallet
        ? Big(data.fromBalance).gte(data.amount === '' ? 0 : data.amount)
        : true,
    {
      message: 'validation.balance.insufficient',
      path: ['amount'],
    },
  )

type TransferSchema = z.infer<typeof transferSchema>

export const TransferMoney = () => {
  const intl = useIntl()
  const [from] = useSearchParamsValue(['from'])

  const notifyError = useErrorToast()

  const accountQuery = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) => data?.data,
  })

  const { mutateAsync, isPending } = useMutation({ mutationFn: transferMoney })

  const navigate = useNavigate()
  const location = useLocation()

  const form = useForm<TransferSchema>({
    mode: 'onChange',
    resolver: zodResolver(transferSchema),
    defaultValues: DEFAULT_VALUES,
  })

  useEffect(() => {
    if (accountQuery.data?.wallets) {
      const walletFromParams = accountQuery.data.wallets.find(
        (wallet) => wallet.id === from,
      )

      if (walletFromParams) {
        form.setValue('fromBalance', Big(walletFromParams.balance).toFixed())
        form.setValue('fromWallet', walletFromParams.id)
      }
    }
  }, [accountQuery.data?.wallets, form, from])

  const onSubmit: SubmitHandler<TransferSchema> = async (data) => {
    const { fromBalance, ...rest } = data

    const fromLabel = accountQuery.data?.wallets.find(
      (wallet) => wallet.id === rest.fromWallet,
    )?.label

    const toLabel = accountQuery.data?.wallets.find(
      (wallet) => wallet.id === rest.toWallet,
    )?.label

    try {
      await mutateAsync({ ...rest, requestId: crypto.randomUUID() })

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Move successful!',
          id: 'transfer.success',
        }),
        {
          description: intl.formatMessage(
            {
              id: 'transfer.success.description',
              defaultMessage:
                'You have successfully moved {amount} {currency} from {from} to {to}',
            },
            {
              amount: formatMoney(rest.amount),
              currency: formatCurrency(rest.currency),
              from: fromLabel,
              to: toLabel,
            },
          ),
        },
      )

      navigate(ACCOUNTS_ROUTE)
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <FullScreen>
      <ProgressButton
        progress={0}
        to={location.state?.from ?? DASHBOARD_ROUTE}
      />

      <GoBackButton to={location.state?.from ?? DASHBOARD_ROUTE} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Move between your accounts"
            id="transfer.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="It's instant and it's free"
            id="transfer.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={TRANSFER_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Widget
              title={<FormattedMessage defaultMessage="From" id="label.from" />}
              variant="form"
            >
              <FormField
                control={form.control}
                name="fromWallet"
                render={({ field }) => {
                  return (
                    <AccountSelect
                      value={field.value}
                      accounts={accountQuery.data?.wallets.filter(
                        (acc) => acc.id !== form.watch('toWallet'),
                      )}
                      label={intl.formatMessage({
                        defaultMessage: 'Select an account',
                        id: 'label.selectAccount',
                      })}
                      onChange={(v) => {
                        field.onChange(v)

                        const selected = accountQuery.data?.wallets.find(
                          (wallet) => wallet.id === v,
                        )

                        if (selected) {
                          form.setValue(
                            'fromBalance',
                            Big(selected.balance).toFixed(),
                          )

                          if (form.getValues('amount') !== '') {
                            form.trigger('amount')
                          }
                        }
                      }}
                    />
                  )
                }}
              />
            </Widget>

            <div className="p-3" />

            <Widget
              title={<FormattedMessage defaultMessage="To" id="label.to" />}
              variant="form"
            >
              <FormField
                control={form.control}
                name="toWallet"
                render={({ field }) => {
                  return (
                    <AccountSelect
                      disabled={accountQuery.data?.wallets.length === 1}
                      value={field.value}
                      accounts={accountQuery.data?.wallets.filter(
                        (acc) => acc.id !== form.watch('fromWallet'),
                      )}
                      label={intl.formatMessage({
                        defaultMessage: 'Select an account',
                        id: 'label.selectAccount',
                      })}
                      onChange={field.onChange}
                    />
                  )
                }}
              />
            </Widget>

            <div className="p-3" />

            <Widget
              title={
                <FormattedMessage defaultMessage="Amount" id="label.amount" />
              }
              variant="form"
            >
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem>
                    <Card size="input" className="flex">
                      <CurrencyFlag />
                      <FormControl>
                        <MoneyInput
                          currency={Currency.USD}
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Amount',
                            id: 'label.amount',
                          })}
                          className="text-right"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </FormControl>

                      <AnimatedFormLabel align="end">
                        <FormattedMessage
                          defaultMessage="Amount"
                          id="label.amount"
                        />
                      </AnimatedFormLabel>
                    </Card>
                  </FormItem>
                )}
              />
            </Widget>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={TRANSFER_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            loading={isPending}
            disabled={isPending || !form.formState.isValid}
            type="submit"
          >
            <FormattedMessage defaultMessage="Move" id="action.transfer" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </FullScreen>
  )
}
