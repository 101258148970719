import { TeamState } from '@/features/Team/types'

import { CountryCode, CountryTaxId } from './country'
import { Permission } from './permissions'
import { Role } from './roles'

export enum BusinessState {
  ACTIVE = 'ACTIVE',
  ONBOARDING = 'ONBOARDING',
}

export type UserIdentity = {
  identityId: string
  businessId: string
}

export type Identity = {
  id: string
  email: string
  state: 'ACTIVE'
  firstName: string
  secondLastName?: string
  lastName: string
  phoneNumber: {
    internationalPhonePrefix: string
    localPhoneNumber: string
  }
}

export type BusinessIdentity = {
  id: string
  legalName: string
  countryOfIncorporation: CountryCode
  addressCity: string | null
  addressMunicipality?: string | null
  addressNeighborhood?: string | null
  addressPostalCode?: string | null
  addressState?: string | null
  addressStreet?: string | null
  addressStreetNumber?: string | null
  dateOfIncorporation?: string | null
  taxId: string
  taxIdType: CountryTaxId
  state: BusinessState
}

export type BusinessUser = {
  id: string
  businessId: string
  identityId: string
  email: string
  userTitle?: string
  firstName: string
  lastName: string
  secondLastName?: string
  state: TeamState
  role: Role
  permissions: Permission[]
}
