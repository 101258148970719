import { FormattedMessage } from 'react-intl'

import { getCardTitle } from '@/lib/card'
import { Widget } from '@/shared/components'
import { ChevronRight, Notes, Statements } from '@/shared/icons/outline'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Card,
  Skeleton,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { Card as CardType } from '../types'

import { CardBillingAddress } from './CardBillingAddress'
import { ScreenType } from './CardDetailsSidebar'

type Props = {
  card?: CardType
  setScreen: (screen: ScreenType) => void
}

export const CardInformation = ({ card, setScreen }: Props) => {
  return (
    <SlideInLeft>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbLink asChild>
            <Button
              onClick={() => setScreen({ type: 'base' })}
              variant="ghost"
              size="inline"
            >
              {card ? (
                <Typography>{getCardTitle(card)}</Typography>
              ) : (
                <Skeleton className="h-6 w-36" />
              )}
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Typography>
              <FormattedMessage
                id="card.sidebar.information"
                defaultMessage="Card information"
              />
            </Typography>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-4" />

      <CardBillingAddress />

      <div className="p-4" />

      <Widget.Title>
        <FormattedMessage
          id="card.sidebar.cardDocuments"
          defaultMessage="Card documents"
        />
      </Widget.Title>

      <div className="p-1" />

      <Card scalable size="upload" className="py-2">
        <Button
          onClick={() => setScreen({ type: 'statements' })}
          variant="ghost"
          size="inline"
          className="group w-full justify-between"
        >
          <span className="flex gap-3 py-1">
            <span className="rounded-xl bg-neutral-gray-100 p-3">
              <Statements className="size-4" />
            </span>
            <span className="flex flex-col">
              <Typography className="font-semibold">
                <FormattedMessage
                  id="card.sidebar.accountStatements"
                  defaultMessage="Account statements"
                />
              </Typography>
              <Typography className="text-neutral-gray-600">
                <FormattedMessage
                  id="card.sidebar.statements.desc"
                  defaultMessage="Click to access your weekly card statements"
                />
              </Typography>
            </span>
          </span>
          <ChevronRight className="size-8 shrink-0" />
        </Button>
      </Card>

      <div className="p-1" />

      <Card scalable size="upload" className="py-2">
        <Button
          onClick={() => setScreen({ type: 'statements' })}
          variant="ghost"
          size="inline"
          className="group w-full justify-between"
        >
          <span className="flex gap-3 py-1">
            <span className="rounded-xl bg-neutral-gray-100 p-3">
              <Notes className="size-4" />
            </span>
            <span className="flex flex-col">
              <Typography className="font-semibold">
                <FormattedMessage
                  id="card.sidebar.terms"
                  defaultMessage="Terms and conditions"
                />
              </Typography>
              <Typography className="text-neutral-gray-600">
                <FormattedMessage
                  id="card.sidebar.terms.desc"
                  defaultMessage="Click to download the contract"
                />
              </Typography>
            </span>
          </span>
          <ChevronRight className="size-8 shrink-0" />
        </Button>
      </Card>

      <div className="p-4" />

      <Widget
        title={
          <FormattedMessage
            id="card.sidebar.ATMNetwork"
            defaultMessage="ATM network"
          />
        }
      >
        <Typography>
          <FormattedMessage
            id="card.sidebar.withdrawalFees"
            defaultMessage="Withdrawal fees"
          />
        </Typography>

        <Typography className="text-neutral-gray-600">
          <FormattedMessage
            id="card.sidebar.withdrawalFees.description"
            defaultMessage="You will pay a 1% fee on all ATM withdrawals. You can withdraw as much as you have in the account linked to your card"
          />
        </Typography>
      </Widget>
    </SlideInLeft>
  )
}
