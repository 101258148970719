import { FormattedMessage } from 'react-intl'

import { TeamMember, TeamState } from '@/features/Team/types'
import { cn } from '@/lib/utils'
import { Typography } from '@/shared/ui'

const bgColorClassNameByTeamState: Record<TeamState, string> = {
  [TeamState.ACTIVE]: 'bg-primary-light',
  [TeamState.INVITED]: 'bg-neutral-gray-200',
  [TeamState.BLOCKED]: 'bg-neutral-gray-200',
  [TeamState.TERMINATED]: 'bg-primary-error/15',
}

const textColorClassNameByTeamState: Record<TeamState, string> = {
  [TeamState.ACTIVE]: 'text-primary-dark',
  [TeamState.INVITED]: 'text-neutral-gray-800',
  [TeamState.BLOCKED]: 'text-neutral-gray-800',
  [TeamState.TERMINATED]: 'text-primary-error',
}

const dotColorClassNameByTeamState: Record<TeamState, string> = {
  [TeamState.ACTIVE]: 'bg-primary-dark',
  [TeamState.INVITED]: 'bg-neutral-gray-800',
  [TeamState.BLOCKED]: 'bg-neutral-gray-800',
  [TeamState.TERMINATED]: 'bg-primary-error',
}

type Props = {
  member: TeamMember
}

export const SidebarTeamStateCell = ({ member }: Props) => {
  return (
    <div
      className={cn(
        'flex h-[22px] items-center gap-1.5 rounded-xl px-2',
        `${bgColorClassNameByTeamState[member.state]}`,
      )}
    >
      <span
        className={cn(
          'size-1.5 rounded-full',
          dotColorClassNameByTeamState[member.state],
        )}
      />

      <Typography
        className={cn(textColorClassNameByTeamState[member.state])}
        bold
        variant="body-small"
      >
        <FormattedMessage
          id="team.state.label"
          defaultMessage="{state, select, ACTIVE {Active} INVITED {Invited} BLOCKED {Blocked} TERMINATED {Terminated} other {}}"
          values={{ state: member.state }}
        />
      </Typography>
    </div>
  )
}
