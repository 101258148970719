import { useCallback, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { SIGNING_IN_ROUTE } from '@/constants/paths'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useAuth } from '@/providers/AuthProvider'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  InputOTP,
  SlideInScreen,
  Typography,
} from '@/shared/ui'

import { authenticate, signIn } from '../api'

const TIMER_COUNTDOWN = 60
const PASSWORD_FORM_ID = 'password-form'

const verificationSchema = z.object({
  otp: z
    .string()
    .min(1, {
      message: 'validation.otp.required',
    })
    .length(6, {
      message: 'validation.otp.length',
    }),
})

export type VerificationSchema = z.infer<typeof verificationSchema>

type Props = {
  email?: string
  password?: string
  identityId: string | null
}

export const VerificationForm = ({ email, password, identityId }: Props) => {
  const notifyError = useErrorToast()
  const [countdown, setCountdown] = useState(TIMER_COUNTDOWN)
  const { updateRefreshToken } = useAuth()
  const navigate = useNavigate()
  const intl = useIntl()

  const form = useForm<VerificationSchema>({
    mode: 'onChange',
    resolver: zodResolver(verificationSchema),
    defaultValues: { otp: '' },
  })

  const {
    mutateAsync: signInAsync,
    isPending: signInIsPending,
    isError: signInIsError,
  } = useMutation({
    mutationFn: signIn,
  })

  const { mutateAsync: authenticateAsync, isPending: authenticateIsPending } =
    useMutation({ mutationFn: authenticate })

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => setCountdown(countdown - 1), 1000)
    }
  }, [countdown])

  const onSubmit: SubmitHandler<VerificationSchema> = useCallback(
    async (data) => {
      if (!identityId) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Identity ID is missing',
            id: 'auth.error.noIdentityId',
          }),
        )

        return
      }

      try {
        const response = await signInAsync({
          ...data,
          identityId,
        })

        updateRefreshToken(response.data.refreshToken)

        navigate(SIGNING_IN_ROUTE, { replace: true })
      } catch (error) {
        if (error instanceof Error) {
          notifyError(error)
        }
      }
    },
    [identityId, intl, navigate, notifyError, signInAsync, updateRefreshToken],
  )

  useEffect(() => {
    if (signInIsError) {
      form.setValue('otp', '')
    }
  }, [form, signInIsError])

  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onSubmit)())

    return () => subscription.unsubscribe()
  }, [form, onSubmit])

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Verify your identity"
          id="signIn.verifyIdentity"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          defaultMessage="We have sent you an email with a verification code. Don’t share it with anyone"
          id="signIn.verifyIdentity.emailSent"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form id={PASSWORD_FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="otp"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputOTP
                    shouldReset={signInIsError}
                    disabled={signInIsPending}
                    {...field}
                  />
                </FormControl>
                <FormLabel htmlFor="otp" className="sr-only">
                  <FormattedMessage
                    defaultMessage="Verification code"
                    id="label.verificationCode"
                  />
                </FormLabel>
              </FormItem>
            )}
          />
        </form>
      </Form>

      <div className="p-8" />

      <div className="mt-auto flex flex-col gap-3">
        <Button
          width="full"
          form={PASSWORD_FORM_ID}
          disabled={signInIsPending}
          loading={signInIsPending}
          type="submit"
        >
          <FormattedMessage defaultMessage="Sign in" id="action.signIn" />
        </Button>

        <Typography className="flex items-center justify-center gap-2">
          <span className="text-neutral-gray-600">
            <FormattedMessage
              defaultMessage="Didn’t receive the code?"
              id="auth.noCode"
            />
          </span>{' '}
          <Button
            disabled={countdown > 0}
            variant="link"
            size="inline"
            onClick={() => {
              if (email && password) {
                try {
                  authenticateAsync({ email, password })
                } catch (error) {
                  if (error instanceof Error) {
                    notifyError(error)
                  }
                }
              } else {
                toast.error(
                  intl.formatMessage({
                    defaultMessage: 'Email or password is missing',
                    id: 'auth.error.missingEmailOrPassword',
                  }),
                )
              }

              setCountdown(TIMER_COUNTDOWN)
            }}
          >
            {countdown === 0
              ? intl.formatMessage({
                  defaultMessage: 'Resend now',
                  id: 'auth.resendNow',
                })
              : authenticateIsPending
                ? intl.formatMessage({
                    defaultMessage: 'Sending now...',
                    id: 'auth.sendNow',
                  })
                : intl.formatMessage(
                    {
                      defaultMessage: 'Send again in {countdown}...',
                      id: 'auth.sendAgainIn',
                    },
                    { countdown },
                  )}
          </Button>
        </Typography>
      </div>

      <div className="p-6" />
    </SlideInScreen>
  )
}
