import { PaymentMethod } from '@/constants/payments'
import { CountryCode } from '@/types/country'

type BaseRecipient = {
  bankName: string | null
  addressStreet: string | null
  addressCity: string | null
  addressState: string | null
  addressZipCode: string | null
  businessId: string
  createdAt: string
  email: string | null
  entityType: EntityType
  firstName: string | null
  id: string
  internationalPhonePrefix: string | null
  lastName: string | null
  legalName: string | null
  localPhoneNumber: string | null
  secondLastName: string | null
  state: 'ACTIVE'
}

type MXLocalInformation = {
  clabe: string
}

type USLocalInformation = {
  accountNumber: string
  routingNumber: string
  paymentMethod: PaymentMethod
}

type MXRecipient = BaseRecipient & {
  country: CountryCode.MX
  localInformation: MXLocalInformation
}

type USRecipient = BaseRecipient & {
  country: CountryCode.US
  localInformation: USLocalInformation
}

export type Recipient = MXRecipient | USRecipient

export enum EntityType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
}

type IndividualFields = {
  firstName: string
  lastName: string
  secondLastName?: string
  internationalPhonePrefix?: string
  localPhoneNumber?: string
  email?: string
}

type BusinessFields = {
  legalName: string
  internationalPhonePrefix?: string
  localPhoneNumber?: string
  email?: string
}

export type IndividualMXRecipient = IndividualFields & {
  country: CountryCode.MX
  entityType: EntityType.INDIVIDUAL
  localInformation: {
    clabe: string
  }
}

export type BusinessMXRecipient = BusinessFields & {
  country: CountryCode.MX
  entityType: EntityType.BUSINESS
  localInformation: {
    clabe: string
  }
}

export type IndividualUSRecipient = IndividualFields & {
  country: CountryCode.US
  entityType: EntityType.INDIVIDUAL
  addressStreet: string
  addressCity: string
  addressState: string
  addressZipCode: string
  addressCountry: string
  localInformation: {
    accountNumber: string
    routingNumber: string
    paymentMethod: string
  }
}

export type BusinessUSRecipient = BusinessFields & {
  country: CountryCode.US
  entityType: EntityType.BUSINESS
  addressStreet: string
  addressCity: string
  addressState: string
  addressZipCode: string
  addressCountry: string
  localInformation: {
    accountNumber: string
    routingNumber: string
    paymentMethod: string
  }
}

export type CreatedRecipient =
  | IndividualMXRecipient
  | BusinessMXRecipient
  | IndividualUSRecipient
  | BusinessUSRecipient
