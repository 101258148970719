import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { getCodeByCountry } from '@/lib/country'
import { CountryCodeField, OptionalTag, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  PhoneNumberInput,
  StickyContainer,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { BusinessMXRecipient } from '../types'

import { businessValidation, clabeValidation } from './validations'

const DEFAULT_VALUES: CreateBusinessSchema = {
  legalName: '',
  email: '',
  localPhoneNumber: '',
  internationalPhonePrefix: '',
  clabe: '',
}

const createBusinessSchema = businessValidation.and(clabeValidation)

const MX_BUSINESS_FORM_ID = 'mx-business-form'

export type CreateBusinessSchema = z.infer<typeof createBusinessSchema>

type Props = {
  country: CountryCode
  onContinue: (values: CreateBusinessSchema) => void
  recipient?: BusinessMXRecipient
}

export const MXBusinessForm = ({ country, onContinue, recipient }: Props) => {
  const intl = useIntl()
  const form = useForm<CreateBusinessSchema>({
    mode: 'onChange',
    resolver: zodResolver(createBusinessSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
      internationalPhonePrefix: getCodeByCountry(country),
    },
  })

  const onSubmit: SubmitHandler<CreateBusinessSchema> = (values) => {
    const { internationalPhonePrefix, localPhoneNumber, ...rest } = values

    const parsedPhoneNumber =
      localPhoneNumber?.replace(`${internationalPhonePrefix}`, '') ?? ''

    onContinue({
      ...rest,
      localPhoneNumber: parsedPhoneNumber,
      internationalPhonePrefix,
    })
  }

  useEffect(() => {
    if (recipient) {
      form.setValue('legalName', recipient.legalName)
      form.setValue('email', recipient.email)

      if (recipient.localPhoneNumber) {
        form.setValue(
          'internationalPhonePrefix',
          recipient.internationalPhonePrefix,
        )

        form.setValue(
          'localPhoneNumber',
          recipient.internationalPhonePrefix + recipient.localPhoneNumber,
        )
      }

      form.setValue('clabe', recipient.localInformation.clabe)

      form.trigger()
    }
  }, [form, recipient])

  return (
    <>
      <Form {...form}>
        <form
          id={MX_BUSINESS_FORM_ID}
          className="w-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Widget
            title={
              <FormattedMessage
                id="recipient.contactDetails"
                defaultMessage="Contact details"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="legalName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.legalName',
                        defaultMessage: 'Legal Name',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="label.legalName"
                      defaultMessage="Legal Name"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="email"
                      placeholder={intl.formatMessage({
                        id: 'label.contactEmail',
                        defaultMessage: 'Contact email',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="label.contactEmail"
                      defaultMessage="Contact email"
                    />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            <div className="flex items-stretch gap-3">
              <FormField
                control={form.control}
                name="internationalPhonePrefix"
                render={({ field }) => {
                  return (
                    <CountryCodeField
                      value={field.value}
                      onSelect={(value) =>
                        form.setValue('internationalPhonePrefix', value)
                      }
                    />
                  )
                }}
              />

              <FormField
                control={form.control}
                name="localPhoneNumber"
                render={({ field }) => (
                  <FormItem className="relative flex-1">
                    <FormControl>
                      <PhoneNumberInput
                        phonePrefix={
                          form.watch('internationalPhonePrefix') ?? ''
                        }
                        placeholder={intl.formatMessage({
                          id: 'label.contactPhoneNumber',
                          defaultMessage: 'Contact phone number',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        id="label.contactPhoneNumber"
                        defaultMessage="Contact phone number"
                      />
                    </AnimatedFormLabel>

                    {field.value === '' && <OptionalTag />}
                  </FormItem>
                )}
              />
            </div>
          </Widget>

          <div className="p-4" />

          <Widget
            title={
              <FormattedMessage
                id="recipient.paymentDetails"
                defaultMessage="Payment details"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="clabe"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.clabe',
                        defaultMessage: 'CLABE',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage id="label.clabe" defaultMessage="CLABE" />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
          </Widget>
        </form>
      </Form>

      <StickyContainer>
        <Button
          width="full"
          form={MX_BUSINESS_FORM_ID}
          disabled={!form.formState.isValid}
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Save & Continue"
            id="action.saveAndContinue"
          />
        </Button>
      </StickyContainer>
    </>
  )
}
