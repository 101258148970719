export const queryKeys = {
  getAccount: 'getAccount',
  getAccountDetails: 'getAccountDetails',

  getBusinessIdentity: 'getBusinessIdentity',
  getIdentity: 'getIdentity',

  getRecipients: 'getRecipients',
  getSingleRecipient: 'getSingleRecipient',

  getTransactions: 'getTransactions',
  getTransaction: 'getTransaction',
  getCashbackTransactions: 'getCashbackTransactions',

  getBulkTransaction: 'getBulkTransaction',
  getBulkTransactions: 'getBulkTransactions',

  getFXRates: 'getFXRates',
  getCountryCodes: 'getCountryCodes',
  getCompanyTypes: 'getCompanyTypes',

  getOnboardingState: 'getOnboardingState',
  getOnboardingConfig: 'getOnboardingConfig',
  getOnboardingStepDetails: 'getOnboardingStepDetails',
  getOnboardingCountries: 'getOnboardingCountries',

  startIdentityValidation: 'startIdentityValidation',
  restartIdentityValidation: 'restartIdentityValidation',

  getBeneficialOwner: 'getBeneficialOwner',

  createBulkPayment: 'createBulkPayment',
  getCSVTransactions: 'getCSVTransactions',
  getCSVFiles: 'getCSVFiles',

  getGooglePlacesDetails: 'getGooglePlacesDetails',

  getPriceChartRates: 'getPriceChartRates',

  getAllCards: 'getAllCards',
  getUserCards: 'getUserCards',
  getSingleCard: 'getSingleCard',
  getUnmaskedCardDetails: 'getUnmaskedCardDetails',
  getCardTransactions: 'getCardTransactions',
  getCardDeliveryDetails: 'getCardDeliveryDetails',

  getTeamMembers: 'getTeamMembers',
  getTeamMember: 'getTeamMember',

  getBusinessUser: 'getBusinessUser',
  getTeamMemberPaymentLimit: 'getTeamMemberPaymentLimit',
  getPaymentLimit: 'getPaymentLimit',

  getBusinessPaymentLimits: 'getBusinessPaymentLimits',

  getAdminTasks: 'getAdminTasks',
  getAdminTask: 'getAdminTask',
  getUserTasks: 'getUserTasks',
  getUserTask: 'getUserTask',
  getTasksCount: 'getTasksCount',
  getWithdrawalTaskDetails: 'getWithdrawalTaskDetails',
  getBulkPaymentTaskDetails: 'getBulkPaymentTaskDetails',
}
