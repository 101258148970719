import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import {
  ACCOUNTS_ROUTE,
  CARDS_ROUTE,
  DASHBOARD_ROUTE,
  NavigationLink,
  RECIPIENTS_ROUTE,
  TASKS_ROUTE,
  TEAM_ROUTE,
  TRANSACTIONS_ROUTE,
} from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getTasksCount } from '@/features/Tasks/api'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { Feature, useFeatures } from '@/hooks/useFeatures'
import { Playlist } from '@/shared/icons/outline'
import {
  Bank,
  Card,
  Home,
  Navigation,
  SquareCheck,
  Users,
} from '@/shared/icons/solid'

export function useNavigationLinks(): NavigationLink[] {
  const { isAdmin } = useBusinessRole()

  const tasksCountQuery = useQuery({
    queryKey: [queryKeys.getTasksCount],
    queryFn: getTasksCount,
    select: (data) => data.data.pendingTasksCount,
    enabled: isAdmin,
  })

  const [
    homeEnabled,
    tasksEnabled,
    accountsEnabled,
    cardsEnabled,
    recipientsEnabled,
    transactionsEnabled,
    teamEnabled,
  ] = useFeatures([
    Feature.HOME,
    Feature.TASKS,
    Feature.ACCOUNTS,
    Feature.CARDS,
    Feature.RECIPIENTS,
    Feature.TRANSACTIONS,
    Feature.TEAM,
  ])

  return useMemo(
    () => [
      {
        key: 'home',
        icon: Home,
        path: DASHBOARD_ROUTE,
        enabled: homeEnabled,
      },
      {
        key: 'tasks',
        icon: SquareCheck,
        path: TASKS_ROUTE,
        enabled: tasksEnabled,
        showDot: !!(tasksCountQuery.data && tasksCountQuery.data > 0),
      },
      {
        key: 'accounts',
        icon: Bank,
        path: ACCOUNTS_ROUTE,
        enabled: accountsEnabled,
      },
      {
        key: 'cards',
        icon: Card,
        path: CARDS_ROUTE,
        enabled: cardsEnabled,
      },
      {
        key: 'recipients',
        icon: Navigation,
        path: RECIPIENTS_ROUTE,
        enabled: recipientsEnabled,
      },
      {
        key: 'transactions',
        icon: Playlist,
        path: TRANSACTIONS_ROUTE,
        enabled: transactionsEnabled,
      },
      {
        key: 'team',
        icon: Users,
        path: TEAM_ROUTE,
        enabled: teamEnabled,
      },
    ],
    [
      accountsEnabled,
      cardsEnabled,
      homeEnabled,
      recipientsEnabled,
      tasksCountQuery.data,
      tasksEnabled,
      teamEnabled,
      transactionsEnabled,
    ],
  )
}
