import { FormattedMessage } from 'react-intl'

import { Widget } from '@/shared/components'
import { Details } from '@/shared/ui'

export const CardBillingAddress = () => {
  return (
    <Widget
      title={
        <FormattedMessage
          id="card.sidebar.billingAddress"
          defaultMessage="Billing address"
        />
      }
    >
      <Details>
        <Details.Label>
          <FormattedMessage
            id="label.streetAddress"
            defaultMessage="Street address"
          />
        </Details.Label>

        <Details.Value copyable>Av. Isaac Newton 82</Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage
            id="label.neighborhoodOrDistrict"
            defaultMessage="Neighborhood or district"
          />
        </Details.Label>

        <Details.Value copyable>Miguel Hidalgo</Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage id="label.city" defaultMessage="City" />
        </Details.Label>

        <Details.Value copyable>Ciudad de Mexico</Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage
            defaultMessage="State or province"
            id="label.stateOrProvince"
          />
        </Details.Label>

        <Details.Value copyable>CDMX</Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Post code" id="label.postCode" />
        </Details.Label>

        <Details.Value copyable>11560</Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Country" id="label.country" />
        </Details.Label>

        <Details.Value copyable>Mexico</Details.Value>
      </Details>
    </Widget>
  )
}
